import { Set } from '../types'
import { set } from '../utils'

export type DurationRecord<T> = Record<10 | 15 | 20 | 25 | 30, T>
export type LanguageRecord<T> = Record<'fr' | 'en', T>

export interface AudioTheme {
  _id: string
  slug: string
  short: LanguageRecord<string>
  title: LanguageRecord<string>
  description: LanguageRecord<string>
  image?: string
  cover?: string
  tracks: LanguageRecord<DurationRecord<number>>
}

export interface AudioBackground {
  _id: string
  slug: string
  short: LanguageRecord<string>
  long: LanguageRecord<string>
  tracks: DurationRecord<number>
  mode?: 'music-only' | 'always'
  image?: string
}

export interface AudioBook {
  _id: string
  slug: string
  category: 'club' | 'book'
  language: 'fr' | 'en'
  title: { short: string; long: string }
  description: string
  image?: string
  cover?: string
  backgrounds?: string[]
  chapters: {
    _id: string
    title: string
    tracks: DurationRecord<number>
  }[]
}

interface ModelAudio {
  // State
  themes: AudioTheme[]
  books: AudioBook[]
  backgrounds: AudioBackground[]

  // Actions
  setThemes: Set<this, 'themes'>
  setBooks: Set<this, 'books'>
  setBackgrounds: Set<this, 'backgrounds'>
}

export const modelAudio: ModelAudio = {
  // State
  themes: [],
  books: [],
  backgrounds: [],

  // Actions
  setThemes: set('themes'),
  setBooks: set('books'),
  setBackgrounds: set('backgrounds'),
}
