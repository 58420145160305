import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'

import { graphql } from '../../../../services/graphql'
import { serviceModals } from '../../../../services/modals'

import { buildComponent } from '../../../../components/factory'

import { NapCocoon } from '../../../../resources/icons/nap-cocoon'
import { NapClock, NapRoom } from '../../../../resources/icons'

import * as UI from '@mantine/core'
import * as Icons from '../../../../components/icons'

export const UserSettings = buildComponent<{
  open: boolean
  onClose: () => void
  user: {
    _id: string
    email: string
    spaces: { _id: string; name: string }[]
  }
}>()
  .withLifecycle(({ props }) => {
    const [tab, setTab] = useState<'spaces' | 'naps'>('spaces')
    const [removedSpaces, setRemovedSpaces] = useState<string[]>([])
    const [past, setPast] = useState(true)
    const [naps, setNaps] = useState<
      {
        _id: string
        time: { start: string; end: string }
        cocoon: { name: string; room: { name: string } }
      }[]
    >([])

    const deleteUser = async () => {
      await serviceModals.confirmDeletion(
        'Supprimer un utilisateur',
        'Voulez-vous vraiment supprimer cet utilisateur ?',
      )
      await graphql.mutations.deleteUser(props.user._id)
      props.onClose()
    }

    useEffect(() => {
      setRemovedSpaces((removed) => {
        // Trigger API calls for each removed space
        removed.forEach((space) =>
          graphql.mutations.userQuitSpace(props.user._id, space),
        )

        return []
      })
      setTab('spaces')
    }, [props.user._id, props.open])

    useEffect(() => {
      if (props.open) {
        graphql.queries
          .getUserNaps(props.user._id, past)
          .then((res) => setNaps(res.napList))
      }
    }, [props.open, props.user._id, past])

    return {
      tab,
      setTab,
      removedSpaces,
      setRemovedSpaces,
      past,
      setPast,
      naps,
      deleteUser,
    }
  })
  .withRender(({ props, lifecycle }) => (
    <UI.Modal
      centered
      opened={props.open}
      onClose={props.onClose}
      styles={{
        modal: {
          width: '88rem',
          paddingTop: '1rem !important',
          paddingBottom: '0.5rem !important',
        },
      }}
      title={
        <UI.Group spacing={4} mb={-8}>
          <b>{props.user.email}</b>
          <UI.ActionIcon color="red" size="xl" onClick={lifecycle.deleteUser}>
            <Icons.FontAwesomeIcon icon={Icons.Solid.faTrash} />
          </UI.ActionIcon>
        </UI.Group>
      }
    >
      <UI.Stack>
        <UI.SegmentedControl
          mx="auto"
          data={[
            { value: 'spaces', label: 'Espaces' },
            { value: 'naps', label: 'Réservations' },
          ]}
          value={lifecycle.tab}
          onChange={lifecycle.setTab as (value: string) => void}
        />

        {lifecycle.tab === 'spaces' && (
          <UI.Input.Wrapper mb={16} label="Espaces de l'utilisateur">
            <UI.ScrollArea
              mt={8}
              mx={-16}
              px={16}
              sx={{ height: '48rem' }}
              type="auto"
            >
              <UI.Card
                mt={8}
                p={0}
                withBorder
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.015)' }}
              >
                {props.user.spaces.flatMap((space, i) => {
                  const left = lifecycle.removedSpaces.includes(space._id)

                  return [
                    i ? <UI.Divider key={space._id + '-divider'} /> : null,
                    <UI.Group p={16} key={space._id} position="apart">
                      <UI.Text
                        strikethrough={left}
                        color={left ? 'dimmed' : undefined}
                      >
                        {space.name}
                      </UI.Text>
                      <UI.ActionIcon
                        size="lg"
                        color={left ? 'blue' : 'red'}
                        onClick={() =>
                          !left
                            ? lifecycle.setRemovedSpaces((r) => [
                                ...r,
                                space._id,
                              ])
                            : lifecycle.setRemovedSpaces((r) =>
                                r.filter((s) => s !== space._id),
                              )
                        }
                      >
                        <Icons.FontAwesomeIcon
                          icon={
                            left
                              ? Icons.Solid.faUndo
                              : Icons.Solid.faRightFromBracket
                          }
                        />
                      </UI.ActionIcon>
                    </UI.Group>,
                  ]
                })}
              </UI.Card>
            </UI.ScrollArea>
          </UI.Input.Wrapper>
        )}

        {lifecycle.tab === 'naps' && (
          <UI.Input.Wrapper
            mb={16}
            styles={{
              label: {
                width: '100%',
              },
            }}
            label={
              <UI.Group sx={{ width: '100%' }} position="apart">
                <UI.Text>Réservations de l'utilisateur</UI.Text>
                <UI.Group spacing={6}>
                  <UI.Text size="xs">Passées</UI.Text>
                  <UI.Switch
                    size="xs"
                    checked={!lifecycle.past}
                    onChange={(e) =>
                      lifecycle.setPast(!e.currentTarget.checked)
                    }
                  />
                  <UI.Text size="xs">À venir</UI.Text>
                </UI.Group>
              </UI.Group>
            }
          >
            <UI.ScrollArea
              mt={8}
              mx={-16}
              px={16}
              sx={{ height: '48rem' }}
              type="auto"
            >
              {lifecycle.naps.length === 0 && (
                <UI.Text mt={128} align="center" weight="bold" color="dimmed">
                  Aucune sieste {lifecycle.past ? 'passées' : 'à venir'}
                </UI.Text>
              )}

              {lifecycle.naps.map((nap) => (
                <UI.Card
                  key={nap._id}
                  mt={8}
                  p={0}
                  withBorder
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.015)' }}
                >
                  <UI.Group p={0} noWrap spacing={4}>
                    <UI.Group
                      sx={{ flex: 1, width: '100%' }}
                      p={8}
                      noWrap
                      spacing={8}
                    >
                      <NapCocoon height="4rem" />
                      <UI.Text size="sm">{nap.cocoon.name}</UI.Text>
                    </UI.Group>
                    <UI.Divider orientation="vertical" />
                    <UI.Group
                      sx={{ flex: 1, width: '100%' }}
                      p={8}
                      noWrap
                      spacing={8}
                    >
                      <NapRoom height="4rem" style={{ margin: '0 -0.5rem' }} />
                      <UI.Text size="sm">{nap.cocoon.room.name}</UI.Text>
                    </UI.Group>
                    <UI.Divider orientation="vertical" />
                    <UI.Group
                      sx={{ flex: 2, width: '100%' }}
                      p={8}
                      noWrap
                      spacing={8}
                    >
                      <NapClock active={false} duration={20} height="4rem" />
                      <UI.Text size="sm">
                        {format(new Date(nap.time.start), 'dd/MM/yyyy, H:mm')} -{' '}
                        {format(new Date(nap.time.end), 'H:mm')}
                      </UI.Text>
                    </UI.Group>
                  </UI.Group>
                </UI.Card>
              ))}
            </UI.ScrollArea>
          </UI.Input.Wrapper>
        )}
      </UI.Stack>
    </UI.Modal>
  ))
