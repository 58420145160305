import React, { useCallback, useMemo, useState } from 'react'
import { RouteComponentProps } from 'wouter'

import { graphql } from '../../../services/graphql'
import { serviceModals } from '../../../services/modals'

import { useStoreState } from '../../../hooks/state'
import { buildComponent } from '../../../components/factory'

import * as UI from '@mantine/core'
import * as Icons from '../../../components/icons'

import { Table } from './components/table'

export const ScreenConnectedUsers = buildComponent<RouteComponentProps>()
  .withLifecycle(() => {
    const [search, setSearch] = useState('')
    const [_selection, setSelection] = useState<string[]>([])
    const list = useStoreState((store) => store.user.list)
    const space = useStoreState((store) => store.user.space)
    const selection = useMemo(
      () => _selection.filter((id) => list.some((user) => user._id === id)),
      [_selection, list],
    )

    const users = list.filter(
      (user) =>
        (user.email.toLowerCase().includes(search.toLowerCase()) && !space) ||
        user.spaces.some((s) => s._id === space),
    )

    const toggleRow = useCallback(
      (id: string) =>
        setSelection((current) =>
          current.includes(id)
            ? current.filter((item) => item !== id)
            : [...current, id],
        ),
      [],
    )
    const toggleAll = useCallback(
      () =>
        setSelection((current) =>
          current.length === users.length ? [] : users.map((item) => item._id),
        ),
      [users],
    )
    const isSelected = useCallback(
      (id: string) => selection.includes(id),
      [selection],
    )

    const deleteSelected = useCallback(async () => {
      await serviceModals.confirmDeletion(
        <>
          Supprimer {selection.length} utilisateur
          {selection.length > 1 ? 's' : ''}
        </>,
        <>
          Voulez-vous vraiment supprimer ce
          {selection.length > 1 ? 's' : 't'} utilisateur
          {selection.length > 1 ? 's' : ''} ?
        </>,
      )
      await Promise.all(
        selection.map((user) => graphql.mutations.deleteUser(user)),
      )
      setSelection([])
    }, [selection])

    return {
      users,
      search,
      setSearch,
      selection,
      toggleAll,
      toggleRow,
      isSelected,
      deleteSelected,
    }
  })
  .withRender(({ lifecycle }) => (
    <>
      <UI.Stack
        sx={{
          position: 'sticky',
          top: 72,
          zIndex: 10,
          backgroundColor: '#f8f9fc',
        }}
        mx={-16}
        px={16}
        mt={-16}
        py={16}
        spacing={0}
      >
        <UI.Group position="apart">
          <UI.Stack spacing={0}>
            <UI.Title>
              <UI.Text size={22}>Utilisateurs</UI.Text>
            </UI.Title>
            <UI.Text color="dimmed">
              Liste des utilisateurs inscrits à l'application Nap&Up.
            </UI.Text>
          </UI.Stack>
        </UI.Group>

        <UI.Group mx="auto" mt="sm" mb={0} position="center" spacing={8}>
          <UI.TextInput
            variant="default"
            mx={8}
            sx={{ width: 480 }}
            value={lifecycle.search}
            onChange={(e) => lifecycle.setSearch(e.target.value)}
            placeholder={'Chercher un utilisateur'}
            rightSection={
              <Icons.FontAwesomeIcon
                color="#AAAAAA"
                icon={Icons.Solid.faSearch}
                style={{ marginRight: '2rem' }}
              />
            }
          />
        </UI.Group>
      </UI.Stack>

      <UI.Group
        sx={{
          height: '7rem',
          position: 'sticky',
          top: 231,
          marginBottom: -64,
          backgroundColor: 'white',
          zIndex: 10,
          borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
          borderRadius: '6px 6px 0 0',
        }}
        px={16}
      >
        <UI.Group
          align="center"
          position="center"
          style={{ width: '4rem', flex: 'none' }}
        >
          <UI.Checkbox
            checked={lifecycle.selection.length === lifecycle.users.length}
            indeterminate={
              lifecycle.selection.length > 0 &&
              lifecycle.selection.length !== lifecycle.users.length
            }
            onChange={lifecycle.toggleAll}
            transitionDuration={0}
          />
        </UI.Group>
        <div style={{ width: '32rem', flex: 'none' }}>
          <UI.Text weight={700}>Utilisateur</UI.Text>
        </div>
        <div style={{ flex: 1 }}>
          <UI.Text weight={700}>Espaces</UI.Text>
        </div>

        <UI.Text style={{ position: 'relative' }}>
          {!!lifecycle.selection.length && (
            <UI.Button
              color="danger"
              size="sm"
              sx={{
                minHeight: 30,
                position: 'absolute',
                right: 0,
                top: -20,
              }}
              onClick={lifecycle.deleteSelected}
            >
              Supprimer {lifecycle.selection.length} utilisateur
              {lifecycle.selection.length > 1 ? 's' : ''}
            </UI.Button>
          )}
        </UI.Text>
      </UI.Group>

      <UI.Card pt={56} shadow="sm" mt={8} p={0}>
        <Table
          data={lifecycle.users}
          toggleRow={lifecycle.toggleRow}
          isSelected={lifecycle.isSelected}
        />
      </UI.Card>
    </>
  ))
