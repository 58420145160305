import ky from 'ky'
import io from 'socket.io-client'
import DataLoader from 'dataloader'

import { DeepPartial } from '../hooks/use-synchronize-data'

import { Store } from '../state'
import {
  SpaceCocoons,
  SpaceInfo,
  SpaceList,
  SpaceMaintenance,
  SpaceOptions,
  //4261 ajouter les horaires aux salles
  RoomOptions,
} from '../state/models/space'
import { AudioBackground, AudioBook, AudioTheme } from '../state/models/audio'
import { User } from '../state/models/users'
import { createCallCache } from '../helpers/cache-calls'

type Update<T> = DeepPartial<T> & { _id: string }

const socket = io(process.env.REACT_APP_API_URL as string, {
  path: '/harmonyjs-socket',
})

const spaceInfoSelection = `
_id, localizedName, localizedDescription, code, created_at
codes {
  code, expiration
}
emails {
  email
}
subscription {
  start, end, premium, licenses
  disabled {
    audio, profile, booking
  }
}`

const spaceOptionsSelection = `
_id
options {
  napDelay
  napsPerDay
  napDurations { duration10, duration15, duration20, duration25,duration45,duration60 }
  napDays { 
    monday { startHour, endHour }
    tuesday { startHour, endHour }
    wednesday { startHour, endHour }
    thursday { startHour, endHour }
    friday { startHour, endHour }
    saturday { startHour, endHour }
    sunday { startHour, endHour }
  }
}`
//4261 Ajouter les horaires aux salles
const roomOptionsSelection = `
_id
options {
  napDays { 
    monday { startHour, endHour }
    tuesday { startHour, endHour }
    wednesday { startHour, endHour }
    thursday { startHour, endHour }
    friday { startHour, endHour }
    saturday { startHour, endHour }
    sunday { startHour, endHour }
  }
}`

const spaceMaintenanceSelection = `
_id
maintenances { target, start, end }
cocoons { _id, name, room { _id } }
rooms { _id, name }`

const audioThemeSelection = `
_id, slug, title, short, description, tracks, image, cover`
const audioBookSelection = `
_id, slug, language, title { short, long }, description, backgrounds
image, cover
chapters { title, tracks }`
const audioBackgroundSelection = `
_id, slug, short, long, tracks, image`

function serializeValue(value: any): string {
  if ((value ?? null) === null) {
    return 'null'
  }

  if (Array.isArray(value)) {
    return `[${value.map(serializeValue)}]`
  }

  if (typeof value === 'object') {
    return serializeRecord(value)
  }

  return JSON.stringify(value)
}

function serializeRecord(record: object): string {
  type Key = keyof typeof record
  return `{
    ${Object.keys(record).map(
      (key) => `${key}: ${serializeValue(record[key as Key])}\n`,
    )}
  }`
}

const callCache = createCallCache(500)

export const graphql = {
  subscribe(models: string[], callback: () => void | Promise<void>) {
    callback()

    models.forEach((m) => {
      socket.on(m + '-updated', callback)
    })

    return () => {
      models.forEach((m) => {
        socket.off(m + '-updated', callback)
      })
    }
  },
  async query<T = {}>(query: string) {
    const token = Store.getState().utils.session.token

    const headers: Record<string, string> = {}

    if (token) {
      headers.Authorization = `Bearer ${token}`
    }

    const results = await ky
      .post(process.env.REACT_APP_API_URL as string, {
        headers,
        json: {
          operationName: null,
          variables: {},
          query,
        },
      })
      .json<{ data: T; errors?: [Error] }>()

    if (results.errors) {
      throw results.errors[0]
    }

    return results.data
  },
  queries: {
    login(email: string, password: string) {
      return graphql.query<{
        administratorLogin: {
          error: 'user_not_found' | 'wrong_credentials' | null
          token: string | null
        }
      }>(`{
        administratorLogin(login: "${email}", password: "${password}") {
          error
          token
        }
      }`)
    },
    sendRecap(spaceId: string, month: number, year: number, annual: boolean) {
      return graphql.query<{
        spaceId: string
        month: number
        year: number
        annual: boolean
      }>(`{
        administratorSendRecap(spaceId: "${spaceId}", month: ${month}, year: ${year}, annual: ${annual})
      }`)
    },
    getAdministratorInfo() {
      return graphql.query<{
        administrator: { active: boolean }
      }>(`{
        administrator {
          active
        }
      }`)
    },
    getSpaces() {
      return graphql.query<{
        spaceList: Omit<SpaceList, 'status'>[]
      }>(
        `{
          spaceList {
            _id, name, code,
            localizedName,
            
            subscription { 
              start, end, premium, licenses,
            }
          }
        }`,
      )
    },
    getSpaceStatistics(id: string) {
      return spaceStatisticsLoader.load(id)
    },
    getSpacesStatistics(ids: readonly string[]) {
      return graphql.query<{
        spaceList: {
          _id: string
          statistics: {
            users: number
            naps: number
            rooms: number
            cocoons: number
          }
        }[]
      }>(
        `{
          spaceList(filter: { _operators: { _id: { in: ${JSON.stringify(
            ids,
          )} } } }) {
            _id
            statistics {
              users, naps, rooms, cocoons
            }
          }
        }`,
      )
    },
    async getSpaceInfo(id: string) {
      return (
        await graphql.query<{ space: SpaceInfo }>(`{
        space(filter: { _id: "${id}" }) { ${spaceInfoSelection} }
      }`)
      ).space
    },
    async getSpaceOptions(id: string) {
      return (
        await graphql.query<{ space: SpaceOptions }>(`{
        space(filter: { _id: "${id}" }) { ${spaceOptionsSelection} }
      }`)
      ).space
    },
    //4261 recuperer les options d'une salle
    async getRoomOptions(id: string) {
      return (
        await graphql.query<{ room: RoomOptions }>(`{
        room(filter: { _id: "${id}" }) { ${roomOptionsSelection} }
      }`)
      ).room
    },
    async getSpaceCocoons(id: string) {
      return (
        await graphql.query<{ space: SpaceCocoons }>(`{
        space(filter: { _id: "${id}" }) {
          _id
          localizedName
          rooms { _id, name, localizedName, localizedDescription }
          cocoons { 
            _id, localizedName, localizedDescription, version
            room { _id }    
          }
        }
      }`)
      ).space
    },
    async getSpaceMaintenance(id: string) {
      return (
        await graphql.query<{ space: SpaceMaintenance }>(`{
        space(filter: { _id: "${id}" }) { ${spaceMaintenanceSelection} }
      }`)
      ).space
    },
    getAudioThemes() {
      return graphql.query<{
        audioThemeList: AudioTheme[]
      }>(`{ audioThemeList { ${audioThemeSelection} } }`)
    },
    async getAudioTheme(id: string, force = false) {
      return (
        await callCache(graphql.query, { key: 'getAudioTheme:' + id, force })<{
          audioThemeList: AudioTheme[]
        }>(
          `{  audioThemeList(filter: { slug: "${id}" }) { ${audioThemeSelection} } }`,
        )
      ).audioThemeList[0]
    },
    getAudioBooks() {
      return graphql.query<{
        audioBookList: AudioBook[]
      }>(`{ audioBookList { ${audioBookSelection} } }`)
    },
    async getAudioBook(id: string, force = false) {
      return (
        await callCache(graphql.query, { key: 'getAudioBook:' + id, force })<{
          audioBookList: AudioBook[]
        }>(
          `{ audioBookList(filter: { slug: "${id}" }) { ${audioBookSelection} } }`,
        )
      ).audioBookList[0]
    },
    getAudioBackgrounds() {
      return graphql.query<{
        audioBackgroundList: AudioBackground[]
      }>(`{ audioBackgroundList { ${audioBackgroundSelection} } }`)
    },
    async getAudioBackground(id: string, force = false) {
      return (
        await callCache(graphql.query, {
          key: 'getAudioBackground:' + id,
          force,
        })<{
          audioBackgroundList: AudioBackground[]
        }>(
          `{ audioBackgroundList(filter: { slug: "${id}" }) { ${audioBackgroundSelection} } }`,
        )
      ).audioBackgroundList[0]
    },
    getUsers() {
      return graphql.query<{
        userList: User[]
      }>(`{
        userList {
          _id, email, spaces { _id, name }
        }
      }`)
    },
    getNaps(space: string, from: Date, to: Date, withDetails: boolean = false) {
      return graphql.query<{
        napList: {
          _id: string
          user: string
          userInfo?: { email: string; _id: string }
          time: { start: string; end: string }
          cocoon: { _id: string; name: string; room: { _id: string } }
          space: string
          evaluation: { rating: number; comment: string }
        }[]
      }>(`{
        napList(
          filter: {
            ${space ? `space: "${space}"` : ''}
            _operators: { time: { match: { start: { gte: "${from.toISOString()}" }, end: { lte: "${to.toISOString()}" } } } }
          }
        ) {
          _id
          user
          ${withDetails ? 'userInfo { email }' : ''}
          time { start, end }
          cocoon { _id, name, room { _id } }
          evaluation { rating, comment }
          space
        }
      }`)
    },
    getUserNaps(user: string, past?: boolean) {
      return graphql.query<{
        napList: {
          _id: string
          time: { start: string; end: string }
          cocoon: { name: string; room: { name: string } }
        }[]
      }>(`{
        napList(
          filter: {
            user: "${user}"
            _operators: { time: { match: { start: { ${
              past ? 'lte' : 'gte'
            }: "${new Date().toISOString()}" } } } }
          }
        ) {
          _id
          time { start, end }
          cocoon { _id, name, room { _id, name } }
        }
      }`)
    },
    getUserSpace(to: Date, skip: number, limit: number) {
      return graphql.query<{
        userSpaceList: {
          user: string
          space: string
          join: string
          leave?: string
        }[]
      }>(`{
        userSpaceList(filter: {
          _operators: {
            join: {
              lte: "${to.toISOString()}"
            }
          }
        }, skip: ${skip}, limit: ${limit}) { user, join, leave, space }
      }`)
    },
  },
  mutations: {
    // Administrator
    async setupAdministrator(password: string) {
      await graphql.query(`mutation {
        administratorSetup(password: "${password}")
      }`)
    },
    // Space
    async createSpace(space: {
      localizedName: { fr: string }
      codes: [{ code: string }]
    }) {
      return graphql.query<{ spaceCreate: { _id: string } }>(`mutation {
        spaceCreate(record: ${serializeRecord(space)}) { _id }
      }`)
    },
    async updateSpaceInfo(info: Partial<SpaceInfo> & { _id: string }) {
      const result = (
        await graphql.query<{ spaceUpdate: SpaceInfo }>(`mutation {
        spaceUpdate(record: ${serializeRecord(info)}) { ${spaceInfoSelection} }
      }`)
      ).spaceUpdate

      if (!result) {
        throw new Error('Error while updating Space')
      }

      return result
    },
    async updateSpaceOptions(options: Partial<SpaceOptions> & { _id: string }) {
      return (
        await graphql.query<{ spaceUpdate: SpaceOptions }>(`mutation {
        spaceUpdate(record: ${serializeRecord(
          options,
        )}) { ${spaceOptionsSelection} }
      }`)
      ).spaceUpdate
    },
    //4261 mettre a jour les options d'une salle
    async updateRoomOptions(options: Partial<RoomOptions> & { _id: string }) {
      return (
        await graphql.query<{ roomUpdate: RoomOptions }>(`mutation {
        roomUpdate(record: ${serializeRecord(
          options,
        )}) { ${roomOptionsSelection} }
      }`)
      ).roomUpdate
    },
    async updateSpaceMaintenance(
      maintenance: Partial<SpaceMaintenance> & { _id: string },
    ) {
      return (
        await graphql.query<{ spaceUpdate: SpaceMaintenance }>(`mutation {
        spaceUpdate(record: ${serializeRecord(
          maintenance,
        )}) { ${spaceMaintenanceSelection} }
      }`)
      ).spaceUpdate
    },
    async deleteSpace(space: string) {
      return graphql.query(`mutation {
        spaceDelete(_id: "${space}") { _id }
      }`)
    },

    // Cocoons & Rooms
    async addRoom(space: string) {
      return graphql.query(`mutation {
        roomCreate(record: { space: "${space}" }) { _id }
      }`)
    },
    async addCocoon(space: string, room: string) {
      return graphql.query(`mutation {
        cocoonCreate(record: { space: "${space}", room: "${room}" }) { _id }
      }`)
    },
    async updateRoom(room: Update<SpaceCocoons['rooms'][number]>) {
      return graphql.query(`mutation {
        roomUpdate(record: ${serializeRecord(room)}) { _id }
      }`)
    },
    async updateCocoon(cocoon: Update<SpaceCocoons['cocoons'][number]>) {
      return graphql.query(`mutation {
        cocoonUpdate(record: ${serializeRecord(cocoon)}) { _id }
      }`)
    },
    async deleteRoom(room: string) {
      return graphql.query(`mutation {
        roomDelete(_id: "${room}") { _id }
      }`)
    },
    async deleteCocoon(cocoon: string) {
      return graphql.query(`mutation {
        cocoonDelete(_id: "${cocoon}") { _id }
      }`)
    },

    // Audio themes
    async createAudioTheme(slug: string) {
      return graphql.query<{
        audioThemeCreate: { _id: string; slug: string }
      }>(`mutation {
        audioThemeCreate(record: { slug: "${slug}" }) { _id, slug }
      }`)
    },
    async updateAudioTheme(theme: Update<AudioTheme>) {
      return (
        await graphql.query<{ audioThemeUpdate: AudioTheme }>(`mutation {
        audioThemeUpdate(record: ${serializeRecord(
          theme,
        )}) { ${audioThemeSelection} }
      }`)
      ).audioThemeUpdate
    },
    async uploadAudioTheme(parameters: {
      duration: number
      part: number
      id: string
      language?: string
    }) {
      return graphql.query(`mutation { audioThemeTrackUpload(
        duration: ${parameters.duration},
        part: "${parameters.part}",
        themeId: "${parameters.id}",
        language: "${parameters.language ?? 'fr'}"
        cache: ${Date.now()}
      ) }`)
    },
    async uncacheAudioTheme(parameters: {
      duration: number
      part: number
      id: string
      language?: string
    }) {
      return graphql.query(`mutation { audioThemeTrackDelete(
        duration: ${parameters.duration},
        part: "${parameters.part}",
        themeId: "${parameters.id}",
        language: "${parameters.language ?? 'fr'}"
      ) }`)
    },
    async deleteAudioTheme(id: string) {
      return graphql.query<{
        audioThemeDelete: { _id: string }
      }>(`mutation {
        audioThemeDelete(_id: "${id}") { _id }
      }`)
    },

    // Audio books
    async createAudioBook(slug: string) {
      return graphql.query<{
        audioBookCreate: { _id: string; slug: string }
      }>(`mutation {
        audioBookCreate(record: { slug: "${slug}", language: "fr", chapters: [], backgrounds: [] }) { _id, slug }
      }`)
    },
    async updateAudioBook(book: Update<AudioBook>) {
      return (
        await graphql.query<{ audioBookUpdate: AudioBook }>(`mutation {
        audioBookUpdate(record: ${serializeRecord(
          book,
        )}) { ${audioBookSelection} }
      }`)
      ).audioBookUpdate
    },
    async setAudioBookChapterTitle(
      book: string,
      chapter: number,
      title: string,
    ) {
      return graphql.query(
        `mutation { audioBookSetChapterTitle(bookId: "${book}", chapterIndex: ${chapter}, title: "${title}") }`,
      )
    },
    async removeLastAudioBookChapter(book: string) {
      return graphql.query(
        `mutation { audioBookRemoveLastChapter(bookId: "${book}") }`,
      )
    },
    async uploadAudioBook(parameters: {
      id: string
      chapter?: number
      part: number
      duration: number
    }) {
      return graphql.query(`mutation { audioBookUpload(
        duration: ${parameters.duration},
        part: "${parameters.part}",
        bookId: "${parameters.id}",
        chapterIndex: ${parameters.chapter ?? 0}
        cache: ${Date.now()}
      ) }`)
    },
    async uncacheAudioBook(parameters: {
      id: string
      chapter?: number
      part: number
      duration: number
    }) {
      return graphql.query(`mutation { audioBookTrackDelete(
        duration: ${parameters.duration},
        part: "${parameters.part}",
        bookId: "${parameters.id}",
        chapterIndex: ${parameters.chapter ?? 0}
      ) }`)
    },
    async deleteAudioBook(id: string) {
      return graphql.query<{
        audioBookDelete: { _id: string }
      }>(`mutation {
        audioBookDelete(_id: "${id}") { _id }
      }`)
    },

    // Audio background
    async createAudioBackground(slug: string) {
      return graphql.query<{
        audioBackgroundCreate: { _id: string; slug: string }
      }>(`mutation {
        audioBackgroundCreate(record: { slug: "${slug}" }) { _id, slug }
      }`)
    },
    async updateAudioBackground(background: Update<AudioBackground>) {
      return (
        await graphql.query<{
          audioBackgroundUpdate: AudioBackground
        }>(`mutation {
        audioBackgroundUpdate(record: ${serializeRecord(
          background,
        )}) { ${audioBackgroundSelection} }
      }`)
      ).audioBackgroundUpdate
    },
    async uploadAudioBackground(parameters: { duration: number; id: string }) {
      return graphql.query(`mutation { audioBackgroundDurationTrackUpload(
        duration: ${parameters.duration},
        backgroundId: "${parameters.id}",
        cache: ${Date.now()}
      ) }`)
    },
    async uncacheAudioBackground(parameters: { duration: number; id: string }) {
      return graphql.query(`mutation { audioBackgroundDurationTrackDelete(
        duration: ${parameters.duration},
        backgroundId: "${parameters.id}",
      ) }`)
    },
    async deleteAudioBackground(id: string) {
      return graphql.query<{
        audioBackgroundDelete: { _id: string }
      }>(`mutation {
        audioBackgroundDelete(_id: "${id}") { _id }
      }`)
    },

    // Users
    userQuitSpace(user: string, space: string) {
      return graphql.query(
        `mutation { userQuitSpace(user: "${user}", space: "${space}") }`,
      )
    },
    deleteUser(user: string) {
      return graphql.query(`mutation { userDelete(user: "${user}") }`)
    },
  },
}

// Loaders
const spaceStatisticsLoader = new DataLoader<
  string,
  {
    statistics: {
      users: number
      naps: number
      rooms: number
      cocoons: number
    }
  }
>(async (ids) => {
  const { spaceList } = await graphql.queries.getSpacesStatistics(ids)

  return ids.map((id) => spaceList.find((space) => space._id === id)!)
})
