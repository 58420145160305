import React, { useState } from 'react'

import { buildComponent } from '../components/factory'
import { useStoreState, useStoreActions } from '../hooks/state'

import * as UI from '@mantine/core'
import { useIntersection } from '@mantine/hooks'

const Space = buildComponent<{
  space: { _id: string; name: string }
  selected: string | null
  setSelected: (s: string | null) => void
}>().withRender(({ props }) => {
  const { space, selected, setSelected } = props
  const { ref, entry } = useIntersection()
  const showContent = !!entry?.isIntersecting

  return (
    <div
      ref={ref}
      style={{ height: '5rem', overflow: 'hidden', width: '300px' }}
    >
      {showContent && (
        <UI.NavLink
          key={space._id}
          label={space.name}
          title={space.name}
          active={selected === space._id}
          onClick={() => setSelected(selected === space._id ? null : space._id)}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
            whiteSpace: 'nowrap',
          }}
        />
      )}
    </div>
  )
})

export const SpaceList = buildComponent<{ kind: 'user' | 'statistics' }>()
  .withLifecycle(({ props }) => {
    const [search, setSearch] = useState('')
    const selected = useStoreState((store) => store[props.kind].space)
    const setSelected = useStoreActions((store) => store[props.kind].setSpace)
    const spaces = useStoreState((store) =>
      store.space.list
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter((space) =>
          space.name.toLowerCase().includes(search.toLowerCase()),
        ),
    )

    return { spaces, selected, setSelected, search, setSearch }
  })
  .withRender(({ lifecycle }) => (
    <UI.Navbar width={{ base: 300 }}>
      <UI.Navbar.Section py={8} px={16}>
        <UI.TextInput
          value={lifecycle.search}
          onChange={(e) => lifecycle.setSearch(e.target.value)}
          placeholder="Chercher un espace"
          autoComplete="off"
        />
      </UI.Navbar.Section>

      <UI.Navbar.Section grow component={UI.ScrollArea}>
        {lifecycle.spaces.map((space) => (
          <Space
            key={space._id}
            space={space}
            selected={lifecycle.selected}
            setSelected={lifecycle.setSelected}
          />
        ))}
      </UI.Navbar.Section>
    </UI.Navbar>
  ))
