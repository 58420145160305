import React from 'react'

import { graphql } from '../../../../services/graphql'

import { useSynchronizeData } from '../../../../hooks/use-synchronize-data'

import { buildComponent } from '../../../../components/factory'

import * as UI from '@mantine/core'
import * as Elements from '../../../../components/elements'

const EditImage = buildComponent<{
  id?: string
  slug?: string
}>().withRender(({ props }) => (
  <UI.Input.Wrapper
    style={{ flex: 1 }}
    label={
      <b style={{ fontSize: '2rem' }}>Image de fond SVG pour application v1</b>
    }
    description="Image rectangulaire utilisée dans la configuration de l'audio. Format SVG."
  >
    <Elements.LegacyImageButton
      kind="books"
      variant="image"
      slug={props.slug}
      entity={props.id}
      width={255}
      height={180}
    />
  </UI.Input.Wrapper>
))

export const SectionLegacyImages = buildComponent<{ book: string }>()
  .withLifecycle(({ props }) => {
    return useSynchronizeData(
      graphql.queries.getAudioBook,
      () => graphql.queries.getAudioBook(props.book),
      props.book,
    )
  })
  .withRender(({ lifecycle }) => (
    <UI.Group mt={16}>
      <EditImage id={lifecycle.data?._id} slug={lifecycle.data?.slug} />

      {lifecycle.loading && (
        <UI.Loader
          sx={{ position: 'absolute', top: '1rem', right: '1rem' }}
          size="xs"
        />
      )}
    </UI.Group>
  ))
