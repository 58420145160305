import React from 'react'

import { buildComponent } from '../factory'

import * as UI from '@mantine/core'
import { Localized } from '../../state/models/space'

export const LocalizedInput = buildComponent<{
  value?: Localized
  onChange?: (next: Localized) => void
}>().withRender(({ props }) => (
  <UI.Stack spacing={0} style={{ width: '100%' }}>
    <UI.Input
      icon="FR"
      mb={0}
      value={props.value?.fr ?? ''}
      onChange={(e: { currentTarget: { value: string } }) => {
        props.onChange?.({
          fr: e.currentTarget.value,
          en: props.value?.en ?? '',
        })
      }}
      styles={(theme) => ({
        input: {
          paddingLeft: '8rem !important',
          borderRadius: '3rem',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
          boxShadow: 'none',
          ':focus': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.05) !important',
          },
        },
        icon: {
          borderRadius: '3rem 0 0 0',
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          color: theme.colors.dark[7],
          fontWeight: 700,
          fontSize: '1.5rem',
          width: '6rem',
        },
      })}
    />
    <UI.Input
      icon="EN"
      mt={0}
      value={props.value?.en ?? ''}
      onChange={(e: { currentTarget: { value: string } }) => {
        props.onChange?.({
          en: e.currentTarget.value,
          fr: props.value?.fr ?? '',
        })
      }}
      styles={(theme) => ({
        input: {
          paddingLeft: '8rem !important',
          borderRadius: '3rem',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
        icon: {
          borderRadius: '0 0 0 3rem',
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          color: theme.colors.dark[7],
          fontWeight: 700,
          fontSize: '1.5rem',
          width: '6rem',
        },
      })}
    />
  </UI.Stack>
))
