import React from 'react'
import { format, startOfDay, startOfMonth } from 'date-fns'

import { Localized } from '../../../../state/models/space'

import { buildComponent } from '../../../../components/factory'

import { EventAudioPlay } from './data'
import { useChartValues, BarGraph, PieChart } from './graphs'

export const AudioKindRepartitionPie = buildComponent<{
  eventsAudioPlay: EventAudioPlay[]
}>()
  .withLifecycle(({ props }) => {
    return useChartValues({
      events: props.eventsAudioPlay,
      label: (event) => {
        if (event.theme) {
          return 'Sophrologie'
        } else if (event.book) {
          return 'Livres audios'
        } else {
          return 'Ambiances sonores'
        }
      },
    })
  })
  .withRender(({ lifecycle }) => (
    <PieChart
      shifted
      data={lifecycle.data}
      single={{
        label: "Un seul type d'audio écouté",
        kind: 'écoute',
      }}
    />
  ))

export const AudioKindDurationRepartitionPie = buildComponent<{
  eventsAudioPlay: EventAudioPlay[]
}>()
  .withLifecycle(({ props }) => {
    return useChartValues({
      events: props.eventsAudioPlay,
      label: (event) => {
        return event.duration + ' min'
      },
    })
  })
  .withRender(({ lifecycle }) => (
    <PieChart
      shifted
      data={lifecycle.data}
      single={{
        label: "Un seul type d'audio écouté",
        kind: 'minute',
      }}
    />
  ))

export const AudioPlayByPeriodBars = buildComponent<{
  annual: boolean
  eventsAudioPlay: EventAudioPlay[]
}>()
  .withLifecycle(({ props }) => {
    return useChartValues({
      events: props.eventsAudioPlay,
      label: (event) => {
        const date = new Date(event.date)
        return props.annual
          ? format(startOfMonth(date), 'MM/yyyy')
          : format(startOfDay(date), 'dd/MM/yyyy')
      },
    })
  })
  .withRender(({ lifecycle }) => <BarGraph data={lifecycle.data} />)

export const AudioMainRepartitionBars = buildComponent<{
  kind: 'sophrology' | 'audiobooks'
  themes?: { _id: string; slug: string; short: Localized }[]
  books?: { _id: string; slug: string; title: { short: string } }[]
  eventsAudioPlay: EventAudioPlay[]
}>()
  .withLifecycle(({ props }) => {
    const field = props.kind === 'sophrology' ? 'theme' : 'book'

    return useChartValues({
      events: props.eventsAudioPlay.filter((event) => event[field]),
      label: (event) =>
        props.kind === 'sophrology'
          ? props.themes?.find((t) =>
              [t._id, t.slug].includes(event[field] as string),
            )?.short?.fr ?? 'Autre'
          : props.books?.find((t) =>
              [t._id, t.slug].includes(event[field] as string),
            )?.title?.short ?? 'Autre',
    })
  })
  .withRender(({ lifecycle }) => (
    <BarGraph data={lifecycle.data} color="#6299e8" />
  ))

export const AudioBackgroundRepartitionBars = buildComponent<{
  kind: 'sophrology' | 'audiobooks' | 'soundscapes'
  backgrounds: { _id: string; slug: string; short: Localized }[]
  eventsAudioPlay: EventAudioPlay[]
}>()
  .withLifecycle(({ props }) => {
    const field = props.kind === 'sophrology' ? 'theme' : 'book'

    return useChartValues({
      events: props.eventsAudioPlay.filter((event) =>
        props.kind === 'soundscapes'
          ? !event.theme && !event.book
          : event[field],
      ),
      label: (event) =>
        props.backgrounds.find((bg) =>
          [bg._id, bg.slug].includes(event.background as string),
        )?.short?.fr ?? 'Autre',
    })
  })
  .withRender(({ lifecycle, props }) => (
    <BarGraph
      data={lifecycle.data}
      color={props.kind === 'soundscapes' ? '#6299e8' : '#6299e8'}
    />
  ))

export const AudioDurationRepartitionPie = buildComponent<{
  kind: 'sophrology' | 'audiobooks' | 'soundscapes'
  eventsAudioPlay: EventAudioPlay[]
}>()
  .withLifecycle(({ props }) => {
    const field = props.kind === 'sophrology' ? 'theme' : 'book'

    return useChartValues({
      events: props.eventsAudioPlay.filter((event) =>
        props.kind === 'soundscapes'
          ? !event.theme && !event.book
          : event[field],
      ),
      label: (event) =>
        event.duration + (props.kind === 'soundscapes' ? ' minutes' : ' min'),
      sort: (a, b) => Number.parseFloat(a.label) - Number.parseFloat(b.label),
    })
  })
  .withRender(({ lifecycle, props }) => (
    <PieChart
      bottom={props.kind !== 'soundscapes'}
      shifted={props.kind === 'soundscapes'}
      data={lifecycle.data}
      single={{
        label: 'Une seule durée écoutée',
        kind: 'audio',
      }}
    />
  ))
