import React from 'react'

import { AudioBackground } from '../../../../state/models/audio'
import { Localized } from '../../../../state/models/space'
import { graphql } from '../../../../services/graphql'

import {
  DeepPartial,
  useSynchronizeData,
} from '../../../../hooks/use-synchronize-data'

import { buildComponent } from '../../../../components/factory'

import * as UI from '@mantine/core'
import * as Elements from '../../../../components/elements'
import * as Semantics from '../../../../components/semantics'

const ShowUniqueIdentifier = buildComponent<{ slug?: string }>().withRender(
  ({ props }) => (
    <UI.Group>
      <UI.Text weight={700}>Identifiant unique</UI.Text>
      <UI.Kbd mb={-4} px={16}>
        {props.slug ?? '...'}
      </UI.Kbd>
    </UI.Group>
  ),
)

const EditName = buildComponent<
  Partial<AudioBackground> & {
    update: (next: DeepPartial<AudioBackground>) => void
  }
>()
  .withLifecycle(({ props }) => ({
    update: (field: 'long' | 'short') => (value: Localized) =>
      props.update({ [field]: value }),
  }))
  .withRender(({ props, lifecycle }) => (
    <UI.Stack sx={{ width: '100%' }}>
      <UI.Input.Wrapper
        sx={{ flex: 1 }}
        label={<b style={{ fontSize: '2rem' }}>Nom du fond sonore</b>}
        description="Affiché lors de la configuration de l'audio et dans le lecteur"
      >
        <Semantics.LocalizedInput
          value={props.long}
          onChange={lifecycle.update('long')}
        />
      </UI.Input.Wrapper>
      <UI.Input.Wrapper
        sx={{ flex: 1 }}
        label={<b style={{ fontSize: '2rem' }}>Nom court</b>}
        description="Affiché dès lors que le nom complet serait trop long, par exemple sur les cartes de sieste"
      >
        <Semantics.LocalizedInput
          value={props.short}
          onChange={lifecycle.update('short')}
        />
      </UI.Input.Wrapper>
    </UI.Stack>
  ))

const EditIcon = buildComponent<
  Partial<AudioBackground> & {
    update: (next: DeepPartial<AudioBackground>) => void
  }
>().withRender(({ props }) => (
  <UI.Input.Wrapper
    style={{ width: '40rem' }}
    label={<b style={{ fontSize: '2rem' }}>Icône</b>}
    description="Icône carrée utilisée dans la configuration de l'audio. Format WEBP"
  >
    <Elements.ImageButton
      accept="webp"
      update={props.update}
      width={180}
      height={180}
      kind="backgrounds"
      variant="image"
      entity={props._id}
      slug={props.slug}
      image={props.image}
      fallback={props.slug}
    />
  </UI.Input.Wrapper>
))

export const SectionMain = buildComponent<{
  background: string
}>()
  .withLifecycle(({ props }) => {
    return useSynchronizeData(
      graphql.queries.getAudioBackground,
      graphql.mutations.updateAudioBackground,
      props.background,
    )
  })
  .withRender(({ lifecycle }) => (
    <UI.Group sx={{ flex: 1 }} spacing={64}>
      <UI.Stack spacing={24} sx={{ flex: 1, width: '100%' }}>
        <ShowUniqueIdentifier slug={lifecycle.data?.slug} />
        <EditName {...lifecycle.data} update={lifecycle.update} />
      </UI.Stack>

      <EditIcon {...lifecycle.data} update={lifecycle.update} />

      {lifecycle.loading && (
        <UI.Loader
          sx={{ position: 'absolute', top: '1rem', right: '1rem' }}
          size="xs"
        />
      )}
    </UI.Group>
  ))
