import * as Modals from '@mantine/modals'
import * as UI from '@mantine/core'
import React from 'react'

export const serviceModals = {
  async confirmAction(
    title: string | React.ReactNode,
    content: string | React.ReactNode,
    onConfirm = () => {},
  ) {
    await new Promise<void>((resolve, reject) => {
      Modals.openConfirmModal({
        title: <UI.Text weight="bold">{title}</UI.Text>,
        children: <UI.Text size="sm">{content}</UI.Text>,
        zIndex: 1000,
        labels: { confirm: 'Oui', cancel: 'Non' },
        onCancel: reject,
        onConfirm: () => {
          onConfirm()
          resolve()
        },
        confirmProps: {
          px: 32,
          sx: { minHeight: '3rem' },
        },
        cancelProps: {
          px: 32,
          sx: { minHeight: '3rem' },
          color: 'gray',
          variant: 'filled',
        },
      })
    })
  },
  async confirmDeletion(
    title: string | React.ReactNode,
    content: string | React.ReactNode,
    onConfirm = () => {},
  ) {
    await new Promise<void>((resolve, reject) => {
      Modals.openConfirmModal({
        title: <UI.Text weight="bold">{title}</UI.Text>,
        children: (
          <UI.Text size="sm">{content} Cette action est irréversible.</UI.Text>
        ),
        zIndex: 1000,
        labels: { confirm: 'Oui', cancel: 'Non' },
        onCancel: reject,
        onConfirm: () => {
          onConfirm()
          resolve()
        },
        confirmProps: {
          px: 32,
          sx: { minHeight: '3rem' },
          color: 'red',
        },
        cancelProps: {
          px: 32,
          sx: { minHeight: '3rem' },
          color: 'gray',
          variant: 'filled',
        },
      })
    })
  },
  async inform(
    title: string | React.ReactNode,
    content: string | React.ReactNode,
  ) {
    await new Promise<void>((resolve) => {
      Modals.openModal({
        title: <UI.Text weight="bold">{title}</UI.Text>,
        children: (
          <>
            <UI.Text size="sm">{content}</UI.Text>

            <UI.Group mt={16}>
              <UI.Button
                ml="auto"
                onClick={() => {
                  Modals.closeAllModals()
                  resolve()
                }}
                px={32}
                sx={{ minHeight: '3rem' }}
              >
                Fermer
              </UI.Button>
            </UI.Group>
          </>
        ),
        zIndex: 1000,
      })
    })
  },
}
