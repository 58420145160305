import React, { useState } from 'react'
import startOfDay from 'date-fns/startOfDay'
import setMinutes from 'date-fns/setMinutes'
import setHours from 'date-fns/setHours'
import format from 'date-fns-tz/format'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import cityTimezones from 'city-timezones'

import { SpaceMaintenance } from '../../../../state/models/space'
import { graphql } from '../../../../services/graphql'

import { useSynchronizeData } from '../../../../hooks/use-synchronize-data'

import { buildComponent } from '../../../../components/factory'

import * as UI from '@mantine/core'
import * as Dates from '@mantine/dates'
import * as Icons from '../../../../components/icons'

const MaintenanceSlot = buildComponent<
  SpaceMaintenance['maintenances'][number] & {
    cocoons?: SpaceMaintenance['cocoons']
    rooms?: SpaceMaintenance['rooms']
    update: (next: Partial<SpaceMaintenance['maintenances'][number]>) => void
    remove: () => void
    showTimezone: boolean
    timezone: string
  }
>().withRender(({ props }) => (
  <>
    <UI.Group p={16}>
      <UI.Text weight="bold">Maintenance prévue</UI.Text>
      <UI.Text>Début (inclus)</UI.Text>
      <Dates.DatePicker
        withinPortal
        value={new Date(props.start)}
        onChange={(date) => date && props.update({ start: date.toISOString() })}
        rightSection={
          <Dates.TimeInput
            variant="default"
            value={new Date(props.start)}
            onChange={(date) => {
              const startDate = new Date(props.start)
              const nextDate = setHours(
                setMinutes(startDate, date.getMinutes()),
                date.getHours(),
              )
              const fn =
                nextDate.toISOString() > startDate.toISOString()
                  ? 'ceil'
                  : 'floor'
              props.update({
                start: setMinutes(
                  nextDate,
                  Math.min(55, Math[fn](date.getMinutes() / 5) * 5),
                ).toISOString(),
              })
            }}
          />
        }
        sx={{ width: '20rem' }}
        mr={24}
      />
      <UI.Text>Fin (exclus)</UI.Text>
      <Dates.DatePicker
        withinPortal
        value={new Date(props.end)}
        onChange={(date) => date && props.update({ end: date.toISOString() })}
        rightSection={
          <Dates.TimeInput
            variant="default"
            value={new Date(props.end)}
            onChange={(date) => {
              const endDate = new Date(props.end)
              const nextDate = setHours(
                setMinutes(endDate, date.getMinutes()),
                date.getHours(),
              )
              const fn =
                nextDate.toISOString() > endDate.toISOString()
                  ? 'ceil'
                  : 'floor'
              props.update({
                end: setMinutes(
                  nextDate,
                  Math.min(55, Math[fn](date.getMinutes() / 5) * 5),
                ).toISOString(),
              })
            }}
          />
        }
        sx={{ width: '20rem' }}
        mr={24}
      />
      <UI.Text>Cible</UI.Text>
      <UI.Select
        withinPortal
        itemComponent={({ label, ...rest }) => (
          <UI.Text {...rest}>{label}</UI.Text>
        )}
        value={props.target ?? 'space'}
        data={[
          { label: "Tous l'Espace", value: 'space' },
          ...(props.rooms ?? []).flatMap((room) => [
            {
              label: 'Salle - ' + room.name,
              value: room._id,
            },
            ...(props.cocoons ?? [])
              .filter((cocoon) => cocoon.room._id === room._id)
              .map((cocoon) => ({
                label: '\u00A0\u00A0\u00A0Cocon - ' + cocoon.name,
                value: cocoon._id,
              })),
          ]),
        ]}
        onChange={(value) =>
          props.update({ target: value === 'space' ? null : value })
        }
        styles={{ wrapper: { width: 'auto' } }}
      />
      <UI.ActionIcon ml="auto" size="lg" onClick={props.remove}>
        <UI.Text color="red">
          <Icons.FontAwesomeIcon
            color="currentColor"
            icon={Icons.Solid.faTrash}
          />
        </UI.Text>
      </UI.ActionIcon>
    </UI.Group>
    {props.showTimezone && (
      <UI.Group
        pl={100}
        sx={{ backgroundColor: 'rgba(0, 0, 0, 0.02)', height: '6rem' }}
      >
        <UI.Box sx={{ width: 280, position: 'relative' }}>
          <UI.Text
            size="sm"
            sx={{ position: 'absolute', right: -110, top: -10 }}
          >
            <b>{props.timezone}:</b>{' '}
            {format(
              utcToZonedTime(new Date(props.start), props.timezone),
              'dd/MM, HH:mm',
              { timeZone: props.timezone },
            )}
          </UI.Text>
        </UI.Box>
        <UI.Box sx={{ width: 280, position: 'relative' }}>
          <UI.Text
            size="sm"
            sx={{ position: 'absolute', right: -110, top: -10 }}
          >
            <b>{props.timezone}:</b>{' '}
            {format(
              utcToZonedTime(new Date(props.end), props.timezone),
              'dd/MM, HH:mm',
              { timeZone: props.timezone },
            )}
          </UI.Text>
        </UI.Box>
      </UI.Group>
    )}
    <UI.Divider />
  </>
))

const TimezonePicker = buildComponent<{
  showTimezone: boolean
  timezone: string
  setTimezone: (timezone: string) => void
}>()
  .withLifecycle(() => {
    const [search, setSearch] = useState('')
    const data = cityTimezones
      .findFromCityStateProvince(search)
      .map((res) => ({
        value: res.timezone,
        label: `${res.city}, ${res.country}`,
      }))
      .filter(
        (res) =>
          res.value && res.label.toLowerCase().includes(search.toLowerCase()),
      )
      .filter((e, i, a) => a.findIndex((res) => res.value === e.value) === i)

    return { search, setSearch, data }
  })
  .withRender(({ props, lifecycle }) => {
    if (!props.showTimezone) {
      return null
    }

    return (
      <UI.Select
        searchable
        searchValue={lifecycle.search}
        onSearchChange={lifecycle.setSearch}
        styles={{ wrapper: { width: '32rem' } }}
        data={lifecycle.data}
        value={props.timezone}
        onChange={props.setTimezone}
        withinPortal
        itemComponent={({ label, ...rest }) => (
          <UI.Text {...rest}>{label}</UI.Text>
        )}
      />
    )
  })

export const SectionMaintenance = buildComponent<{ space: string }>()
  .withLifecycle(({ props }) => {
    const [timezone, setTimezone] = useState('Europe/Paris')
    const [showTimezone, setShowTimezone] = useState(false)

    const {
      loading,
      data: space,
      update,
    } = useSynchronizeData(
      graphql.queries.getSpaceMaintenance,
      graphql.mutations.updateSpaceMaintenance,
      props.space,
    )

    const addMaintenanceSlot = async () => {
      update({
        maintenances: [
          ...(space?.maintenances ?? []),
          {
            start: startOfDay(new Date()).toISOString(),
            end: startOfDay(new Date()).toISOString(),
            target: null,
          },
        ],
      })
    }

    const updateMaintenanceSlot =
      (index: number) =>
      (maintenance: Partial<SpaceMaintenance['maintenances'][number]>) => {
        if (maintenance.target === 'space') {
          maintenance.target = null
        }
        const maintenances = space?.maintenances ?? []
        maintenances[index] = {
          ...maintenances[index],
          ...maintenance,
        }
        update({ maintenances })
      }
    const removeMaintenanceSlot = (index: number) => () => {
      const maintenances = space?.maintenances ?? []
      maintenances.splice(index, 1)
      update({ maintenances })
    }

    return {
      space,
      update,
      loading,
      timezone,
      setTimezone,
      showTimezone,
      setShowTimezone,
      addMaintenanceSlot,
      updateMaintenanceSlot,
      removeMaintenanceSlot,
    }
  })
  .withRender(({ lifecycle }) => (
    <UI.Input.Wrapper
      label={<b style={{ fontSize: '2rem' }}>Maintenance</b>}
      description={
        <UI.Text mt={8} size="sm" sx={{ maxWidth: '124rem' }}>
          Configurez ci-dessous les créneaux de maintenance. Ces créneaux
          peuvent être configurés au niveau de l'<b>Espace</b> (tous les cocons
          dans toutes les salles sont inaccessibles pendant la période), de la{' '}
          <b>Salle</b> (tous les cocons de cette salle sont inaccessibles), ou
          d'un <b>Cocon</b> (seul ce cocon est inaccessible).
        </UI.Text>
      }
    >
      <UI.Group mb={-16} mt={8} spacing={32} sx={{ height: '8rem' }}>
        <UI.Checkbox
          label="Afficher les informations de fuseau horaire"
          checked={lifecycle.showTimezone}
          onChange={(e) => lifecycle.setShowTimezone(e.currentTarget.checked)}
        />
        <TimezonePicker
          showTimezone={lifecycle.showTimezone}
          timezone={lifecycle.timezone}
          setTimezone={lifecycle.setTimezone}
        />
      </UI.Group>

      {lifecycle.showTimezone && (
        <UI.Text color="dimmed" size="sm" mt={16}>
          L'heure sélectionnée pour le début et la fin du créneau est
          systématiquement dans le fuseau horaire de votre ordinateur.
          <br />
          En choisissant un fuseau horaire, vous pouvez visualiser l'heure de
          début et de fin d'un créneau de maintenance pour un fuseau horaire
          donné, autre que celui de votre ordinateur. Pratique par exemple pour
          prévoir une maintenance pour un espace en Amérique !
        </UI.Text>
      )}

      <UI.Card p={0} mt={16} withBorder>
        {lifecycle.space?.maintenances?.map((maintenance, index) => (
          <MaintenanceSlot
            {...maintenance}
            key={index}
            update={lifecycle.updateMaintenanceSlot(index)}
            remove={lifecycle.removeMaintenanceSlot(index)}
            cocoons={lifecycle.space?.cocoons}
            rooms={lifecycle.space?.rooms}
            showTimezone={lifecycle.showTimezone}
            timezone={lifecycle.timezone}
          />
        ))}
        <UI.Group position="right" p={16}>
          <UI.Button onClick={lifecycle.addMaintenanceSlot}>
            Ajouter une période de maintenance
          </UI.Button>
        </UI.Group>
      </UI.Card>

      {lifecycle.loading && (
        <UI.Loader
          sx={{ position: 'absolute', top: '1rem', right: '1rem' }}
          size="xs"
        />
      )}
    </UI.Input.Wrapper>
  ))
