import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useLocation } from 'wouter'

import { graphql } from '../../../services/graphql'

import { useForm } from '@mantine/form'
import { useStoreState } from '../../../hooks/state'
import { useSessionValue } from '../../../hooks/use-session-value'

import { buildComponent } from '../../../components/factory'

import { Space } from './components/space'

import * as UI from '@mantine/core'
import * as Modals from '@mantine/modals'
import * as Icons from '../../../components/icons'

const CreateSpace = buildComponent()
  .withLifecycle(() => {
    const [, setLocation] = useLocation()
    const [submitting, setSubmitting] = useState(false)
    const form = useForm({
      initialValues: {
        name: '',
        code: '',
      },
    })

    const submit = form.onSubmit(async () => {
      try {
        setSubmitting(true)
        form.clearErrors()
        const result = await graphql.mutations.createSpace({
          localizedName: { fr: form.values.name },
          codes: [{ code: form.values.code }],
        })
        Modals.closeAllModals()

        setLocation('/spaces/edit/' + result.spaceCreate._id)
      } catch (err: any) {
        if ('message' in err) {
          if (err.message === 'code_unavailable') {
            return form.setFieldError(
              'code',
              'Ce code est déjà utilisé par un autre Espace',
            )
          }
        }

        form.setFieldError(
          'name',
          "Une erreur est survenue lors de la création de l'Espace",
        )
      } finally {
        setSubmitting(false)
      }
    })

    return { form, submit, submitting }
  })
  .withRender(({ lifecycle }) => (
    <form onSubmit={lifecycle.submit}>
      <UI.Stack>
        <UI.TextInput
          label="Nom de l'Espace"
          {...lifecycle.form.getInputProps('name')}
        />
        <UI.TextInput
          label="Code de l'Espace"
          {...lifecycle.form.getInputProps('code')}
        />
        <UI.Button
          mx="auto"
          mb={16}
          sx={{ minHeight: '4rem' }}
          type="submit"
          loading={lifecycle.submitting}
        >
          Créer l'Espace
        </UI.Button>
      </UI.Stack>
    </form>
  ))

export const ScreenConnectedSpaces = buildComponent<RouteComponentProps>()
  .withLifecycle(() => {
    const [, setLocation] = useLocation()
    const list = useStoreState((store) => store.space.list)
    const [search, setSearch] = useSessionValue<string>('SpaceSearch', '')
    const [_statuses, setStatuses] = useSessionValue<string>(
      'SpaceStatuses',
      '["expiring", "active", "pending"]',
    )
    const statuses = JSON.parse(_statuses)

    const spaces = list.map((space) => ({
      ...space,
      show:
        statuses.includes(space.status) &&
        (space.name.toLowerCase().includes(search.toLowerCase()) ||
          space.code?.toLowerCase().includes(search.toLowerCase())),
    }))

    const createSpace = () => {
      Modals.openModal({
        title: <UI.Text weight="bold">Créer un Espace</UI.Text>,
        children: <CreateSpace />,
        padding: 'lg',
      })
    }

    useEffect(() => {
      if (statuses.length === 0) {
        setStatuses('["expiring", "active", "pending"]')
      }
    }, [statuses, setStatuses])

    const updateStatuses = (next: string[]) => setStatuses(JSON.stringify(next))
    const updateSearch = setSearch
    const selectSpace = (space: { value: string; id: string }) => {
      setSearch('')
      setLocation('/spaces/edit/' + space.id)
    }

    return {
      spaces,
      search,
      updateSearch,
      createSpace,
      statuses,
      updateStatuses,
      selectSpace,
    }
  })
  .withRender(({ lifecycle }) => (
    <React.Fragment>
      <UI.Stack
        mx={-16}
        px={16}
        mt={-16}
        py={16}
        sx={{
          position: 'sticky',
          top: 72,
          zIndex: 10,
          backgroundColor: '#f8f9fc',
        }}
        spacing={0}
      >
        <UI.Group position="apart">
          <UI.Stack spacing={0}>
            <UI.Title>
              <UI.Text size={22}>Espaces clients</UI.Text>
            </UI.Title>
            <UI.Text color="dimmed">
              Tous les espaces Nap&Up sont regroupés ici.
            </UI.Text>
            <UI.Text color="dimmed">
              Visualisez les statistiques, modifiez leurs paramètres, ou ajoutez
              un tout nouvel espace à la liste !
            </UI.Text>
          </UI.Stack>

          <UI.Button
            px="4rem"
            leftIcon={<Icons.FontAwesomeIcon icon={Icons.Solid.faPlus} />}
            onClick={lifecycle.createSpace}
          >
            Créer un espace
          </UI.Button>
        </UI.Group>

        <UI.Stack mx="auto" mt="lg" align="center" spacing={4}>
          <UI.Autocomplete
            mx={8}
            data={lifecycle.spaces
              .map((s) => ({ value: s.name, id: s._id }))
              .sort((a, b) => a.value.localeCompare(b.value))}
            placeholder="Chercher un espace par son nom ou son code d'accès"
            variant="default"
            rightSection={
              <Icons.FontAwesomeIcon
                color="#AAAAAA"
                icon={Icons.Solid.faSearch}
                style={{ marginRight: '2rem' }}
              />
            }
            value={lifecycle.search}
            onChange={lifecycle.updateSearch}
            onItemSubmit={lifecycle.selectSpace}
          />

          <UI.Chip.Group
            multiple
            value={lifecycle.statuses}
            onChange={lifecycle.updateStatuses}
          >
            <UI.Chip value="active">Actifs</UI.Chip>
            <UI.Chip value="expiring">Prêts à expirer</UI.Chip>
            <UI.Chip value="pending">En attente</UI.Chip>
            <UI.Chip value="expired">Expirés</UI.Chip>
            <UI.Chip value="inactive">Désactivés</UI.Chip>
          </UI.Chip.Group>
        </UI.Stack>
      </UI.Stack>

      <UI.Group mt="lg" mb={160} position="center" spacing="xl">
        {lifecycle.spaces.map((space) => (
          <Space key={space._id} show={space.show} space={space} />
        ))}
      </UI.Group>
    </React.Fragment>
  ))
