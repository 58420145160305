import React, { useEffect, useState } from 'react'

import { SpaceList } from '../../../../state/models/space'
import { graphql } from '../../../../services/graphql'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'

import { buildComponent } from '../../../../components/factory'

import { useIntersection } from '@mantine/hooks'

import * as UI from '@mantine/core'
import * as Routing from 'wouter'
import * as Icons from '../../../../components/icons'

const useStyles = UI.createStyles((theme) => ({
  card: {
    width: 460,
    minHeight: 220,
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  label: {
    fontWeight: 700,
    lineHeight: 1,
  },

  lead: {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: 1,
  },

  inner: {
    display: 'flex',

    [theme.fn.smallerThan(350)]: {
      flexDirection: 'column',
    },
  },
}))

const statuses = {
  pending: 'En attente',
  active: 'Actif',
  expiring: 'Expire bientôt',
  expired: 'Expiré',
  inactive: 'Désactivé',
}
const statusColor = {
  pending: 'gray',
  active: 'blue',
  expiring: 'orange',
  expired: 'danger',
  inactive: 'danger',
} as const

const Stat = buildComponent<{
  main?: boolean
  label: string
  value?: number
  suffix?: number
  loading: boolean
}>().withRender(({ props }) => (
  <div>
    <UI.Skeleton visible={props.loading}>
      <UI.Text mb={-4} size={props.main ? 'xl' : 'md'} weight={700}>
        {props.value ?? 0}
        {(props.suffix ?? 0) > 0 && (
          <UI.Text component="span" size="xs">
            {' / '}
            {props.suffix}
          </UI.Text>
        )}
      </UI.Text>
      <UI.Text size="xs" color="dimmed">
        {props.label}
      </UI.Text>
    </UI.Skeleton>
  </div>
))

const Info = buildComponent<{ label: string }>().withRender(
  ({ props, children }) => (
    <UI.Group mt={8} position="right" align="end" spacing={8}>
      <UI.Text size="xs" color="dimmed">
        {props.label}
      </UI.Text>
      <UI.Text size="sm" weight="bold" sx={{ width: 112 }}>
        {children}
      </UI.Text>
    </UI.Group>
  ),
)

export const Space = buildComponent<{
  show: boolean
  space: SpaceList
}>()
  .withLifecycle(({ props }) => {
    const [statistics, setStatistics] = useState<null | {
      statistics: {
        users: number
        naps: number
        rooms: number
        cocoons: number
      }
    }>(null)

    const { ref, entry } = useIntersection({
      threshold: 0.2,
    })

    useEffect(() => {
      if (statistics === null && entry?.isIntersecting) {
        graphql.queries.getSpaceStatistics(props.space._id).then(setStatistics)
      }
    }, [props.space._id, entry, statistics])

    return {
      ref,
      display: !!entry?.isIntersecting,
      statistics: statistics?.statistics,
      loading: statistics === null,
    }
  })
  .withRender(({ props, lifecycle }) => {
    const { classes } = useStyles()

    if (!props.show) {
      return null
    }

    const isPremium = (() => {
      if (
        !props.space.subscription ||
        (props.space.subscription && !props.space.subscription.premium)
      ) {
        return false
      } else if (!props.space.subscription.end) {
        return true
      }
      return new Date(props.space.subscription.end) > new Date()
    })()

    return (
      <UI.Card
        ref={lifecycle.ref}
        withBorder
        p="xl"
        radius="md"
        className={classes.card}
      >
        {lifecycle.display && (
          <>
            <UI.Group noWrap position="apart" align="start">
              <div>
                <UI.Text
                  size="xl"
                  className={classes.label}
                  color={
                    props.space.status === 'inactive' ? 'dimmed' : undefined
                  }
                >
                  {props.space.name}
                </UI.Text>
                <UI.Text size="xs" color="dimmed" mt={8} mb={16}>
                  Code d'accès <UI.Kbd ml={4}>{props.space.code}</UI.Kbd>
                </UI.Text>
              </div>

              <Routing.Link to={`/spaces/edit/${props.space._id}`}>
                <UI.ActionIcon size="xl" mr={-16} mt={-16} component="a">
                  <UI.Text color="dimmed">
                    <Icons.FontAwesomeIcon
                      color="currentColor"
                      icon={Icons.Solid.faCog}
                    />
                  </UI.Text>
                </UI.ActionIcon>
              </Routing.Link>
            </UI.Group>

            <UI.Group position="apart">
              <div>
                <UI.Group>
                  <Stat
                    main
                    label="Utilisateurs"
                    value={lifecycle.statistics?.users}
                    suffix={props.space.subscription?.licenses ?? 0}
                    loading={lifecycle.loading}
                  />
                  <Stat
                    main
                    label="Siestes"
                    value={lifecycle.statistics?.naps}
                    loading={lifecycle.loading}
                  />
                </UI.Group>
                <UI.Group mt="lg">
                  <Stat
                    label="Salles"
                    value={lifecycle.statistics?.rooms}
                    loading={lifecycle.loading}
                  />
                  <Stat
                    label="Cocons"
                    value={lifecycle.statistics?.cocoons}
                    loading={lifecycle.loading}
                  />
                </UI.Group>
              </div>

              <div>
                <Info label="Abonnement">
                  <UI.Badge color={isPremium ? 'yellow' : undefined}>
                    {isPremium ? 'Premium' : 'Basique'}
                  </UI.Badge>
                </Info>

                <Info label="Statut">
                  <UI.Badge color={statusColor[props.space.status]}>
                    {statuses[props.space.status]}
                  </UI.Badge>
                </Info>

                {props.space.status === 'pending' &&
                  props.space.subscription?.start && (
                    <Info label="Date de démarrage">
                      {format(
                        new Date(props.space.subscription.start),
                        'd MMM yyyy',
                        { locale: fr },
                      )}
                    </Info>
                  )}

                {props.space.subscription?.end && (
                  <Info label="Date d'expiration">
                    {format(
                      new Date(props.space.subscription.end),
                      'd MMM yyyy',
                      {
                        locale: fr,
                      },
                    )}
                  </Info>
                )}
              </div>
            </UI.Group>
          </>
        )}
      </UI.Card>
    )
  })
