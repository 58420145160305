import React from 'react'
import { useMantineTheme } from '@mantine/core'

export function NapClock({
  duration,
  active,
  ...props
}: React.SVGProps<SVGSVGElement> & { duration: number; active: boolean }) {
  const theme = useMantineTheme()
  const marks = [...new Array(12)]
  const slices = [...new Array(duration / 5 - 1)]

  return (
    <svg
      {...props}
      color={active ? 'white' : theme.colors.darkblue[5]}
      viewBox="-1 -1 26 26"
    >
      {marks.map((_, mark) =>
        mark > slices.length + 1 ? (
          <path
            key={mark}
            transform={`rotate(${270 + mark * 30}, 12, 12)`}
            d="M21,12 L23,12"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="1.5"
          />
        ) : null,
      )}

      <path
        strokeWidth="0.3"
        stroke="currentColor"
        fill="currentColor"
        d="M12,12 L15.1,0.4 A12,12 0 0 0 12.6,0.1 A0.6,0.6 0 0 0 12,0.6Z"
      />

      {slices.map((_, slice) => (
        <path
          key={slice}
          transform={`rotate(${30 + 30 * slice},12,12)`}
          strokeWidth="0.3"
          stroke="currentColor"
          fill="currentColor"
          d="M12,12 L15.1,0.4 A12,12 0 0 0 12.9,0 L12.9,3.2 A0.8,0.8 0 0 1 11.1,3.2 L11.1,0.1 A12,12 0 0 0 8.9,0.4Z"
        />
      ))}

      <path
        transform={`rotate(${30 * (duration / 5)},12,12)`}
        strokeWidth="0.3"
        stroke="currentColor"
        fill="currentColor"
        d="M12,12 L8.9,0.4 A12,12 0 0 1 11.4,0.1 A0.6,0.6 0 0 1 12,0.6Z"
      />

      <circle
        cx="12"
        cy="12"
        r="4"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </svg>
  )
}
