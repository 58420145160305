import React, { useRef } from 'react'

import { buildComponent } from '../factory'
import { storage } from '../../services/firebase'

import * as UI from '@mantine/core'

export const ImageButton = buildComponent<{
  accept: 'webp' | 'png'
  update: (payload: { _id: string; image?: string; cover?: string }) => void
  width: number
  height: number
  entity?: string
  slug?: string
  image?: string
  fallback?: string
  kind: 'themes' | 'backgrounds' | 'books'
  variant: 'image' | 'cover'
}>()
  .withLifecycle(({ props }) => {
    const directoryPath =
      'images/' + props.kind + '/' + (props.slug ?? 'unknown')
    const imageName = props.image ?? props.fallback ?? props.variant
    const imageExtension = '.' + props.accept
    const image = directoryPath + '/' + imageName + imageExtension

    const resetRef = useRef<() => void>(null)

    const onChange = async (file: File | null) => {
      if (!file) {
        return
      }

      const next = Date.now() + '-' + props.variant
      await storage()
        .ref(directoryPath + '/' + next + imageExtension)
        .put(file)
      await storage()
        .ref(directoryPath + '/' + props.variant + imageExtension)
        .put(file)
      resetRef.current?.()

      if (props.entity) {
        props.update({
          _id: props.entity,
          [props.variant]: next,
        })
      }
    }

    return { image, onChange, resetRef }
  })
  .withRender(({ props, lifecycle }) => (
    <UI.Box mx="auto" mt={32} sx={{ width: props.width, height: props.height }}>
      <UI.FileButton
        onChange={lifecycle.onChange}
        accept={'image/' + props.accept}
        resetRef={lifecycle.resetRef}
      >
        {(p) => (
          <UI.UnstyledButton {...p}>
            <UI.Card
              withBorder
              p={0}
              sx={{
                width: props.width,
                height: props.height,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <UI.Image
                width={props.fallback ? props.width / 2 : props.width}
                height={props.fallback ? props.width / 2 : props.height}
                withPlaceholder
                key={lifecycle.image}
                src={`${process.env.REACT_APP_STATIC_URL as string}${
                  lifecycle.image
                }`}
              />
            </UI.Card>
          </UI.UnstyledButton>
        )}
      </UI.FileButton>
    </UI.Box>
  ))
