import { useOnMount } from '../use-on-mount'
import { graphql } from '../../services/graphql'
import { useStore } from '../state'
import { addMonths, subMonths } from 'date-fns'

export function useDatasourceSpace() {
  const Store = useStore()

  useOnMount(() => {
    return graphql.subscribe(['Space'], () => {
      graphql.queries.getSpaces().then(({ spaceList }) => {
        const inOneMonth = addMonths(new Date(), 1).valueOf()
        const sixMonthsAgo = subMonths(new Date(), 6).valueOf()
        const now = Date.now()

        const sorted = spaceList
          .map((space) => {
            const startDate = space.subscription?.start
              ? new Date(space.subscription.start).valueOf()
              : null
            const endDate = space.subscription?.end
              ? new Date(space.subscription.end).valueOf()
              : null

            const getStatus = () => {
              if (startDate && startDate > now) {
                return 'pending' as const
              }

              if (endDate && endDate < sixMonthsAgo) {
                return 'inactive' as const
              }

              if (endDate && endDate < now) {
                return 'expired' as const
              }

              if (endDate && endDate < inOneMonth) {
                return 'expiring' as const
              }

              return 'active' as const
            }

            return {
              ...space,
              status: getStatus(),
            }
          })
          .sort((a, b) => {
            // Show expiring first, sort by expiry date
            if (a.status === 'expiring' && b.status !== 'expiring') {
              return -1
            }

            if (a.status !== 'expiring' && b.status === 'expiring') {
              return 1
            }

            if (a.status === 'expiring' && b.status === 'expiring') {
              return (
                new Date(a.subscription?.end ?? 0).valueOf() -
                new Date(b.subscription?.end ?? 0).valueOf()
              )
            }

            // Then show expired
            if (a.status === 'expired' && b.status !== 'expired') {
              return -1
            }

            if (a.status !== 'expired' && b.status === 'expired') {
              return 1
            }

            // Finally, sort alphabetically
            return a.name.localeCompare(b.name)
          })

        Store.getActions().space.setList(sorted)
      })
    })
  })
}
