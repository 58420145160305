import React, { useState } from 'react'

import IconLogoWhite from '../../../../resources/icons/new-logo-white.png'
import StatHeader0 from '../../../../resources/illustrations/stat-header-0.png'
import StatHeader1 from '../../../../resources/illustrations/stat-header-1.png'

import { buildComponent } from '../../../../components/factory'

import * as UI from '@mantine/core'

export const Title = buildComponent().withRender(({ children }) => (
  <UI.Text
    mt={16}
    sx={(theme) => ({
      display: 'flex',
      justifyContent: 'center',
      backgroundImage: `linear-gradient(-60deg, ${theme.colors.darkblue[5]} 0%, ${theme.colors.darkblue[7]} 100%)`,
      padding: '1.5rem',
      borderRadius: theme.radius.md,
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '24px',
      color: 'white',
    })}
  >
    {children}
  </UI.Text>
))

export const CategoryTitle = buildComponent().withRender(({ children }) => (
  <UI.Text
    weight="bold"
    align="center"
    size="lg"
    mt={16}
    sx={(theme) => ({
      display: 'flex',
      justifyContent: 'center',
      backgroundImage: `linear-gradient(-60deg, ${theme.colors.blue[7]} 0%, ${theme.colors.blue[7]} 100%)`,
      padding: '1rem',
      margin: '0 3rem',
      borderRadius: theme.radius.md,
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '16px',
      color: 'white',
    })}
  >
    {children}
  </UI.Text>
))

export const StatTitle = buildComponent().withRender(({ children }) => (
  <UI.Text
    weight="bold"
    color="blue"
    mt={16}
    mx={8}
    sx={{
      width: 'fit-content',
      '&:after': {
        display: 'block',
        content: '" "',
        height: '2px',
        backgroundColor: 'currentColor',
        width: '5rem',
        marginTop: '0',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    }}
  >
    {children}
  </UI.Text>
))

export const PrintTitle = buildComponent<{ title: string }>()
  .withLifecycle(() => {
    const [header] = useState(Math.random() > 0.5 ? 1 : 0)
    return { header }
  })
  .withRender(({ props, lifecycle }) => (
    <div className="only-print">
      <img
        alt=""
        src={IconLogoWhite}
        style={{
          position: 'absolute',
          top: '0.75cm',
          left: '0.75cm',
          height: '2cm',
        }}
      />
      {props.title}
      <img
        alt=""
        src={lifecycle.header ? StatHeader0 : StatHeader1}
        style={{
          marginTop: '0.25cm',
          height: '1.25cm',
        }}
      />
    </div>
  ))
