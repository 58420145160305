import React from 'react'

import { buildComponent } from '../../../../components/factory'

import { RichContent } from './rich-content'

export const LegalNotices = buildComponent<{
  language: 'fr' | 'en'
}>().withRender(({ props }) => (
  <RichContent
    language={props.language}
    state="legalNotices"
    draft="legal-notices"
    confirmation={{
      title: 'Publier les Mentions légales',
      content:
        'Voulez-vous vraiment publier cette version des Mentions légales ?',
    }}
    header={
      <>
        Éditez ci-dessous les mentions légales. Les modifications apportées
        seront enregistrées localement sur votre ordinateur. Quand tout est bon,
        un clic sur <b>Publier</b> appliquera les modifications dans
        l'application !
      </>
    }
  />
))
