import React, { useState } from 'react'

import { buildComponent } from '../../../../components/factory'

import * as UI from '@mantine/core'
import * as Icons from '../../../../components/icons'

import { UserSettings } from './user-settings'

const getSpaceRandomIndex = (space: { name: string }) => {
  return space.name.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)
}

const spaceBadgeColors = ['blue', 'yellow', 'darkblue', 'danger']

export const UserRow = buildComponent<{
  _id: string
  email: string
  spaces: { _id: string; name: string }[]
  isSelected: (item: string) => boolean
  toggleRow: (item: string) => void
}>()
  .withLifecycle(() => {
    const [showSettings, setShowSettings] = useState(false)

    return { showSettings, setShowSettings }
  })
  .withRender(({ props, lifecycle }) => {
    const { isSelected, toggleRow, ...item } = props
    const selected = isSelected(item._id)

    return (
      <UI.Group
        sx={(theme) => ({
          height: '6rem',
          background: selected
            ? theme.colors[theme.primaryColor][0]
            : 'transparent',
          ':hover': {
            background: selected
              ? theme.colors[theme.primaryColor][1]
              : 'rgba(0, 0, 0, 0.02)',
          },
        })}
        align="center"
        px={16}
        noWrap
      >
        <UI.Group
          align="center"
          position="center"
          style={{ width: '4rem', flex: 'none' }}
        >
          <UI.Checkbox
            checked={selected}
            onChange={() => toggleRow(item._id)}
            transitionDuration={0}
          />
        </UI.Group>
        <div style={{ width: '32rem', flex: 'none' }}>
          <UI.Group spacing="sm">
            <UI.Text size="sm" weight={500}>
              {item.email}
            </UI.Text>
          </UI.Group>
        </div>
        <div style={{ flex: 1 }}>
          <UI.Group align="center" noWrap>
            {item.spaces
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((space) => (
                <UI.Badge
                  key={space._id}
                  color={
                    spaceBadgeColors[
                      getSpaceRandomIndex(space) % spaceBadgeColors.length
                    ]
                  }
                >
                  {space.name}
                </UI.Badge>
              ))}
          </UI.Group>
        </div>
        <div style={{ width: 64 }}>
          <UI.ActionIcon
            size="lg"
            color="blue"
            sx={{
              marginLeft: 'auto',
              width: 32,
              height: 32,
            }}
            onClick={() => lifecycle.setShowSettings(true)}
          >
            <Icons.FontAwesomeIcon
              color="rgba(0, 0, 0, 0.5)"
              icon={Icons.Solid.faCog}
            />
          </UI.ActionIcon>
        </div>

        <UserSettings
          user={item}
          open={lifecycle.showSettings}
          onClose={() => lifecycle.setShowSettings(false)}
        />
      </UI.Group>
    )
  })
