import React from 'react'

import { buildComponent } from '../../../../components/factory'

import { RichContent } from './rich-content'

export const PrivacyPolicy = buildComponent<{
  language: 'fr' | 'en'
}>().withRender(({ props }) => (
  <RichContent
    language={props.language}
    state="privacyPolicy"
    draft="privacy-policy"
    confirmation={{
      title: 'Publier les Clauses de confidentialité',
      content:
        'Voulez-vous vraiment publier cette version des Clauses de confidentialité ?',
    }}
    header={
      <>
        Éditez ci-dessous les clauses de confidentialité. Les modifications
        apportées seront enregistrées localement sur votre ordinateur. Quand
        tout est bon, un clic sur <b>Publier</b> appliquera les modifications
        dans l'application !
      </>
    }
  />
))
