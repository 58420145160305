import React from 'react'

import { AudioTheme } from '../../../../state/models/audio'

import { buildComponent } from '../../../../components/factory'

import * as UI from '@mantine/core'
import { AudioButton } from '../../../../components/elements'
import { useSynchronizeData } from '../../../../hooks/use-synchronize-data'
import { graphql } from '../../../../services/graphql'

const SelectTracks = buildComponent<
  Partial<AudioTheme> & {
    locale: 'fr' | 'en'
  }
>().withRender(({ props }) => (
  <UI.Group position="center">
    <UI.Text sx={{ width: '10rem' }} weight="bold">
      {props.locale === 'fr' ? 'Français' : 'Anglais'}
    </UI.Text>
    {([10, 15, 20, 25] as const).map((duration) => (
      <AudioButton
        key={duration}
        kind="sophrology"
        duration={duration}
        slug={props.slug}
        entity={props._id}
        language={props.locale}
        track={props.tracks?.[props.locale ?? 'fr']?.[duration]}
      />
    ))}
  </UI.Group>
))

export const SectionTracks = buildComponent<{ theme: string }>()
  .withLifecycle(({ props }) => {
    return useSynchronizeData(
      graphql.queries.getAudioTheme,
      graphql.mutations.updateAudioTheme,
      props.theme,
    )
  })
  .withRender(({ lifecycle }) => (
    <UI.Input.Wrapper
      sx={{ flex: 1 }}
      label={<b style={{ fontSize: '2rem' }}>Fichiers audios</b>}
      description="Un fichier pour chaque durée, avec le gong à la fin. Les fichiers doivent durer 2 minutes de moins que les siestes: 8, 13, 18 et 23 minutes"
    >
      <UI.Stack mt={32} sx={{ flex: 1 }}>
        <SelectTracks locale="fr" {...lifecycle.data} />
        <SelectTracks locale="en" {...lifecycle.data} />
      </UI.Stack>

      {lifecycle.loading && (
        <UI.Loader
          sx={{ position: 'absolute', top: '1rem', right: '1rem' }}
          size="xs"
        />
      )}
    </UI.Input.Wrapper>
  ))
