import { Set } from '../types'
import { set } from '../utils'

interface ModelStatistics {
  // State
  space: string | null

  // Actions
  setSpace: Set<this, 'space'>
}

export const modelStatistics: ModelStatistics = {
  // State
  space: null,

  // Actions
  setSpace: set('space'),
}
