import React, { useEffect, useMemo, useState } from 'react'

import { buildComponent } from '../../../../components/factory'

import * as UI from '@mantine/core'
import { DateSelector } from './date-selector'
import { useStoreState } from '../../../../hooks/state'
import { graphql } from '../../../../services/graphql'
import { endOfDay, format, startOfDay } from 'date-fns'

const Booking = buildComponent<{
  start: string
  end: string
  user?: {
    email: string
  }
}>().withRender(({ props }) => (
  <UI.Group p={8} position="center" sx={{ borderRadius: 8 }}>
    <UI.Text>
      <b>
        {props.start}
        {' - '}
        {props.end}
        {' : '}
      </b>
    </UI.Text>
    <UI.Text>{props.user?.email ?? '<Utilisateur supprimé>'}</UI.Text>
  </UI.Group>
))

export const Planning = buildComponent<{
  date: Date
  setDate: (next: Date) => void
}>()
  .withLifecycle(({ props }) => {
    const [naps, setNaps] = useState<
      {
        _id: string
        userInfo?: { email: string }
        time: { start: string; end: string }
        cocoon: { _id: string; name: string }
      }[]
    >([])
    const space = useStoreState((store) => store.statistics.space)
    const { from, to } = useMemo(
      () => ({ from: startOfDay(props.date), to: endOfDay(props.date) }),
      [props.date],
    )

    useEffect(() => {
      setNaps([])
      if (space) {
        graphql.queries
          .getNaps(space, from, to, true)
          .then((res) => setNaps(res.napList))
      }
    }, [space, from, to])

    const sortedNaps = naps.sort((a, b) => {
      const compareCocoonName = a.cocoon.name.localeCompare(b.cocoon.name)
      const compareStartTime = a.time.start.localeCompare(b.time.start)
      const compareEndTime = a.time.end.localeCompare(b.time.end)

      return compareCocoonName || compareStartTime || compareEndTime
    })

    const byCocoons = sortedNaps.reduce(
      (acc, nap) => {
        acc[nap.cocoon._id] = acc[nap.cocoon._id] ?? []
        acc[nap.cocoon._id].push(nap)

        return acc
      },
      {} as Record<
        string,
        {
          _id: string
          userInfo?: { email: string }
          time: { start: string; end: string }
          cocoon: { _id: string; name: string }
        }[]
      >,
    )

    return {
      space,
      byCocoons,
    }
  })
  .withRender(({ props, lifecycle }) => {
    if (!lifecycle.space) {
      return (
        <UI.Text mt={128} align="center" weight="bold" color="dimmed">
          Sélectionnez un Espace pour accéder au planning
        </UI.Text>
      )
    }

    return (
      <UI.Stack>
        <DateSelector
          date={props.date}
          setDate={props.setDate}
          type="Planning"
          mode="day"
        />

        <UI.Group align="flex-start" px={8} spacing={32}>
          {Object.values(lifecycle.byCocoons).map((naps) => (
            <UI.Stack
              sx={(theme) => ({
                flex: 1,
                minWidth: 640,
                maxWidth: '50%',
                '& > *:nth-child(2n)': {
                  backgroundColor: theme.fn.lighten(
                    theme.colors[theme.primaryColor][0],
                    0.25,
                  ),
                },
              })}
              spacing={0}
            >
              <UI.Text sx={{ textAlign: 'center' }} pb={16}>
                Cocon <b>{naps[0].cocoon.name}</b>
              </UI.Text>

              {naps.map((nap) => (
                <Booking
                  key={nap._id}
                  user={nap.userInfo}
                  start={format(new Date(nap.time.start), 'H:mm')}
                  end={format(new Date(nap.time.end), 'H:mm')}
                />
              ))}
            </UI.Stack>
          ))}
        </UI.Group>
      </UI.Stack>
    )
  })
