import { buildComponent } from '../../../../components/factory'
import * as UI from '@mantine/core'
import { ResponsiveBar } from '@nivo/bar'
import { useTooltip } from '@nivo/tooltip'
import React, { createElement, MouseEvent, useCallback, useMemo } from 'react'
import { ResponsivePie } from '@nivo/pie'

const textColor = {
  '#ffad73': '#ffad73',
  '#073475': '#073475',
  '#bbddf6': '#bbddf6',
}

function rotate(array: string[], value?: string) {
  const index = array.indexOf(value ?? '')

  if (index === -1) {
    return array
  }

  return [...array.slice(index), ...array.slice(0, index)]
}

export function useChartValues<T>(params: {
  events: T[]
  label: (event: T) => string | undefined
  otherField?: keyof T
  sort?: (a: { label: string }, b: { label: string }) => number
}) {
  const count: Record<string, number> = {}

  const events = params.events //.map(params.label)

  events.forEach((event) => {
    const label = params.label(event) as string

    count[label] = count[label] || 0
    // prettier-ignore
    count[label] += params.otherField ? (event[params.otherField] as unknown as number) : 1
  })

  return {
    data: Object.keys(count)
      .map((key) => ({
        value: count[key as keyof typeof count],
        label: key,
      }))
      .sort(params.sort ?? ((a, b) => a.label.localeCompare(b.label))),
  }
}

export const BarGraph = buildComponent<{
  data: { label: string; value: number }[]
  tilted?: boolean
  color?: string
}>()
  .withLifecycle(({ props }) => {
    const rawMaxValue = props.data.reduce(
      (max, v) => Math.ceil(Math.max(max, v.value)),
      0,
    )
    const maxValue =
      rawMaxValue < 4 ? rawMaxValue : Math.ceil(rawMaxValue / 4) * 4

    const nbTicks = Math.min(rawMaxValue, 4)

    const tickValues = [
      0,
      ...new Array(nbTicks)
        .fill(0)
        .map((e, i) => Math.floor(maxValue / nbTicks) * (i + 1)),
    ]
      .sort()
      .filter((e, i, a) => a.indexOf(e) === i)

    return { tickValues }
  })
  .withRender(({ props, lifecycle }) => (
    <UI.Card
      mx="auto"
      p={0}
      sx={() => ({
        height: 420,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',

        '.printmode &': {
          height: `${props?.data?.length > 0 ? '7.75cm' : '6cm'}`,
        },
      })}
    >
      {props.data.length === 0 && (
        <UI.Text color="dimmed" weight="bold" align="center">
          Pas de données disponibles
        </UI.Text>
      )}
      {props.data.length > 0 && (
        <>
          <ResponsiveBar
            data={props.data}
            indexBy="label"
            padding={0.3}
            margin={{
              top: 32,
              left: 48,
              right: 16,
              bottom: 96,
            }}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: props.tilted ? -70 : -45,
              tickValues: 0,
              renderTick(data) {
                const words = data.value?.split(' ') ?? []
                const lines = ['']
                words.forEach((word: string) => {
                  if (lines[lines.length - 1].length + word.length > 16) {
                    lines.push('')
                  }

                  lines[lines.length - 1] += ` ${word}`
                  lines[lines.length - 1] = lines[lines.length - 1].trim()
                })

                return (
                  <g transform={`translate(${data.x}, ${data.y})`}>
                    <line
                      x1="0"
                      x2={data.lineX}
                      y1="0"
                      y2={data.lineY}
                      style={{ stroke: 'rgb(119, 119, 119)', strokeWidth: 1 }}
                    />
                    <text
                      dominantBaseline={data.textBaseline}
                      textAnchor={data.textAnchor}
                      transform={`translate(${data.textX}, ${data.textY}) rotate(${data.rotate})`}
                      style={{
                        opacity: 0.5,
                        fontSize: '12px',
                        fontWeight: 700,
                      }}
                    >
                      {lines.map((l, i) => (
                        <tspan x={`${1 * i}em`} y={`${1.2 * i}em`}>
                          {l}
                        </tspan>
                      ))}
                    </text>
                  </g>
                )
              },
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              tickValues: lifecycle.tickValues,
            }}
            enableGridY={false}
            colors={[props.color ?? '#6299e8']}
            barComponent={(p) => {
              const { tooltip, onMouseEnter, onMouseLeave } = p
              const { x: x, y: y, width: w, height: h, color: c } = p.bar
              const { data, ...bar } = p.bar

              const maxWidth = Math.min(w, 48)

              const { value } = data
              const widthFactor = `${value}`.length - 1
              const top = h < 24 || w < 20 + 10 * widthFactor
              const smallText = w < 16

              const { showTooltipFromEvent, hideTooltip } = useTooltip()

              const renderTooltip = useMemo(
                () => () => createElement(tooltip, { ...bar, ...data }),
                [tooltip, bar, data],
              )

              const handleTooltip = useCallback(
                (event: MouseEvent<SVGRectElement>) =>
                  showTooltipFromEvent(renderTooltip(), event),
                [showTooltipFromEvent, renderTooltip],
              )
              const handleMouseEnter = useCallback(
                (event: MouseEvent<SVGRectElement>) => {
                  onMouseEnter?.(data, event)
                  showTooltipFromEvent(renderTooltip(), event)
                },
                [data, onMouseEnter, showTooltipFromEvent, renderTooltip],
              )
              const handleMouseLeave = useCallback(
                (event: MouseEvent<SVGRectElement>) => {
                  onMouseLeave?.(data, event)
                  hideTooltip()
                },
                [data, hideTooltip, onMouseLeave],
              )

              return (
                <g>
                  <rect
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onMouseMove={handleTooltip}
                    x={x + (w - maxWidth) / 2}
                    y={y}
                    width={maxWidth}
                    height={h}
                    fill={c}
                    rx={7}
                    ry={7}
                    strokeWidth={6}
                    stroke="white"
                  />
                  <text
                    style={{ pointerEvents: 'none' }}
                    transform={
                      smallText && widthFactor > 1
                        ? `rotate(-30, ${
                            x + (w - maxWidth) / 2 + maxWidth / 2
                          }, ${y - 4 + (top ? 0 : h / 2 + 8)})`
                        : ''
                    }
                    fill={
                      top
                        ? textColor[c as keyof typeof textColor] ?? c
                        : 'white'
                    }
                    fontWeight="700"
                    fontSize={smallText ? '9px' : '12px'}
                    className="value-label"
                    textAnchor="middle"
                    x={x + (w - maxWidth) / 2 + maxWidth / 2}
                    y={y - 4 + (top ? 0 : h / 2 + 8)}
                  >
                    {value}
                  </text>
                </g>
              )
            }}
            tooltip={(p) => (
              <UI.Card withBorder shadow="xl" py={2}>
                <UI.Text>
                  {p.data.label} : <b>{p.data.value}</b>
                </UI.Text>
              </UI.Card>
            )}
          />
          <div
            style={{
              position: 'absolute',
              borderLeft: '1px solid #CCCCCC',
              borderBottom: '1px solid #CCCCCC',
              top: 16,
              left: 48,
              right: 24,
              bottom: 95,
            }}
          />
        </>
      )}
    </UI.Card>
  ))

const SingleStat = buildComponent<{
  data: { label: string; value: number }[]
  color?: string
  kind?: string
  label?: string
}>().withRender(({ props }) => (
  <UI.Card
    mx="auto"
    p={0}
    sx={() => ({
      height: 420,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',

      '.printmode &': {
        height: '6cm',
      },

      '& text[text-anchor="middle"]': {
        fontWeight: 700,
        fontSize:
          props.data.length === 1 ? '28px !important' : '14px !important',
      },
      '& text[text-anchor="start"]': {
        fontWeight: 700,
        fontSize: '12px !important',
      },
    })}
  >
    <UI.Text weight="bold" color="dimmed" size="xl" mb="1rem">
      {props.label}
    </UI.Text>
    <UI.Text weight="bold" color={props.color} sx={{ fontSize: '3rem' }}>
      {props.data[0].label}
    </UI.Text>
    <UI.Text
      weight="bold"
      color={textColor[props.color as '#ffad73']}
      size="xl"
      mb="6rem"
    >
      {props.data[0].value}&nbsp;
      {props.kind && props.kind + (props.data[0].value > 1 ? 's' : '')}
    </UI.Text>
  </UI.Card>
))

export const PieChart = buildComponent<{
  data: { label: string; value: number }[]
  shifted?: boolean
  bottom?: boolean
  color?: string
  single?: {
    kind: string
    label: string
  }
}>().withRender(({ props }) => {
  if (props.data.length > 4) {
    return <BarGraph data={props.data} color={props.color} />
  }
  if (props.data.length === 1) {
    return (
      <SingleStat
        data={props.data}
        color={props.color}
        kind={props.single?.kind}
        label={props.single?.label}
      />
    )
  }

  const labelSize =
    (props.data
      .map(({ label }) => {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        const { width } = context?.measureText(label) ?? { width: 80 }
        return width * 1.5
      })
      .sort()
      .pop() ?? 80) + 32

  return (
    <UI.Card
      mx="auto"
      p={0}
      sx={() => ({
        height: 420,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '.printmode &': {
          height: '6.75cm',
        },

        '& text[text-anchor="middle"]': {
          fontWeight: 700,
          fontSize:
            props.data.length === 1 ? '28px !important' : '14px !important',
        },
        '& text[text-anchor="start"]': {
          fontWeight: 700,
          fontSize: '12px !important',
        },
      })}
    >
      {props.data.length === 0 && (
        <UI.Text color="dimmed" weight="bold" align="center">
          Pas de données disponibles
        </UI.Text>
      )}
      {props.data.length > 0 && (
        <UI.Box
          sx={{
            height: '320px',
            width: '100%',
            maxWidth: '640px',
            '.printmode &': {
              height: '240px',
            },
          }}
        >
          <ResponsivePie
            data={props.data.map((data) => ({ ...data, id: data.label }))}
            margin={{
              top: 2,
              left: props.shifted ? -180 : 2,
              right: 2,
              bottom: props.bottom ? 32 : 2,
            }}
            arcLabelsSkipAngle={6}
            arcLabelsTextColor="white"
            arcLabelsRadiusOffset={props.data.length === 1 ? 0 : 0.66}
            enableArcLinkLabels={false}
            innerRadius={props.data.length === 1 ? 0 : 0.04}
            cornerRadius={3}
            padAngle={3}
            colors={rotate(
              ['#6299e8', '#9ecef2', '#073475', '#ff8f41'],
              props.color,
            )}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            motionConfig={{
              damping: 15,
              tension: 90,
            }}
            legends={[
              {
                anchor: props.bottom ? 'bottom' : 'right',
                direction: props.bottom ? 'row' : 'column',
                justify: false,
                translateX: props.bottom ? 8 : props.shifted ? -40 : 0,
                translateY: props.bottom ? 32 : 0,
                itemsSpacing: 0,
                itemWidth: props.shifted ? 160 : props.bottom ? labelSize : 120,
                itemHeight: 24,
                itemTextColor: '#999',
                symbolSize: 18,
                symbolShape: 'circle',
              },
            ]}
            tooltip={(p) => (
              <UI.Card withBorder py={2}>
                <UI.Text>
                  {p.datum.label} : <b>{p.datum.value}</b>
                </UI.Text>
              </UI.Card>
            )}
          />
        </UI.Box>
      )}
    </UI.Card>
  )
})
