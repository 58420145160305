import React from 'react'

import { buildComponent } from '../../../../components/factory'

import { EventAudioPlay, EventNap, EventRating } from './data'
import { StarMask } from './star-mask'

import * as UI from '@mantine/core'
import * as Icons from '../../../../components/icons'

const useStyles = UI.createStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundImage: `linear-gradient(-60deg, ${
      theme.colors[theme.primaryColor][5]
    } 0%, ${theme.colors[theme.primaryColor][5]} 100%)`,
    padding: theme.spacing.xl * 1.5,
    borderRadius: theme.radius.md,

    '.printmode &': {
      padding: theme.spacing.xl,
    },

    '@media(max-width: 1350px)': {
      padding: theme.spacing.xl * 1.25,
    },
  },

  title: {
    color: theme.white,
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: theme.fontSizes.sm,

    '.printmode &': {
      fontSize: '0.33cm',
    },

    '@media(max-width: 1350px)': {
      fontSize: theme.fontSizes.xs,
    },
  },

  count: {
    color: theme.white,
    fontSize: 32,
    lineHeight: 1,
    fontWeight: 700,
    marginBottom: theme.spacing.md,
    display: 'flex',
    alignItems: 'center',
    gap: '1.5rem',

    '.printmode &': {
      fontSize: '0.6cm',
    },

    '@media(max-width: 1350px)': {
      fontSize: 24,
      marginBottom: theme.spacing.xs,
    },
  },

  icon: {
    fontSize: 24,
    marginTop: 2,
    marginBottom: -2,

    '.printmode &': {
      fontSize: 18,
    },

    '@media(max-width: 1350px)': {
      fontSize: 18,
    },
  },

  stat: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& + &': {
      paddingLeft: theme.spacing.xl,
      marginLeft: theme.spacing.xl,
      borderLeft: `1px solid ${theme.colors[theme.primaryColor][3]}`,
    },
  },
}))

export const UsageStats = buildComponent<{
  annual: boolean
  eventsAudioPlay: EventAudioPlay[]
  naps: EventNap[]
  ratings: EventRating[]
  legacyRatings: number[]
}>()
  .withLifecycle(({ props }) => {
    const meanRating = props.ratings.length
      ? (props.ratings.reduce((acc, rating) => acc + rating.rating, 0) /
          props.ratings.length) *
        20
      : 0
    const meanLegacyRating = props.legacyRatings.length
      ? (props.legacyRatings.reduce((acc, rating) => acc + rating, 0) /
          props.legacyRatings.length) *
        20
      : 0
    const ratingWeight = props.ratings.length + props.legacyRatings.length
    const weightedMeanRating = ratingWeight
      ? (meanRating * props.ratings.length +
          meanLegacyRating * props.legacyRatings.length) /
        ratingWeight
      : 0
    const meanNaps = Math.round(props.naps.length / (props.annual ? 365 : 31))

    const nbSophrology = props.eventsAudioPlay.filter(
      (event) => event.theme,
    ).length
    const nbAudiobooks = props.eventsAudioPlay.filter(
      (event) => event.book,
    ).length
    const nbOther = props.eventsAudioPlay.length - nbSophrology - nbAudiobooks

    return {
      meanRating: weightedMeanRating,
      meanNaps,
      nbSophrology,
      nbAudiobooks,
      nbOther,
    }
  })
  .withRender(({ props, lifecycle }) => {
    const { classes } = useStyles()

    return (
      <div className={classes.root}>
        <div className={classes.stat}>
          <UI.Text className={classes.count}>
            <UI.Text component="span" className={classes.icon} ml={-16}>
              <Icons.FontAwesomeIcon icon={Icons.Solid.faMusic} />
            </UI.Text>
            {props.eventsAudioPlay.length}
          </UI.Text>
          <UI.Text className={classes.title}>Audios écoutés</UI.Text>
        </div>
        <div className={classes.stat}>
          <UI.Text className={classes.count}>
            <UI.Text component="span" className={classes.icon} ml={-16}>
              <Icons.FontAwesomeIcon icon={Icons.Solid.faMoon} />
            </UI.Text>
            {props.naps.length}
          </UI.Text>
          <UI.Text className={classes.title}>Siestes réservées</UI.Text>
        </div>
        <div className={classes.stat}>
          <UI.Text className={classes.count}>
            <StarMask value={lifecycle.meanRating} />
          </UI.Text>
          <UI.Text className={classes.title}>
            Évaluation moyenne
            <small
              style={{
                display: 'block',
                marginBottom: '-16px',
                textAlign: 'center',
              }}
            >
              Sur {props.ratings.length + props.legacyRatings.length}{' '}
              évaluations
            </small>
          </UI.Text>
        </div>
      </div>
    )
  })
