import React from 'react'

import { buildComponent } from '../../../../components/factory'

import * as UI from '@mantine/core'
import * as Routing from 'wouter'

const useStyles = UI.createStyles((theme, _params) => {
  return {
    card: {
      position: 'relative',
      height: 160,
      width: 200,
      backgroundColor: theme.white,

      '&:hover': {
        boxShadow: theme.shadows.xs,
      },

      '& img': {
        transition: 'transform .2s',
      },
      '&:hover img': {
        transform: 'scale(1.2)',
      },
    },

    content: {
      height: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      zIndex: 1,
    },

    title: {
      marginBottom: 5,
      textAlign: 'center',
    },
  }
})

interface ImageCardProps {
  link: string
  image: string
  title: string
}

export const Background = buildComponent<ImageCardProps>().withRender(
  ({ props: { image, title, link } }) => {
    const { classes } = useStyles()

    return (
      <UI.Card
        p="lg"
        shadow="sm"
        className={classes.card}
        radius="md"
        component={Routing.Link}
        to={link}
      >
        <div className={classes.content}>
          <UI.Image
            src={image}
            width={48}
            height={48}
            mx="auto"
            my="auto"
            withPlaceholder
          />

          <div>
            <UI.Text size="md" className={classes.title} weight={500}>
              {title}
            </UI.Text>
          </div>
        </div>
      </UI.Card>
    )
  },
)
