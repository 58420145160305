import React from 'react'
import { useLocation } from 'wouter'

import { buildComponent } from '../factory'
import { graphql } from '../../services/graphql'
import { serviceModals } from '../../services/modals'

import { useSynchronizeData } from '../../hooks/use-synchronize-data'

import * as UI from '@mantine/core'
import * as Icons from '../icons'

const getter = {
  theme: graphql.queries.getAudioTheme,
  book: graphql.queries.getAudioBook,
  background: graphql.queries.getAudioBackground,
}

const remove = {
  theme: graphql.mutations.deleteAudioTheme,
  book: graphql.mutations.deleteAudioBook,
  background: graphql.mutations.deleteAudioBackground,
}

export const AudioDelete = buildComponent<{
  kind: 'theme' | 'book' | 'background'
  slug: string
}>()
  .withLifecycle(({ props }) => {
    const [, setLocation] = useLocation()
    const resource = useSynchronizeData(
      getter[props.kind],
      () => getter[props.kind](props.slug),
      props.slug,
    )
    const handleDelete = async () => {
      if (resource.data) {
        await serviceModals.confirmDeletion(
          'Supprimer un audio',
          'Voulez-vous vraiment supprimer cet audio ?',
          async () => {
            await remove[props.kind](resource.data?._id as string)
            setLocation('/audios')
          },
        )
      }
    }

    return { handleDelete }
  })
  .withRender(({ lifecycle }) => (
    <UI.Button
      mb={128}
      px={32}
      color="red"
      leftIcon={<Icons.FontAwesomeIcon icon={Icons.Solid.faTrash} />}
      onClick={lifecycle.handleDelete}
    >
      Supprimer
    </UI.Button>
  ))
