import React from 'react'
import { RouteComponentProps } from 'wouter'

import { buildComponent } from '../../../components/factory'

import * as UI from '@mantine/core'
import * as Elements from '../../../components/elements'
import * as Components from './components'

export const ScreenConnectedAudiosBook = buildComponent<
  RouteComponentProps<{ book: string }>
>().withRender(({ props }) => (
  <React.Fragment>
    <Components.Title book={props.params.book} />

    <UI.Stack sx={{ maxWidth: 1280, margin: '2rem auto 16rem' }} spacing={32}>
      <UI.Card shadow="sm" pb={32} px={24}>
        <Components.SectionMain book={props.params.book} />
      </UI.Card>

      <UI.Card shadow="sm" pb={32} px={24}>
        <Components.SectionImages book={props.params.book} />
      </UI.Card>

      <UI.Card shadow="sm" pb={32} px={24}>
        <Components.SectionBackgrounds book={props.params.book} />
      </UI.Card>

      <UI.Card shadow="sm" pb={32} px={24}>
        <Components.SectionChapters book={props.params.book} />
      </UI.Card>

      <UI.Card shadow="sm" pb={32} px={24}>
        <Components.SectionLegacyImages book={props.params.book} />
      </UI.Card>

      <UI.Group position="center">
        <Elements.AudioDelete kind="book" slug={props.params.book} />
      </UI.Group>
    </UI.Stack>
  </React.Fragment>
))
