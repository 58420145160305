import React from 'react'

import { graphql } from '../../../../services/graphql'
import { AudioBook } from '../../../../state/models/audio'

import { useSynchronizeData } from '../../../../hooks/use-synchronize-data'

import { buildComponent } from '../../../../components/factory'

import * as UI from '@mantine/core'
import * as Elements from '../../../../components/elements'

const EditImage = buildComponent<{
  id?: string
  slug?: string
  image?: string
  update: (next: Partial<AudioBook>) => void
}>().withRender(({ props }) => (
  <UI.Input.Wrapper
    style={{ flex: 1 }}
    label={<b style={{ fontSize: '2rem' }}>Image de fond</b>}
    description="Image rectangulaire utilisée dans la configuration de l'audio. Format WEBP."
  >
    <Elements.ImageButton
      kind="books"
      variant="image"
      slug={props.slug}
      entity={props.id}
      image={props.image}
      accept="webp"
      update={props.update}
      width={255}
      height={180}
    />
  </UI.Input.Wrapper>
))

const EditCover = buildComponent<{
  id?: string
  slug?: string
  image?: string
  update: (next: Partial<AudioBook>) => void
}>().withRender(({ props }) => (
  <UI.Input.Wrapper
    style={{ flex: 1 }}
    label={<b style={{ fontSize: '2rem' }}>Illustration d'album</b>}
    description="Version carrée de l'image, utilisée dans le lecteur audio et sur le centre de contrôle mobile. Format PNG."
  >
    <Elements.ImageButton
      kind="books"
      variant="cover"
      slug={props.slug}
      entity={props.id}
      image={props.image}
      accept="png"
      update={props.update}
      width={180}
      height={180}
    />
  </UI.Input.Wrapper>
))

export const SectionImages = buildComponent<{ book: string }>()
  .withLifecycle(({ props }) => {
    return useSynchronizeData(
      graphql.queries.getAudioBook,
      graphql.mutations.updateAudioBook,
      props.book,
    )
  })
  .withRender(({ lifecycle }) => (
    <UI.Group mt={16}>
      <EditImage
        id={lifecycle.data?._id}
        slug={lifecycle.data?.slug}
        image={lifecycle.data?.image}
        update={lifecycle.update}
      />
      <EditCover
        id={lifecycle.data?._id}
        slug={lifecycle.data?.slug}
        image={lifecycle.data?.cover}
        update={lifecycle.update}
      />

      {lifecycle.loading && (
        <UI.Loader
          sx={{ position: 'absolute', top: '1rem', right: '1rem' }}
          size="xs"
        />
      )}
    </UI.Group>
  ))
