import React from 'react'

import { NapCocoonV1, NapCocoonV2, NapCocoonV3, NapCocoonV4 } from './index'

export function NapCocoon({
  version,
  ...props
}: React.ImgHTMLAttributes<HTMLImageElement> & { version?: number }) {
  const CocoonIcon =
    [NapCocoonV2, NapCocoonV1, NapCocoonV2, NapCocoonV3, NapCocoonV4][
      version ?? 0
    ] ?? NapCocoonV2

  return <CocoonIcon {...props} />
}
