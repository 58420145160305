import React from 'react'
import { RouteComponentProps } from 'wouter'

import { useSessionValue } from '../../../hooks/use-session-value'

import { buildComponent } from '../../../components/factory'

import * as UI from '@mantine/core'

import { Faq } from './components/faq'
import { LegalNotices } from './components/legal-notices'
import { PrivacyPolicy } from './components/privacy-policy'

export const ScreenConnectedContent = buildComponent<RouteComponentProps>()
  .withLifecycle(() => {
    const [language, setLanguage] = useSessionValue<'fr' | 'en'>(
      'ContentLanguage',
      'fr',
    )
    const [tab, setTab] = useSessionValue<
      'faq' | 'legalNotices' | 'privacyPolicy'
    >('ContentTab', 'faq')

    return { tab, setTab, language, setLanguage }
  })
  .withRender(({ lifecycle }) => (
    <React.Fragment>
      <UI.Stack
        mx={-16}
        px={16}
        mt={-16}
        py={16}
        sx={{
          position: 'sticky',
          top: 72,
          zIndex: 10,
          backgroundColor: '#f8f9fc',
        }}
        spacing={0}
      >
        <UI.Group position="apart">
          <UI.Stack spacing={0}>
            <UI.Title>
              <UI.Text size={22}>Contenu dynamique</UI.Text>
            </UI.Title>
            <UI.Text color="dimmed">
              Édition de certaines parties dynamiques de l'application.
            </UI.Text>
            <UI.Text color="dimmed">
              Modifiez les Mentions légales, Clauses de confidentialité, et
              Questions fréquentes sans avoir à republier l'application !
            </UI.Text>
          </UI.Stack>

          <UI.Group>
            <UI.Text>Éditer les documents en</UI.Text>
            <UI.Select
              variant="default"
              size="sm"
              value={lifecycle.language}
              onChange={lifecycle.setLanguage}
              data={[
                { value: 'fr', label: 'Français' },
                { value: 'en', label: 'Anglais' },
              ]}
              styles={{
                wrapper: {
                  width: 120,
                },
              }}
            />
          </UI.Group>
        </UI.Group>

        <UI.Group mx="auto" mt="lg" position="center" spacing={0}>
          <UI.SegmentedControl
            radius="xl"
            data={[
              { label: 'Questions fréquentes', value: 'faq' },
              { label: 'Mentions légales', value: 'legalNotices' },
              { label: 'Clauses de confidentialité', value: 'privacyPolicy' },
            ]}
            value={lifecycle.tab ?? undefined}
            onChange={lifecycle.setTab}
            styles={(theme) => ({
              root: {
                backgroundColor: 'transparent',
              },
              active: {
                backgroundColor: theme.colors.blue[7],
              },
              label: {
                padding: '1rem 4rem',
              },
              labelActive: {
                color: 'white !important',
              },
            })}
          />
        </UI.Group>

        {lifecycle.tab === 'faq' && <Faq language={lifecycle.language} />}
        {lifecycle.tab === 'legalNotices' && (
          <LegalNotices language={lifecycle.language} />
        )}
        {lifecycle.tab === 'privacyPolicy' && (
          <PrivacyPolicy language={lifecycle.language} />
        )}
      </UI.Stack>
    </React.Fragment>
  ))
