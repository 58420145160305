import React, { useEffect, useMemo, useState } from 'react'
import {
  endOfMonth,
  endOfYear,
  format,
  startOfMonth,
  startOfYear,
} from 'date-fns'
import locale from 'date-fns/locale/fr'

import { buildComponent } from '../../../../components/factory'

import { useStoreState } from '../../../../hooks/state'
import { useSessionBoolean } from '../../../../hooks/use-session-value'

import {
  EventAudioPlay,
  EventNap,
  EventRating,
  eventsCache,
  loadNaps,
  ratingsCache,
} from './data'
import { DateSelector } from './date-selector'

import * as UI from '@mantine/core'
import * as Icons from '../../../../components/icons'

const Rating = buildComponent<{
  user: { email: string; canBeContacted: boolean }
  space?: { name: string }
  date: string
  rating: number
  comment: string
  audio: string
}>().withRender(({ props }) => (
  <UI.Group p={8}>
    <UI.Stack
      sx={{ width: '24rem' }}
      align="center"
      justify="center"
      spacing={4}
    >
      <UI.Group spacing={4}>
        {[...new Array(5)].map((_, i) => (
          <UI.Text key={i} color={i < props.rating ? 'yellow' : 'gray.3'}>
            <Icons.FontAwesomeIcon icon={Icons.Solid.faStar} />
          </UI.Text>
        ))}
      </UI.Group>
      {props.user.canBeContacted && (
        <UI.Badge color="yellow" size="xs">
          Contact autorisé
        </UI.Badge>
      )}
      {props.audio && (
        <UI.Group sx={{ flexWrap: 'nowrap' }}>
          <UI.Text color="blue">
            <Icons.FontAwesomeIcon icon={Icons.Solid.faMusic} />
          </UI.Text>
          <UI.Text align="center" color="blue" weight="bold" size="xs">
            {props.audio}
          </UI.Text>
        </UI.Group>
      )}
    </UI.Stack>
    <UI.Stack sx={{ flex: '1' }} spacing={4}>
      <UI.Text size="sm">
        {props.user.email}, le{' '}
        {format(new Date(props.date), 'dd/MM/yy', { locale })}
        {' - '}
        <UI.Text component="span" weight="bold">
          {props.space?.name}
        </UI.Text>
      </UI.Text>

      <UI.Text
        py={8}
        px={16}
        sx={(theme) => ({
          backgroundColor: theme.fn.lighten(theme.colors.blue[0], 0.25),
          borderRadius: '1rem',
          minHeight: '8rem',
        })}
      >
        {props.comment}
      </UI.Text>
    </UI.Stack>
  </UI.Group>
))

export const Ratings = buildComponent<{
  date: Date
  setDate: (next: Date) => void
}>()
  .withLifecycle(({ props }) => {
    const [_naps, setNaps] = useState<EventNap[]>([])
    const [_ratings, setRatings] = useState<EventRating[]>([])
    const [_eventsAudioPlay, setEventsAudioPlay] = useState<EventAudioPlay[]>(
      [],
    )
    const space = useStoreState((store) => store.statistics.space)
    const [annual] = useSessionBoolean('StatisticsAnnual')
    const year = props.date.getFullYear()
    const month = year + '-' + (props.date.getMonth() + 1)
    const spaces = useStoreState((store) => store.space.list)
    const themes = useStoreState((store) => store.audio.themes)
    const books = useStoreState((store) => store.audio.books)
    const backgrounds = useStoreState((store) => store.audio.backgrounds)

    const filterBySpace = (elem: { space: string }) =>
      !space || elem.space === space
    const naps = _naps.filter(filterBySpace)
    const ratings = _ratings.filter(filterBySpace)
    const eventsAudioPlay = _eventsAudioPlay.filter(filterBySpace)

    const legacyRatings: EventRating[] = useMemo(
      () =>
        naps
          .filter((nap) => nap.evaluation)
          .map((nap) => {
            const configuration = eventsAudioPlay.find(
              (play) => play.napId === nap._id,
            )

            return {
              configuration: configuration ?? ({} as EventAudioPlay),
              space: nap.space,
              rating: nap.evaluation?.rating as number,
              message: nap.evaluation?.comment as string,
              date: nap.time.end,
              user: {
                email: nap.userInfo?.email || '<Utilisateur supprimé>',
                canBeContacted: false,
              },
            }
          }),
      [naps, eventsAudioPlay],
    )

    // Load naps (legacy ratings)
    useEffect(() => {
      const gte = annual ? startOfYear(props.date) : startOfMonth(props.date)
      const lte = annual ? endOfYear(props.date) : endOfMonth(props.date)

      loadNaps(space ?? '', gte, lte, true).then(setNaps)
    }, [props.date, annual, space])

    // Load events (legacy audio for ratings)
    useEffect(() => {
      const promise = annual
        ? eventsCache.loadYear(year)
        : eventsCache.loadMonth(month)
      promise.then((events) => {
        setEventsAudioPlay(events.audioPlay)
      })
    }, [month, year, annual])

    // Load ratings events
    useEffect(() => {
      const promise = annual
        ? ratingsCache.loadYear(year)
        : ratingsCache.loadMonth(month)
      promise.then(setRatings)
    }, [month, year, annual])

    return {
      ratings: [...ratings, ...legacyRatings].sort((a, b) =>
        b.date.localeCompare(a.date),
      ),
      space(spaceId: string) {
        return spaces.find((s) => s._id === spaceId)
      },
      audio(config: { theme?: string; book?: string; background: string }) {
        const theme = themes.find((audio) => audio.slug === config.theme)
        const book = books.find((audio) => audio.slug === config.book)
        const background = backgrounds.find(
          (audio) => audio.slug === config.background,
        )

        return [theme?.short?.fr ?? book?.title?.short, background?.short?.fr]
          .filter(Boolean)
          .join(' - ')
      },
    }
  })
  .withRender(({ props, lifecycle }) => (
    <UI.Stack>
      <DateSelector {...props} type="Évaluations" />

      {lifecycle.ratings.map((rating) => (
        <Rating
          key={rating.date}
          user={rating.user}
          space={lifecycle.space(rating.space)}
          date={rating.date}
          rating={rating.rating}
          comment={rating.message}
          audio={lifecycle.audio(rating.configuration)}
        />
      ))}

      {lifecycle.ratings.length === 0 && (
        <UI.Text mt={128} align="center" weight="bold" color="dimmed">
          Aucune évaluation
        </UI.Text>
      )}
    </UI.Stack>
  ))
