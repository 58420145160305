import React from 'react'

import { buildComponent } from '../../../../components/factory'

import * as UI from '@mantine/core'
import * as Routing from 'wouter'

const useStyles = UI.createStyles((theme, _params, getRef) => {
  const image = getRef('image')

  return {
    card: {
      position: 'relative',
      height: 160,
      width: 280,
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[6]
          : theme.colors.gray[0],

      [`&:hover .${image}`]: {
        transform: 'scale(1.06)',
      },
    },

    image: {
      ref: image,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundSize: 'cover',
      transition: 'transform .2s',
    },

    overlay: {
      position: 'absolute',
      top: '20%',
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage:
        'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .85) 90%)',
    },

    content: {
      height: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      zIndex: 1,
    },

    title: {
      color: theme.white,
      marginBottom: 5,
      textAlign: 'center',
    },
  }
})

interface ImageCardProps {
  link: string
  image: string
  title: string
}

export const Main = buildComponent<ImageCardProps>().withRender(
  ({ props: { image, title, link } }) => {
    const { classes } = useStyles()

    return (
      <UI.Card
        p="lg"
        shadow="lg"
        className={classes.card}
        radius="md"
        component={Routing.Link}
        to={link}
      >
        <div
          className={classes.image}
          style={{ backgroundImage: `url(${image})` }}
        />
        <div className={classes.overlay} />

        <div className={classes.content}>
          <div>
            <UI.Text size="lg" className={classes.title} weight={500}>
              {title}
            </UI.Text>
          </div>
        </div>
      </UI.Card>
    )
  },
)
