import React from 'react'

import { buildComponent } from '../../../../components/factory'

import {
  EventAudioPlay,
  EventNap,
  EventNoSlotWarning,
  EventUserSpace,
} from './data'

import * as UI from '@mantine/core'
import * as Icons from '../../../../components/icons'

const useStyles = UI.createStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundImage: `linear-gradient(-60deg, ${
      theme.colors[theme.primaryColor][5]
    } 0%, ${theme.colors[theme.primaryColor][5]} 100%)`,
    padding: theme.spacing.xl * 1.5,
    borderRadius: theme.radius.md,

    '.printmode & ': {
      padding: theme.spacing.md,
    },

    '@media(max-width: 1350px)': {
      padding: theme.spacing.lg,
    },
  },

  title: {
    color: theme.white,
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: theme.fontSizes.sm,

    '.printmode & ': {
      fontSize: '0.3cm',
    },

    '@media(max-width: 1350px)': {
      fontSize: theme.fontSizes.xs,
    },
  },

  count: {
    color: theme.white,
    fontSize: 32,
    lineHeight: 1,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    gap: '1.5rem',

    '.printmode & ': {
      fontSize: '0.4cm',
    },

    '@media(max-width: 1350px)': {
      fontSize: 24,
    },
  },

  icon: {
    fontSize: 24,

    '.printmode & ': {
      fontSize: 16,
    },

    '@media(max-width: 1350px)': {
      fontSize: 16,
    },
  },

  stat: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    gap: '2rem',

    '& + &': {
      paddingLeft: theme.spacing.md,
      marginLeft: theme.spacing.md,
      borderLeft: `1px solid ${theme.colors.pink[1]}`,
    },

    '.printmode &:last-child': {
      display: 'none',
    },
  },
}))

export const UsersStats = buildComponent<{
  date: Date
  endDate: Date
  annual: boolean
  eventsNoSlotWarnings: EventNoSlotWarning[]
  eventsAudioPlay: EventAudioPlay[]
  naps: EventNap[]
  userSpace: EventUserSpace[]
}>()
  .withLifecycle(({ props }) => {
    const newUsers = props.userSpace.filter(
      (user) =>
        user.join >= props.date.toISOString() &&
        (!user.leave || user.leave > props.endDate.toISOString()),
    ).length
    const activeUsers = [
      ...props.naps.map((nap) => nap.user),
      ...props.eventsAudioPlay.map((event) => event.user),
    ].filter((e, i, a) => a.indexOf(e) === i).length
    const totalUsers = props.userSpace
      .filter((user) => !user.leave || user.leave > props.endDate.toISOString())
      .map((user) => user.user)
      .filter((e, i, a) => a.indexOf(e) === i).length

    return {
      newUsers,
      activeUsers,
      totalUsers,
    }
  })
  .withRender(({ props, lifecycle }) => {
    const { classes } = useStyles()

    return (
      <div className={classes.root}>
        <div className={classes.stat}>
          <UI.Text className={classes.count}>
            <UI.Text className={classes.icon}>
              <Icons.FontAwesomeIcon icon={Icons.Solid.faUser} />
            </UI.Text>
            {lifecycle.totalUsers}
          </UI.Text>
          <UI.Text className={classes.title}>
            Utilisateurs
            <br />
            <small>au total</small>
          </UI.Text>
        </div>
        <div className={classes.stat}>
          <UI.Text className={classes.count}>
            <UI.Text className={classes.icon}>
              <Icons.FontAwesomeIcon icon={Icons.Solid.faUserCheck} />
            </UI.Text>
            {lifecycle.activeUsers}
          </UI.Text>
          <UI.Text className={classes.title}>
            Utilisateurs actifs
            <br />
            <small>{props.annual ? 'cette année' : 'ce mois-ci'}</small>
          </UI.Text>
        </div>
        <div className={classes.stat}>
          <UI.Text className={classes.count}>
            <UI.Text className={classes.icon}>
              <Icons.FontAwesomeIcon icon={Icons.Solid.faUserPlus} />
            </UI.Text>
            {lifecycle.newUsers}
          </UI.Text>
          <UI.Text className={classes.title}>
            Nouveaux inscrits
            <br />
            <small>{props.annual ? 'en un an' : 'en un mois'}</small>
          </UI.Text>
        </div>
        <div className={classes.stat}>
          <UI.Text className={classes.count}>
            <UI.Text className={classes.icon}>
              <Icons.FontAwesomeIcon icon={Icons.Solid.faWarning} />
            </UI.Text>
            {props.eventsNoSlotWarnings.length}
          </UI.Text>
          <UI.Text className={classes.title}>
            Signalements
            <br />
            <small>de créneaux saturés</small>
          </UI.Text>
        </div>
      </div>
    )
  })
