import React from 'react'

import { buildComponent } from '../../../../components/factory'
import { SpaceInfo } from '../../../../state/models/space'

import * as UI from '@mantine/core'
import * as Icons from '../../../../components/icons'
import { graphql } from '../../../../services/graphql'

export const SendRecapButton = buildComponent<{
  spaceInfo: SpaceInfo | null
  date: Date
  annual: boolean
}>()
  .withLifecycle(({ props }) => {
    const emails =
      props.spaceInfo?.emails.filter((email) => email.email !== null) || []
    const sendRecap = () => {
      if (
        // eslint-disable-next-line no-alert
        window.confirm(
          'Etês-vous sûr de vouloir envoyer le récapitulatif mensuel ?',
        )
      ) {
        if (!props.spaceInfo) {
          return
        }
        // If annual recap is enabled, send the annual recap
        graphql.queries
          .sendRecap(
            props.spaceInfo._id,
            props.date.getMonth(),
            props.date.getFullYear(),
            props.annual,
          )
          .then(() => {
            if (props.annual) {
              console.log(`Recap annual for ${props.date.getFullYear()} sent`)
            } else {
              console.log(`Recap monthly for ${props.date.getMonth()} sent`)
            }
          })
      }
    }

    return { sendRecap, emails, annual: props.annual }
  })
  .withRender(({ lifecycle }) => (
    <UI.Button
      color="gray"
      pr={32}
      pl={24}
      py={2}
      ml="auto"
      disabled={!lifecycle.emails || lifecycle.emails.length === 0}
      sx={{ minHeight: 0 }}
      leftIcon={<Icons.FontAwesomeIcon icon={Icons.Solid.faEnvelope} />}
      onClick={lifecycle.sendRecap}
    >
      Envoyer le récapitulatif {lifecycle.annual ? 'annuel' : 'mensuel'}
    </UI.Button>
  ))

export const SendRecapPage = buildComponent().withRender(({ children }) => (
  <UI.Stack className="page">{children}</UI.Stack>
))
