import React, { useState } from 'react'
import {
  format,
  startOfDay,
  startOfMonth,
  subDays,
  subMonths,
  addDays,
  addMonths,
  subYears,
  startOfYear,
  addYears,
} from 'date-fns'
import locale from 'date-fns/locale/fr'

import { useSessionBoolean } from '../../../../hooks/use-session-value'

import { buildComponent } from '../../../../components/factory'

import * as UI from '@mantine/core'
import * as Dates from '@mantine/dates'
import * as Icons from '../../../../components/icons'

export const DateSelector = buildComponent<{
  date: Date
  setDate: (next: Date) => void
  mode?: 'month' | 'day'
  type: string
}>()
  .withDefaultProps({ mode: 'month' })
  .withLifecycle(({ props }) => {
    const [calendar, setCalendar] = useState(false)
    const [annual, setAnnual] = useSessionBoolean('StatisticsAnnual')

    return {
      calendar,
      setCalendar,
      annual,
      setAnnual,
      subtract() {
        props.setDate(
          props.mode === 'month'
            ? annual
              ? subYears(startOfYear(props.date), 1)
              : subMonths(startOfMonth(props.date), 1)
            : subDays(startOfDay(props.date), 1),
        )
      },
      add() {
        props.setDate(
          props.mode === 'month'
            ? annual
              ? addYears(startOfYear(props.date), 1)
              : addMonths(startOfMonth(props.date), 1)
            : addDays(startOfDay(props.date), 1),
        )
      },
    }
  })
  .withRender(({ props, lifecycle }) => (
    <UI.Group sx={{ position: 'relative' }}>
      <UI.Text>
        {props.type}{' '}
        {props.mode === 'month'
          ? lifecycle.annual
            ? "pour l'année"
            : 'pour le mois de'
          : 'pour le'}
      </UI.Text>

      <UI.UnstyledButton onClick={lifecycle.subtract}>
        <Icons.FontAwesomeIcon icon={Icons.Solid.faChevronLeft} />
      </UI.UnstyledButton>
      <UI.Popover
        opened={lifecycle.calendar}
        onClose={() => lifecycle.setCalendar(false)}
      >
        <UI.Popover.Target>
          <UI.UnstyledButton onClick={() => lifecycle.setCalendar(true)}>
            <UI.Text weight="bold">
              {format(
                props.date,
                props.mode === 'month'
                  ? lifecycle.annual
                    ? 'yyyy'
                    : 'MMMM yyyy'
                  : 'd MMMM yyyy',
                { locale },
              )}
            </UI.Text>
          </UI.UnstyledButton>
        </UI.Popover.Target>
        <UI.Popover.Dropdown>
          <Dates.Calendar
            hideWeekdays
            dayStyle={
              props.mode === 'month' ? () => ({ display: 'none' }) : undefined
            }
            initialLevel={
              props.mode === 'month'
                ? lifecycle.annual
                  ? 'year'
                  : 'month'
                : 'date'
            }
            initialMonth={props.date}
            onChange={(date) => {
              if (date) {
                props.setDate(date)
                lifecycle.setCalendar(false)
              }
            }}
            onMonthChange={(date) => {
              if (props.mode === 'month') {
                props.setDate(date)
                lifecycle.setCalendar(false)
              }
            }}
          />
        </UI.Popover.Dropdown>
      </UI.Popover>
      <UI.UnstyledButton onClick={lifecycle.add}>
        <Icons.FontAwesomeIcon icon={Icons.Solid.faChevronRight} />
      </UI.UnstyledButton>

      {props.mode === 'month' && (
        <UI.Checkbox
          label="Mode annuel"
          checked={lifecycle.annual}
          onChange={(e) => lifecycle.setAnnual(e.currentTarget.checked)}
          sx={{
            position: 'fixed',
            top: '24rem',
            zIndex: 100,
          }}
        />
      )}
    </UI.Group>
  ))
