import React, { useState } from 'react'
import * as UI from '@mantine/core'
import { useForm } from '@mantine/form'

import Logo from '../../resources/icons/logo.svg'

import { graphql } from '../../services/graphql'

import { buildComponent } from '../../components/factory'
import { useStore } from '../../hooks/state'

export const ScreenDisconnectedLogin = buildComponent()
  .withLocale('screens.disconnected.login.screen')
  .withLifecycle(() => {
    const Store = useStore()

    const [error, setError] = useState<
      '' | 'user_not_found' | 'wrong_credentials' | 'internal_server_error'
    >('')
    const [submitting, setSubmitting] = useState(false)
    const form = useForm({
      initialValues: {
        email: '',
        password: '',
      },
    })

    const handleSubmit = form.onSubmit((values) => {
      setError('')
      setSubmitting(true)

      graphql.queries
        .login(values.email, values.password)
        .then(({ administratorLogin }) => {
          if (administratorLogin.token) {
            Store.getActions().utils.session.connectWithToken(
              administratorLogin.token,
            )
          }

          if (administratorLogin.error) {
            setError(administratorLogin.error as 'user_not_found')
            setSubmitting(false)
          }
        })
        .catch((err) => {
          console.error(err)
          setError('internal_server_error')
          setSubmitting(false)
        })
    })

    return { form, handleSubmit, error, submitting }
  })
  .withRender(({ lifecycle, locale }) => (
    <UI.Box
      sx={(theme) => ({
        width: '100%',
        height: '100%',
        borderTop: '4rem solid ' + theme.colors.blue[7],
        borderBottom: '8rem solid ' + theme.colors.blue[7],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      })}
    >
      <UI.Container
        size={420}
        my={40}
        sx={{
          '@media(max-height: 800px)': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            maxWidth: '920px',
            gap: '8rem',
            '& > *': {
              flex: 1,
            },
          },
        }}
      >
        <UI.Stack spacing={0} justify="center">
          <UI.Title align="center" title="Nap&Up">
            <UI.Image mx="auto" mb={32} width={128} src={Logo} alt="Nap&Up" />
          </UI.Title>
          <UI.Title
            order={2}
            align="center"
            sx={() => ({
              fontWeight: 900,
            })}
          >
            {locale('subtitle')}
          </UI.Title>

          <UI.Text mt={16} align="center" color="dimmed">
            {locale('description')}
          </UI.Text>
        </UI.Stack>

        <UI.Stack spacing={0} justify="center">
          <form onSubmit={lifecycle.handleSubmit}>
            <UI.Paper
              withBorder
              px={32}
              py={48}
              mt={32}
              mb="5vh"
              radius="md"
              sx={{
                '@media(max-height: 800px)': {
                  marginBottom: '32px',
                },
              }}
            >
              <UI.TextInput
                disabled={lifecycle.submitting}
                {...lifecycle.form.getInputProps('email')}
                label={locale('email')}
              />
              <UI.PasswordInput
                disabled={lifecycle.submitting}
                {...lifecycle.form.getInputProps('password')}
                label={locale('password')}
                mt="md"
              />

              {lifecycle.error && (
                <UI.Alert mt="md" color="red">
                  <UI.Text color="red">
                    {locale(`error.${lifecycle.error}`)}
                  </UI.Text>
                </UI.Alert>
              )}

              <UI.Button
                disabled={lifecycle.submitting}
                type="submit"
                fullWidth
                mt="xl"
              >
                {locale('login')}
              </UI.Button>
            </UI.Paper>
          </form>
        </UI.Stack>
      </UI.Container>
    </UI.Box>
  ))
