import { useMemo } from 'react'

import { firestore, firebase } from '../services/firebase'

const pathPrefix = 'offline-first/offline-first'

export function getDocumentReference(collection: string, document: string) {
  const parts = [pathPrefix, collection, document]

  return firestore().doc(parts.join('/'))
}

export function getCollectionReference(collection: string) {
  return firestore().collection([pathPrefix, collection].join('/'))
}

export function useDocumentReference(collection: string, document: string) {
  return useMemo(
    () => getDocumentReference(collection, document),
    [collection, document],
  )
}

export function useCollectionReference(collection: string) {
  return useMemo(() => getCollectionReference(collection), [collection])
}

export function upsertDocument(
  doc: firebase.firestore.DocumentReference,
  update: firebase.firestore.UpdateData,
  upsert = update,
) {
  return doc.update(update).catch((err) => {
    if (err.code.includes('not-found')) {
      return doc.set(upsert)
    }
    throw err
  })
}
