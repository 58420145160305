import { Set } from '../types'
import { set } from '../utils'

export interface User {
  _id: string
  email: string
  spaces: { _id: string; name: string }[]
}

interface ModelUser {
  // State
  space: string | null
  list: User[]

  // Actions
  setSpace: Set<this, 'space'>
  setList: Set<this, 'list'>
}

export const modelUser: ModelUser = {
  // State
  space: null,
  list: [],

  // Actions
  setSpace: set('space'),
  setList: set('list'),
}
