import React from 'react'
import { useLocation } from 'wouter'

import { SpaceCocoons } from '../../../../state/models/space'

import { buildComponent } from '../../../../components/factory'

import * as UI from '@mantine/core'
import * as Icons from '../../../../components/icons'

export const PrintButton = buildComponent<{
  title: string
  spaceDetails: SpaceCocoons | null
}>()
  .withLifecycle(({ props }) => {
    const [location, setLocation] = useLocation()

    const printMode = () => {
      document.title =
        'Nap&Up - ' +
        props.title +
        (props.spaceDetails ? ' - ' + props.spaceDetails.localizedName?.fr : '')
      document.body.classList.toggle('printmode', true)
      setLocation(location + '#print')
    }

    return { printMode }
  })
  .withRender(({ lifecycle }) => (
    <UI.Button
      color="gray"
      pr={32}
      pl={24}
      py={2}
      ml="auto"
      sx={{ minHeight: 0 }}
      leftIcon={<Icons.FontAwesomeIcon icon={Icons.Solid.faPrint} />}
      onClick={lifecycle.printMode}
    >
      Imprimer
    </UI.Button>
  ))

export const PrintPage = buildComponent().withRender(({ children }) => (
  <UI.Stack className="page">{children}</UI.Stack>
))
