import { useEffect, useRef, useState } from 'react'
import deepmerge from 'deepmerge'

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>
    }
  : T

type UpdateData<Fn extends (id: string) => Promise<any>> = {
  (info: DeepPartial<Awaited<ReturnType<Fn>>>, timeout?: number): {
    onError: (err: any) => void
    onSuccess: () => void
  }
  (
    info: DeepPartial<Awaited<ReturnType<Fn>>>,
    custom: () => Promise<void>,
    timeout?: number,
  ): { onError: (err: any) => void; onSuccess: () => void }
}

export function useSynchronizeData<Fn extends (id: string) => Promise<any>>(
  get: Fn,
  update: (
    next: Partial<Awaited<ReturnType<Fn>>> & { _id: string },
  ) => ReturnType<Fn>,
  id: string,
) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<null | Awaited<ReturnType<Fn>>>(null)

  useEffect(() => {
    get(id).then((result) => {
      setData(result)
      setLoading(false)
    })
  }, [get, id])

  const seed = useRef(0)
  const debounce = useRef(0)
  const updateData: UpdateData<Fn> = (
    info: DeepPartial<Awaited<ReturnType<Fn>>>,
    timeoutOrCustom = 500,
    timeout = 500,
  ) => {
    const actualTimeout =
      typeof timeoutOrCustom !== 'function'
        ? timeoutOrCustom
        : (timeout as number)
    const actualCustom =
      typeof timeoutOrCustom === 'function' ? timeoutOrCustom : async () => {}

    setData(
      (s) =>
        deepmerge(s ?? {}, info, {
          arrayMerge: (_, override) => override,
        }) as Awaited<ReturnType<Fn>>,
    )
    setLoading(true)
    const current = Math.random()
    seed.current = current
    window.clearTimeout(debounce.current)

    const handlers = {
      onSuccess: () => {},
      onError: (_: any) => {},
    }

    const run = async () => {
      await actualCustom().catch(() => {})

      try {
        let errored = false
        const handleError = (err: any) => {
          handlers.onError(err)
          errored = true
        }

        const result =
          (await update({
            ...info,
            _id: data?._id,
          }).catch(handleError)) ?? (await get(id))

        if (seed.current === current) {
          result && setData(result)
        }

        if (!errored) {
          handlers.onSuccess()
        }
      } finally {
        if (seed.current === current) {
          setLoading(false)
        }
      }
    }

    debounce.current = window.setTimeout(run, actualTimeout)

    return handlers
  }

  return {
    data,
    loading,
    update: updateData,
  }
}
