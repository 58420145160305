import { endOfMonth, startOfMonth } from 'date-fns'
import { createMonthlyCache } from '../../../../helpers/cache-statistics'
import { firestore } from '../../../../services/firebase'
import { graphql } from '../../../../services/graphql'

export type EventNap = {
  _id: string
  time: { start: string; end: string }
  space: string
  cocoon?: { _id: string; room?: { _id: string } }
  user: string
  userInfo?: { email: string; _id: string }
  evaluation?: {
    rating: number
    comment: string
  }
}
export type EventUserSpace = {
  space: string
  join: string
  leave?: string
  user: string
}
export type EventAudioPlay = {
  space: string
  user: string
  napId?: string
  theme?: string
  book?: string
  bookChapter?: number
  background: string
  duration: number
  date: string
}
export type EventNoSlotWarning = { space: string; date: string }

export type EventRating = {
  configuration: {
    background: string
    theme?: string
    book?: string
  }
  space: string
  date: string
  message: string
  rating: number
  user: {
    email: string
    canBeContacted: boolean
  }
}

const aggregateMonthParts = async (type: string, month: string) => {
  const parts = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

  const mainDocument = firestore()
    .doc(`/offline-first/offline-first/${type}/${month}`)
    .get()
  const partDocuments = parts.map((part) =>
    firestore()
      .doc(`/offline-first/offline-first/${type}/${month}/parts/part${part}`)
      .get(),
  )

  return Promise.all([mainDocument, ...partDocuments])
}

export const feedbackCache = createMonthlyCache(
  (month: string) =>
    firestore()
      .collection('/offline-first/offline-first/feedback')
      .where('date', '>=', startOfMonth(new Date(month + '-01')).toISOString())
      .where('date', '<=', endOfMonth(new Date(month + '-01')).toISOString())
      .get()
      .then((res) =>
        res.docs.map(
          (doc) =>
            doc.data() as {
              category: 'audio' | 'application'
              date: string
              message: string
              user: { email: string }
            },
        ),
      ),
  (months) => months.flat(),
)

export const ratingsCache = createMonthlyCache(
  (month: string) =>
    aggregateMonthParts('ratings', month).then((parts) =>
      parts.flatMap((doc) => Object.values(doc.data() ?? {})),
    ),
  (months) => months.flat(),
)

export const eventsCache = createMonthlyCache(
  (month: string) => {
    return aggregateMonthParts('events', month).then((parts) => ({
      audioPlay: parts.flatMap(
        (doc) => (doc.data()?.['audio-play'] ?? []) as EventAudioPlay[],
      ),
      noSlotWarnings: parts.flatMap(
        (doc) =>
          (doc.data()?.['no-slot-warning'] ?? []) as EventNoSlotWarning[],
      ),
    }))
  },
  (months) => {
    return {
      audioPlay: months.flatMap((m) => m.audioPlay),
      noSlotWarnings: months.flatMap((m) => m.noSlotWarnings),
    }
  },
)

export const napCache = createMonthlyCache(
  async (month: string) =>
    await loadNaps(
      '',
      startOfMonth(new Date(month + '-01')),
      endOfMonth(new Date(month + '-01')),
    ),
  (months) => months.flat(),
)

export async function loadNaps(
  space: string,
  gte: Date,
  lte: Date,
  withDetails?: boolean,
) {
  return (await graphql.queries.getNaps(space, gte, lte, withDetails)).napList
}

export async function loadUserSpace(lte: Date) {
  const chunkSize = 2500
  let userSpaces: EventUserSpace[] = []

  const loadPage = async (offset: number) => {
    const page = (await graphql.queries.getUserSpace(lte, offset, chunkSize))
      .userSpaceList
    userSpaces = [...userSpaces, ...page]

    if (page.length === chunkSize) {
      await loadPage(offset + chunkSize)
    }
  }

  await loadPage(0)

  return userSpaces
}
