import { graphql } from '../../services/graphql'

import { useOnMount } from '../use-on-mount'
import { useStore } from '../state'

export function useDatasourceAudio() {
  const Store = useStore()

  useOnMount(() => {
    return graphql.subscribe(['AudioTheme'], () => {
      graphql.queries
        .getAudioThemes()
        .then(({ audioThemeList }) =>
          Store.getActions().audio.setThemes(audioThemeList),
        )
    })
  })

  useOnMount(() => {
    return graphql.subscribe(['AudioBook'], () => {
      graphql.queries
        .getAudioBooks()
        .then(({ audioBookList }) =>
          Store.getActions().audio.setBooks(audioBookList),
        )
    })
  })

  useOnMount(() => {
    return graphql.subscribe(['AudioBackground'], () => {
      graphql.queries
        .getAudioBackgrounds()
        .then(({ audioBackgroundList }) =>
          Store.getActions().audio.setBackgrounds(audioBackgroundList),
        )
    })
  })
}
