/**
 * Create a closure for caching function calls for a given TTL.
 * @param defaultTtl - TTL applied on caches, can be overridden for each function
 */
export function createCallCache(defaultTtl: number) {
  // Create a map of functions to keyed cache map
  const fns: Map<() => unknown, Map<string, unknown>> = new Map()
  const timeouts: Map<() => unknown, Map<string, number>> = new Map()

  // Return a function allowing to wrap any function, with options
  const cachedCall = <T, U extends unknown[]>(
    fn: (...args: U) => T,
    params?: {
      // Key used for caching the call, defaults to "default"
      key?: string
      // Optional override TTL replacing the default one
      ttl?: number
      // Allow bypassing the cache
      force?: boolean
    },
  ): ((...args: U) => T) => {
    // If the function was never cached yet, create its cache map
    if (!fns.has(fn)) {
      fns.set(fn, new Map())
    }
    if (!timeouts.has(fn)) {
      timeouts.set(fn, new Map())
    }

    // Get the cache map and cache key for the calls
    const cacheMap = fns.get(fn) as Map<string, unknown>
    const cacheTimeouts = timeouts.get(fn) as Map<string, number>
    const cacheKey = params?.key ?? 'default'

    // Return a function taking the same params as the original function
    // The params will be used in case the original function needs to be called again
    return <R = T>(...args: U): R => {
      // Check if a cached call exist for the current cache key
      const call = cacheMap.get(cacheKey) as T | undefined

      // If a cached call was found and the bypass parameter is falsy,
      // return the cached call; otherwise call the actual function
      const cached = (params?.force ? null : call) ?? fn(...args)

      // If a cached call was not found, then we cache the
      // result of the actual function call
      if (!call || params?.force) {
        // Add the call to the cache
        cacheMap.set(cacheKey, cached)

        // Set up a timeout for clearing the cache
        window.clearTimeout(cacheTimeouts.get(cacheKey))
        cacheTimeouts.set(
          cacheKey,
          window.setTimeout(() => {
            cacheMap.delete(cacheKey)
          }, params?.ttl ?? defaultTtl),
        )
      }

      // Return the cached call result
      return cached as unknown as R
    }
  }

  // Allow manually clearing a cache key
  cachedCall.clear = <T, U extends unknown[]>(
    fn: (...args: U) => T,
    key = 'default',
  ) => {
    const cacheMap = fns.get(fn) as Map<string, unknown>
    cacheMap?.delete(key)
  }

  // Allow manually clearing all keys for a function
  cachedCall.clearAll = <T, U extends unknown[]>(fn: (...args: U) => T) => {
    fns.delete(fn)
  }

  return cachedCall
}

export const cacheCall = createCallCache(30 * 1000) // Default 30 seconds TTL
