import React from 'react'

import { AudioBackground } from '../../../../state/models/audio'
import { graphql } from '../../../../services/graphql'

import { useStoreState } from '../../../../hooks/state'
import { useSynchronizeData } from '../../../../hooks/use-synchronize-data'

import { buildComponent } from '../../../../components/factory'

import * as UI from '@mantine/core'

const Background = buildComponent<{
  background: AudioBackground
  active: boolean
  toggle: (e: { currentTarget: { checked: boolean } }) => void
}>().withRender(({ props }) => {
  const image =
    (process.env.REACT_APP_STATIC_URL as string) +
    `images/backgrounds/${props.background.slug}/${
      props.background.image ?? 'image'
    }.webp`

  return (
    <UI.Card
      p="lg"
      withBorder
      radius="md"
      sx={(theme) => ({
        position: 'relative',
        cursor: 'pointer',
        boxShadow: props.active
          ? 'inset 0 0 0 0.5rem ' + theme.colors.darkblue[5]
          : undefined,
        borderColor: props.active ? 'transparent' : undefined,
      })}
      component="label"
    >
      <UI.Image src={image} width={48} height={48} mx="auto" my="auto" />
      <UI.Checkbox
        size="sm"
        checked={props.active}
        onChange={props.toggle}
        color="darkblue"
        sx={{
          position: 'absolute',
          top: '-4px',
          right: '1px',
          opacity: props.active ? 1 : 0,
        }}
      />
    </UI.Card>
  )
})

export const SectionBackgrounds = buildComponent<{ book: string }>()
  .withLifecycle(({ props }) => {
    const backgrounds = useStoreState((store) => store.audio.backgrounds)
    const synchronize = useSynchronizeData(
      graphql.queries.getAudioBook,
      graphql.mutations.updateAudioBook,
      props.book,
    )

    const toggle =
      (slug: string) => (e: { currentTarget: { checked: boolean } }) => {
        const nextBackgrounds = (synchronize.data?.backgrounds ?? [])?.filter(
          (bg) => bg !== slug,
        )
        if (e.currentTarget.checked) {
          nextBackgrounds.push(slug)
        }

        synchronize.update({ backgrounds: nextBackgrounds })
      }

    return { backgrounds, toggle, ...synchronize }
  })
  .withRender(({ lifecycle }) => (
    <UI.Input.Wrapper
      sx={{ flex: 1 }}
      label={<b style={{ fontSize: '2rem' }}>Fonds sonores</b>}
      description="Sélectionnez les fonds sonores qui peuvent être utilisés pour ce livre audio"
    >
      <UI.Group mt={32} sx={{ flex: 1 }} position="center">
        {lifecycle.backgrounds.map((background) => (
          <Background
            background={background}
            key={background.slug}
            active={!!lifecycle.data?.backgrounds?.includes(background.slug)}
            toggle={lifecycle.toggle(background.slug)}
          />
        ))}
      </UI.Group>

      {lifecycle.loading && (
        <UI.Loader
          sx={{ position: 'absolute', top: '1rem', right: '1rem' }}
          size="xs"
        />
      )}
    </UI.Input.Wrapper>
  ))
