import React, { useCallback, useMemo, useState } from 'react'
import { useLocation } from 'wouter'

import { Localized, SpaceInfo } from '../../../../state/models/space'
import { graphql } from '../../../../services/graphql'
import { serviceModals } from '../../../../services/modals'

import { useSynchronizeData } from '../../../../hooks/use-synchronize-data'

import { buildComponent } from '../../../../components/factory'

import * as UI from '@mantine/core'
import * as Dates from '@mantine/dates'
import * as Icons from '../../../../components/icons'
import * as Semantics from '../../../../components/semantics'

const EditNameAndAddress = buildComponent<
  Partial<SpaceInfo> & { update: (info: Partial<SpaceInfo>) => void }
>()
  .withLifecycle(({ props }) => ({
    updateName: (localizedName: Localized) => props.update({ localizedName }),
    updateDescription: (localizedDescription: Localized) =>
      props.update({ localizedDescription }),
  }))
  .withRender(({ props, lifecycle }) => (
    <>
      <UI.Input.Wrapper
        label={<b style={{ fontSize: '2rem' }}>Date de création</b>}
      >
        <UI.Text>
          {props.created_at
            ? new Date(props.created_at).toLocaleDateString()
            : 'Inconnue'}
        </UI.Text>
      </UI.Input.Wrapper>
      <UI.Input.Wrapper
        label={<b style={{ fontSize: '2rem' }}>Nom de l'espace</b>}
      >
        <Semantics.LocalizedInput
          value={props.localizedName}
          onChange={lifecycle.updateName}
        />
      </UI.Input.Wrapper>
      <UI.Input.Wrapper
        label={<b style={{ fontSize: '2rem' }}>Adresse de l'espace</b>}
      >
        <Semantics.LocalizedInput
          value={props.localizedDescription}
          onChange={lifecycle.updateDescription}
        />
      </UI.Input.Wrapper>
    </>
  ))

const EditSubscriptionDates = buildComponent<
  Partial<SpaceInfo> & { update: (info: Partial<SpaceInfo>) => void }
>()
  .withLifecycle(({ props }) => ({
    get: (date: 'start' | 'end') =>
      props.subscription?.[date]
        ? new Date(props.subscription[date] as string)
        : null,
    update: (date: 'start' | 'end') => (value: Date) =>
      props.update({
        subscription: {
          [date]: value?.toISOString() ?? null,
        },
      }),
  }))
  .withRender(({ lifecycle }) => (
    <UI.Input.Wrapper
      sx={{ width: '100%' }}
      label={
        <b style={{ fontSize: '2rem' }}>
          Dates de début et de fin de souscription
        </b>
      }
    >
      <UI.Group sx={{ width: '100%' }}>
        <Dates.DatePicker
          sx={{ flex: 1 }}
          withinPortal
          placeholder="Aucune date de début"
          value={lifecycle.get('start')}
          onChange={lifecycle.update('start')}
        />
        <Dates.DatePicker
          sx={{ flex: 1 }}
          withinPortal
          placeholder="Souscription active indéfiniment"
          value={lifecycle.get('end')}
          onChange={lifecycle.update('end')}
        />
      </UI.Group>
    </UI.Input.Wrapper>
  ))

const EditAccessCodes = buildComponent<
  Partial<SpaceInfo> & {
    update: (info: Partial<SpaceInfo>) => { onError: (err: Error) => void }
  }
>()
  .withLifecycle(({ props }) => {
    const [code, setCode] = useState('')

    return {
      code,
      updateCode: (e: { currentTarget: { value: string } }) =>
        setCode(e.currentTarget.value),
      addCode: () => {
        if (code) {
          setCode('')
          const task = props.update({
            codes: [...(props.codes ?? []), { code, expiration: null }],
          })

          task.onError = () => {
            serviceModals.inform(
              <>Impossible d'ajouter un code d'accès</>,
              <>
                Le code d'accès <b>{code}</b> n'a pas pu être ajouté. Peut-être
                est-il déjà utilisé par un autre Espace ?
              </>,
            )
          }
        }
      },
      removeCode: (index: number) => () => {
        const codes = [...(props.codes ?? [])]
        codes.splice(index, 1)
        props.update({ codes })
      },
      updateExpiration: (index: number) => (date: Date | null) => {
        const codes = [...(props.codes ?? [])]
        codes[index].expiration = date?.toISOString() ?? null
        props.update({ codes })
      },
      isExpired: (expiration: string | null) => {
        return expiration && new Date(expiration).valueOf() < Date.now()
      },
    }
  })
  .withRender(({ props, lifecycle }) => (
    <UI.Input.Wrapper
      sx={{ width: '100%' }}
      label={<b style={{ fontSize: '2rem' }}>Codes de l'espace</b>}
    >
      <UI.Card mt={8} p={0} withBorder>
        {props.codes
          ?.sort((a, b) => {
            if (a.expiration && b.expiration) {
              return a.expiration.localeCompare(b.expiration)
            }

            if (a.expiration) {
              return -1
            }

            if (b.expiration) {
              return 1
            }

            return 0
          })
          .map((code, index) => {
            const expired = lifecycle.isExpired(code.expiration)
            const active = code.code === props.code

            return (
              <React.Fragment key={code.code}>
                <UI.Group
                  px={16}
                  mb={2}
                  position="apart"
                  sx={{
                    opacity: expired ? 0.5 : 1,
                  }}
                >
                  <UI.Kbd
                    sx={{
                      textDecoration: expired ? 'line-through' : '',
                    }}
                  >
                    {code.code}
                    {active && (
                      <UI.Text component="span" color="green" ml={8}>
                        <Icons.FontAwesomeIcon icon={Icons.Solid.faCheck} />
                      </UI.Text>
                    )}
                  </UI.Kbd>
                  <UI.Group>
                    <Dates.DatePicker
                      withinPortal
                      value={code.expiration ? new Date(code.expiration) : null}
                      placeholder="Code sans expiration"
                      onChange={lifecycle.updateExpiration(index)}
                    />
                    <UI.UnstyledButton
                      disabled={active}
                      sx={{ opacity: active ? 0 : 1 }}
                      onClick={lifecycle.removeCode(index)}
                    >
                      <UI.Text color="red">
                        <Icons.FontAwesomeIcon icon={Icons.Solid.faTimes} />
                      </UI.Text>
                    </UI.UnstyledButton>
                  </UI.Group>
                </UI.Group>
                <UI.Divider />
              </React.Fragment>
            )
          })}
        <UI.Group mx={16} mb={4} sx={{ flex: 1 }}>
          <UI.Input
            placeholder="Nouveau code"
            sx={{ flex: 1, width: '100%' }}
            value={lifecycle.code}
            onChange={lifecycle.updateCode}
          />
          <UI.Button
            size="sm"
            sx={{ minHeight: 38 }}
            mb={1}
            px="4rem"
            onClick={lifecycle.addCode}
          >
            Ajouter
          </UI.Button>
        </UI.Group>
      </UI.Card>
    </UI.Input.Wrapper>
  ))

const EditSubscriptionType = buildComponent<
  Partial<SpaceInfo> & { update: (info: Partial<SpaceInfo>) => void }
>().withRender(({ props }) => {
  const isPremium = useMemo(() => {
    if (
      !props.subscription ||
      (props.subscription && !props.subscription.premium)
    ) {
      return false
    } else if (!props.subscription.end) {
      return true
    }
    return new Date(props.subscription.end) > new Date()
  }, [props])

  //4261
  //quand on active le premium , si la date de fin actuelle est inférieur a aujourd'hui on la supprime
  //par consequent si on rend premium un espace expiré basic, il devient automatiquement premium actif
  const activePremium = useCallback(() => {
    let end = props.subscription?.end || null
    if (!end) {
      props.update({ subscription: { premium: true } })
    } else {
      if (new Date(end) < new Date()) {
        props.update({ subscription: { premium: true, end: undefined } })
      } else {
        props.update({ subscription: { premium: true } })
      }
    }
  }, [props])

  return (
    <UI.Input.Wrapper
      label={<b style={{ fontSize: '2rem' }}>Offre souscrite</b>}
      mb={16}
    >
      <UI.Group mt={24} mx={32}>
        <UI.Button
          onClick={() => props.update({ subscription: { premium: false } })}
          style={{ minHeight: '4rem', width: '16rem' }}
          color={isPremium ? 'gray' : undefined}
        >
          Basique
        </UI.Button>
        <UI.Button
          onClick={activePremium}
          style={{ minHeight: '4rem', width: '16rem' }}
          color={isPremium ? undefined : 'gray'}
        >
          Premium
        </UI.Button>
      </UI.Group>
    </UI.Input.Wrapper>
  )
})

const EditNumberOfLicense = buildComponent<
  Partial<SpaceInfo> & { update: (info: Partial<SpaceInfo>) => void }
>().withRender(({ props }) => (
  <UI.NumberInput
    label={<b style={{ fontSize: '2rem' }}>Nombre de licenses</b>}
    description="0 = licenses illimitées"
    styles={{ description: { marginBottom: '2rem' } }}
    value={props.subscription?.licenses ?? 0}
    onChange={(licenses) =>
      props.update({
        subscription: { licenses },
      })
    }
  />
))

const EditEnabledOptions = buildComponent<
  Partial<SpaceInfo> & {
    update: (info: Partial<SpaceInfo>, timeout?: number) => void
  }
>()
  .withLifecycle(({ props }) => ({
    updateDisabledOption:
      (option: 'booking' | 'profile') =>
      (e: { currentTarget: { checked: boolean } }) =>
        props.update(
          {
            subscription: {
              disabled: {
                [option]: !e.currentTarget.checked,
              },
            },
          },
          0,
        ),
  }))
  .withRender(({ props, lifecycle }) => (
    <UI.Input.Wrapper
      sx={{ width: '100%' }}
      label={<b style={{ fontSize: '2rem' }}>Fonctionnalités disponibles</b>}
    >
      <UI.Stack pl={16} pt={16} spacing={0}>
        <UI.Checkbox
          disabled={!!props.subscription?.disabled?.profile}
          title={
            props.subscription?.disabled?.profile
              ? "Impossible d'activer les réservations sans le profil utilisateur"
              : ''
          }
          checked={
            !props.subscription?.disabled?.profile &&
            !props.subscription?.disabled?.booking
          }
          label="Réservations"
          onChange={lifecycle.updateDisabledOption('booking')}
        />
        <UI.Checkbox
          checked={!props.subscription?.disabled?.profile}
          label="Profil utilisateur"
          onChange={lifecycle.updateDisabledOption('profile')}
        />
      </UI.Stack>
    </UI.Input.Wrapper>
  ))

const EditEmails = buildComponent<
  Partial<SpaceInfo> & { update: (info: Partial<SpaceInfo>) => void }
>()
  .withLifecycle(({ props }) => {
    const [email, setEmail] = useState('')

    return {
      email,
      updateEmail: (e: { currentTarget: { value: string } }) =>
        setEmail(e.currentTarget.value),
      addEmail: () => {
        if (email) {
          setEmail('')
          props.update({
            emails: [...(props.emails ?? []), { email }],
          })
        }
      },
      removeEmail: (index: number) => () => {
        const emails = [...(props.emails ?? [])]
        emails.splice(index, 1)
        props.update({ emails })
      },
    }
  })
  .withRender(({ props, lifecycle }) => (
    <UI.Input.Wrapper
      sx={{ width: '100%' }}
      label={<b style={{ fontSize: '2rem' }}>Emails de contact</b>}
    >
      <UI.Card mt={8} p={0}>
        {props.emails?.map((email, index) => (
          <React.Fragment key={email.email}>
            <UI.Group px={16} mb={2} position="apart">
              <UI.Text>{email.email}</UI.Text>
              <UI.UnstyledButton onClick={lifecycle.removeEmail(index)}>
                <UI.Text color="red">
                  <Icons.FontAwesomeIcon icon={Icons.Solid.faTimes} />
                </UI.Text>
              </UI.UnstyledButton>
            </UI.Group>
            <UI.Divider />
          </React.Fragment>
        ))}
        <UI.Group mx={16} mb={4} sx={{ flex: 1 }}>
          <UI.Input
            placeholder="Nouvel email"
            sx={{ flex: 1, width: '100%' }}
            value={lifecycle.email}
            onChange={lifecycle.updateEmail}
          />
          <UI.Button
            size="sm"
            sx={{ minHeight: 38 }}
            mb={1}
            px="4rem"
            onClick={lifecycle.addEmail}
          >
            Ajouter
          </UI.Button>
        </UI.Group>
      </UI.Card>
    </UI.Input.Wrapper>
  ))

export const SectionMain = buildComponent<{ space: string }>()
  .withLifecycle(({ props }) => {
    const {
      loading,
      data: space,
      update,
    } = useSynchronizeData(
      graphql.queries.getSpaceInfo,
      graphql.mutations.updateSpaceInfo,
      props.space,
    )

    const [, setLocation] = useLocation()
    const remove = async () => {
      await serviceModals.confirmDeletion(
        'Supprimer un espace',
        'Voulez-vous vraiment supprimer cet espace ?',
        async () => {
          await graphql.mutations.deleteSpace(props.space)
          setLocation('/spaces')
        },
      )
    }

    return { space, update, loading, remove }
  })
  .withRender(({ lifecycle }) => (
    <UI.Group spacing={64} align="start" sx={{ position: 'relative' }}>
      <UI.Stack sx={{ flex: 1, width: '100%' }}>
        <EditNameAndAddress {...lifecycle.space} update={lifecycle.update} />
        <EditSubscriptionDates {...lifecycle.space} update={lifecycle.update} />
        <EditAccessCodes {...lifecycle.space} update={lifecycle.update} />
      </UI.Stack>

      <UI.Stack sx={{ flex: 'none' }} spacing={48}>
        <EditSubscriptionType {...lifecycle.space} update={lifecycle.update} />
        <EditNumberOfLicense {...lifecycle.space} update={lifecycle.update} />
        <EditEnabledOptions {...lifecycle.space} update={lifecycle.update} />
        <EditEmails {...lifecycle.space} update={lifecycle.update} />

        <UI.Button
          leftIcon={<Icons.FontAwesomeIcon icon={Icons.Solid.faTrash} />}
          color="red"
          mt={32}
          ml="auto"
          px={32}
          sx={{ minHeight: '3rem' }}
          onClick={lifecycle.remove}
        >
          Supprimer l'Espace
        </UI.Button>
      </UI.Stack>

      {lifecycle.loading && (
        <UI.Loader
          sx={{ position: 'absolute', top: '0', right: '-1rem' }}
          size="xs"
        />
      )}
    </UI.Group>
  ))
