import React from 'react'
import ReactDOM from 'react-dom/client'
import 'dayjs/locale/fr'

import './resources/locale/locale'
import { theme } from './theme'
import { Store } from './state'

import { StoreProvider } from 'easy-peasy'
import { MantineProvider } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'

import { Router } from './layout/router'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

/* // Store the original Date constructor
const OriginalDate = Date

// compute timezone offset between utc and europe/paris
const dateInParis = new Date().toLocaleString('en-US', {
  timeZone: 'Europe/Paris',
})
const utcDate = new Date().toLocaleString('en-US', { timeZone: 'UTC' })

// Compute timezone offset
const parisOffset =
  new Date(utcDate).getTime() - new Date(dateInParis).getTime()

class CustomDate extends OriginalDate {
  constructor(...args: [any?]) {
    super(...args)
    this.setTime(this.getTime() + parisOffset)
  }
}

Date = CustomDate as DateConstructor */

root.render(
  <StoreProvider store={Store}>
    <MantineProvider theme={theme} withGlobalStyles>
      <ModalsProvider>
        <Router />
      </ModalsProvider>
    </MantineProvider>
  </StoreProvider>,
)
