import React, { useRef, useState } from 'react'

import { buildComponent } from '../factory'
import { storage } from '../../services/firebase'

import * as UI from '@mantine/core'

export const LegacyImageButton = buildComponent<{
  width: number
  height: number
  entity?: string
  slug?: string
  kind: 'themes' | 'backgrounds' | 'books'
  variant: 'image' | 'cover' | 'icon'
}>()
  .withLifecycle(({ props }) => {
    const [cacheBurst, setCacheBurst] = useState(Date.now())
    const directoryPath =
      'images/' + props.kind + '/' + (props.slug ?? 'unknown')
    const imageName = props.variant
    const imageExtension = '.svg'
    const image = directoryPath + '/' + imageName + imageExtension

    const resetRef = useRef<() => void>(null)

    const onChange = async (file: File | null) => {
      if (!file) {
        return
      }

      await storage().ref(image).put(file)
      resetRef.current?.()

      setCacheBurst(Date.now())
    }

    return { image: image + '?v=' + cacheBurst, onChange, resetRef }
  })
  .withRender(({ props, lifecycle }) => (
    <UI.Box mx="auto" mt={32} sx={{ width: props.width, height: props.height }}>
      <UI.FileButton
        onChange={lifecycle.onChange}
        accept={'image/svg+xml'}
        resetRef={lifecycle.resetRef}
      >
        {(p) => (
          <UI.UnstyledButton {...p}>
            <UI.Card
              withBorder
              p={0}
              sx={{
                width: props.width,
                height: props.height,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <UI.Image
                width={
                  props.kind === 'backgrounds' ? props.width / 2 : props.width
                }
                height={
                  props.kind === 'backgrounds' ? props.width / 2 : props.height
                }
                withPlaceholder
                key={lifecycle.image}
                src={`${process.env.REACT_APP_STATIC_URL as string}${
                  lifecycle.image
                }`}
              />
            </UI.Card>
          </UI.UnstyledButton>
        )}
      </UI.FileButton>
    </UI.Box>
  ))
