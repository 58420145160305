import React from 'react'

import * as UI from '@mantine/core'
import * as Icons from '../components/icons'
import * as Routing from 'wouter'

//import Logo from '../resources/icons/logo-white.svg'
import Logo from '../resources/icons/nap-up-couleurs-fond-bleu-foncé.png'
import { buildComponent } from '../components/factory'
import { useStore } from '../hooks/state'
import { useRoute } from 'wouter'

const useStyles = UI.createStyles((theme) => ({
  header: {
    paddingTop: theme.spacing.sm,
    backgroundColor: theme.fn.variant({
      variant: 'filled',
      color: theme.primaryColor,
    }).background,
    borderBottom: `1px solid ${
      theme.fn.variant({ variant: 'filled', color: theme.primaryColor })
        .background
    }`,
    marginBottom: 120,
  },

  mainSection: {
    paddingBottom: theme.spacing.sm,
  },

  tabsList: {
    borderBottom: '0 !important',
  },

  tab: {
    fontWeight: 700,
    height: 48,
    marginRight: 8,
    color: theme.white,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: 'transparent',
    borderColor: theme.fn.variant({
      variant: 'filled',
      color: theme.primaryColor,
    }).background,

    '&:hover': {
      backgroundColor: theme.fn.lighten(
        theme.fn.variant({ variant: 'filled', color: theme.primaryColor })
          .background as string,
        0.1,
      ),
    },

    '&[data-active]': {
      color: theme.colors.blue[9],
      backgroundColor: '#f8f9fc',
      borderColor: theme.fn.variant({
        variant: 'filled',
        color: theme.primaryColor,
      }).background,

      '&:before': {
        height: 0,
      },
    },
  },
}))

const Tab = buildComponent<
  Icons.FontAwesomeIconProps & { value: string }
>().withRender(({ props }) => (
  <Routing.Link to={'/' + props.value}>
    <UI.Anchor sx={{ '&:hover': { textDecoration: 'none' } }}>
      <UI.Tabs.Tab value={props.value}>
        <Icons.FontAwesomeIcon icon={props.icon} />
        &nbsp; {props.children}
      </UI.Tabs.Tab>
    </UI.Anchor>
  </Routing.Link>
))

export const TopBar = buildComponent()
  .withLifecycle(() => {
    const Store = useStore()
    const logout = () => Store.getActions().utils.session.disconnect()
    const [, params] = useRoute('/:tab/:rest*')
    const tab = params?.tab

    return { logout, tab }
  })
  .withRender(({ lifecycle }) => {
    const { classes } = useStyles()

    return (
      <UI.Header height={72} className={classes.header}>
        <UI.Container fluid className={classes.mainSection}>
          <UI.Group position="apart" align="start">
            <UI.Image mt={2} width={55} src={Logo} alt="Nap&Up" />

            <UI.Tabs
              ml={32}
              mr="auto"
              mt={12}
              variant="outline"
              classNames={{
                tabsList: classes.tabsList,
                tab: classes.tab,
              }}
              value={lifecycle.tab}
            >
              <UI.Tabs.List>
                <Tab value="spaces" icon={Icons.Solid.faTreeCity}>
                  Espaces
                </Tab>
                <Tab value="audios" icon={Icons.Solid.faHeadphonesAlt}>
                  Audios
                </Tab>
                <Tab value="statistics" icon={Icons.Solid.faBarChart}>
                  Statistiques
                </Tab>
                <Tab value="users" icon={Icons.Solid.faUsers}>
                  Utilisateurs
                </Tab>
                <Tab value="content" icon={Icons.Solid.faEdit}>
                  Contenu
                </Tab>
              </UI.Tabs.List>
            </UI.Tabs>

            <UI.UnstyledButton
              onClick={lifecycle.logout}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 48,
                height: 48,
                borderRadius: 8,
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                },
                '&:active': {
                  opacity: 0.7,
                },
              }}
            >
              <Icons.FontAwesomeIcon
                color="white"
                width={32}
                icon={Icons.Solid.faPowerOff}
              />
            </UI.UnstyledButton>
          </UI.Group>
        </UI.Container>
      </UI.Header>
    )
  })
