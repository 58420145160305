export function createMonthlyCache<T>(
  fn: (month: string) => Promise<T>,
  aggregate: (months: T[]) => T,
) {
  const cache = new Map<string, Promise<T>>()
  async function loadMonth(month: string): Promise<T> {
    if (!cache.has(month)) {
      const promise = fn(month)
      cache.set(month, promise)
      promise.catch(() => cache.delete(month))
    }

    return (await cache.get(month)) as T
  }
  async function loadYear(year: number | string) {
    const months = await Promise.all(
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((m) =>
        loadMonth(year + '-' + m),
      ),
    ).catch(() => [] as T[])

    return aggregate(months)
  }

  return { loadMonth, loadYear }
}
