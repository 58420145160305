import React from 'react'
import { differenceInMinutes, format, startOfDay, startOfMonth } from 'date-fns'

import { SpaceCocoons } from '../../../../state/models/space'

import { buildComponent } from '../../../../components/factory'

import { EventNap } from './data'
import { useChartValues, BarGraph, PieChart } from './graphs'

export const BookingDurationPie = buildComponent<{ naps: EventNap[] }>()
  .withLifecycle(({ props }) => {
    return useChartValues({
      events: props.naps,
      label: (event) => {
        const duration = differenceInMinutes(
          new Date(event.time.end),
          new Date(event.time.start),
        )
        return duration + ' minutes'
      },
      sort: (a, b) => Number.parseFloat(a.label) - Number.parseFloat(b.label),
    })
  })
  .withRender(({ lifecycle }) => (
    <PieChart
      data={lifecycle.data}
      shifted
      single={{
        label: 'Une seule durée',
        kind: 'sieste',
      }}
    />
  ))

export const BookingSlotsBars = buildComponent<{ naps: EventNap[] }>()
  .withLifecycle(({ props }) => {
    return useChartValues({
      events: props.naps,
      label: (event) => {
        const slotStart = new Date(event.time.start)
        const hours = slotStart.getHours()
        const minutes = slotStart.getMinutes() >= 30 ? 30 : 0
        return hours + ':' + (minutes + '').padStart(2, '0')
      },
      sort: (a, b) => {
        const [aHours, aMinutes] = a.label.split(':').map(Number)
        const [bHours, bMinutes] = b.label.split(':').map(Number)
        return aHours - bHours || aMinutes - bMinutes
      },
    })
  })
  .withRender(({ lifecycle }) => (
    <BarGraph data={lifecycle.data} tilted color="#073475" />
  ))

export const BookingByCocoonPie = buildComponent<{
  naps: EventNap[]
  cocoons: SpaceCocoons['cocoons']
}>()
  .withLifecycle(({ props }) => {
    return useChartValues({
      events: props.naps,
      label: (event) =>
        props.cocoons.find((cocoon) => cocoon._id === event.cocoon?._id)
          ?.localizedName?.fr ?? 'Autre',
    })
  })
  .withRender(({ lifecycle }) => (
    <PieChart
      data={lifecycle.data}
      color="#073475"
      bottom
      single={{
        label: 'Un seul cocon',
        kind: 'sieste',
      }}
    />
  ))

export const BookingByRoomPie = buildComponent<{
  naps: EventNap[]
  rooms: SpaceCocoons['rooms']
}>()
  .withLifecycle(({ props }) => {
    return useChartValues({
      events: props.naps,
      label: (event) =>
        props.rooms.find((room) => room._id === event.cocoon?.room?._id)
          ?.localizedName?.fr ?? 'Autre',
    })
  })
  .withRender(({ lifecycle }) => (
    <PieChart
      data={lifecycle.data}
      color="#ffad73"
      bottom
      single={{
        label: 'Une seule salle',
        kind: 'sieste',
      }}
    />
  ))

export const BookingByPeriodBars = buildComponent<{
  annual: boolean
  naps: EventNap[]
}>()
  .withLifecycle(({ props }) => {
    return useChartValues({
      events: props.naps,
      label: (event) => {
        const date = new Date(event.time.start)
        return props.annual
          ? format(startOfMonth(date), 'MM/yyyy')
          : format(startOfDay(date), 'dd/MM/yyyy')
      },
    })
  })
  .withRender(({ lifecycle }) => <BarGraph data={lifecycle.data} />)
