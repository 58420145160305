import React from 'react'

import { buildComponent } from '../../../../components/factory'

import * as UI from '@mantine/core'
import * as Semantics from '../../../../components/semantics'
import { AudioTheme } from '../../../../state/models/audio'
import { Localized } from '../../../../state/models/space'
import { useSynchronizeData } from '../../../../hooks/use-synchronize-data'
import { graphql } from '../../../../services/graphql'

const ShowUniqueIdentifier = buildComponent<{ slug?: string }>().withRender(
  ({ props }) => (
    <UI.Group>
      <UI.Text weight={700}>Identifiant unique</UI.Text>
      <UI.Kbd mb={-4} px={16}>
        {props.slug ?? '...'}
      </UI.Kbd>
    </UI.Group>
  ),
)

const EditName = buildComponent<{
  title?: Localized
  short?: Localized
  update: (next: Partial<AudioTheme>) => void
}>()
  .withLifecycle(({ props }) => ({
    updateTitle: (title: Localized) => props.update({ title }),
    updateShort: (short: Localized) => props.update({ short }),
  }))
  .withRender(({ props, lifecycle }) => (
    <UI.Group sx={{ width: '100%' }} spacing={32}>
      <UI.Input.Wrapper
        sx={{ flex: 1, width: '100%' }}
        label={<b style={{ fontSize: '2rem' }}>Nom de la thématique</b>}
        description="Affiché lors de la configuration de l'audio et dans le lecteur"
      >
        <Semantics.LocalizedInput
          value={props.title}
          onChange={lifecycle.updateTitle}
        />
      </UI.Input.Wrapper>
      <UI.Input.Wrapper
        sx={{ flex: 1, width: '100%' }}
        label={<b style={{ fontSize: '2rem' }}>Nom court</b>}
        description="Affiché dès lors que le nom complet serait trop long, par exemple sur les cartes de sieste"
      >
        <Semantics.LocalizedInput
          value={props.short}
          onChange={lifecycle.updateShort}
        />
      </UI.Input.Wrapper>
    </UI.Group>
  ))

const EditDescription = buildComponent<{
  description?: Localized
  update: (next: Partial<AudioTheme>) => void
}>()
  .withLifecycle(({ props }) => ({
    updateDescription: (description: Localized) =>
      props.update({ description }),
  }))
  .withRender(({ props, lifecycle }) => (
    <UI.Input.Wrapper
      sx={{ width: '100%' }}
      label={<b style={{ fontSize: '2rem' }}>Description</b>}
      description="Accessible lors de la configuration de l'audio, permet de décrire plus en détail le contenu de la thématique"
    >
      <Semantics.LocalizedInput
        value={props.description}
        onChange={lifecycle.updateDescription}
      />
    </UI.Input.Wrapper>
  ))

export const SectionMain = buildComponent<{ theme: string }>()
  .withLifecycle(({ props }) => {
    return useSynchronizeData(
      graphql.queries.getAudioTheme,
      graphql.mutations.updateAudioTheme,
      props.theme,
    )
  })
  .withRender(({ lifecycle }) => (
    <UI.Stack sx={{ flex: 1 }} spacing={24}>
      <ShowUniqueIdentifier slug={lifecycle.data?.slug} />
      <EditName {...lifecycle.data} update={lifecycle.update} />
      <EditDescription {...lifecycle.data} update={lifecycle.update} />

      {lifecycle.loading && (
        <UI.Loader
          sx={{ position: 'absolute', top: '1rem', right: '1rem' }}
          size="xs"
        />
      )}
    </UI.Stack>
  ))
