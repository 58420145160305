import React from 'react'

import { buildComponent } from '../../../../components/factory'

import * as UI from '@mantine/core'

const useStyles = UI.createStyles(() => ({
  root: {
    height: 32,
    lineHeight: 1,

    '.printmode &': {
      height: 24,
    },
  },
  svg: {
    height: 24,

    '.printmode &': {
      height: 20,
    },
  },
}))

export const StarMask = buildComponent<{ value: number }>().withRender(
  ({ props }) => {
    const styles = useStyles()

    return (
      <UI.Box className={styles.classes.root}>
        <svg className={styles.classes.svg} viewBox="0 0 184 32">
          <g>
            <path
              fill="#FFFFFF22"
              d="M145.9,12.1c-0.1-0.4-0.5-0.6-0.9-0.6h-10.6l-3.5-10c-0.1-0.4-0.5-0.6-0.9-0.6c-0.5,0-0.8,0.2-0.9,0.6 l-3.5,10H115c-0.5,0-0.8,0.2-0.9,0.6c0.1,0.5,0,0.8,0.3,1.1l8.5,6.7L119.8,30c0.1,0.4,0,0.8,0.3,1.1c0.4,0.2,0.8,0.3,1.2,0L130,25l8.7,6.1c0.2,0.1,0.4,0.2,0.6,0.2c0.5,0,1-0.4,1-1c0-0.1,0-0.2-0.1-0.4l-3.1-10.1l8.5-6.7C146,12.9,146.1,12.5,145.9,12.1L145.9,12.1z"
            />
            <path
              fill="#FFFFFF22"
              d="M107.9,12.1c-0.1-0.4-0.5-0.6-0.9-0.6H96.4l-3.5-10c-0.1-0.4-0.5-0.6-0.9-0.6c-0.5,0-0.8,0.2-0.9,0.6l-3.5,10H77c-0.5,0-0.8,0.2-0.9,0.6c-0.1,0.5,0,0.8,0.3,1.1l8.5,6.7L81.8,30c0.1,0.4,0,0.8,0.3,1.1c0.4,0.2,0.8,0.3,1.2,0L92,25l8.7,6.1c0.2,0.1,0.4,0.2,0.6,0.2c0.5,0,1-0.4,1-1c0-0.1,0-0.2-0.1-0.4l-3.1-10.1l8.5-6.7C108,12.9,108.1,12.5,107.9,12.1L107.9,12.1z"
            />
            <path
              fill="#FFFFFF22"
              d="M69.9,12.1c-0.1-0.4-0.5-0.6-0.9-0.6H58.4l-3.5-10c-0.1-0.4-0.5-0.6-0.9-0.6c-0.5,0-0.8,0.2-0.9,0.6l-3.5,10H39c-0.5,0-0.8,0.2-0.9,0.6c-0.1,0.5,0,0.8,0.3,1.1l8.5,6.7L43.8,30c0.1,0.4,0,0.8,0.3,1.1c0.4,0.2,0.8,0.3,1.2,0L54,25l8.7,6.1c0.2,0.1,0.4,0.2,0.6,0.2c0.5,0,1-0.4,1-1c0-0.1,0-0.2-0.1-0.4l-3.1-10.1l8.5-6.7C70,12.9,70.1,12.5,69.9,12.1L69.9,12.1z"
            />
            <path
              fill="#FFFFFF22"
              d="M31.9,12.1c-0.1-0.4-0.5-0.6-0.9-0.6H20.4l-3.5-10c-0.1-0.4-0.5-0.6-0.9-0.6c-0.5,0-0.8,0.2-0.9,0.6l-3.5,10H1c-0.5,0-0.8,0.2-0.9,0.6c-0.1,0.5,0,0.8,0.3,1.1l8.5,6.7L5.8,30c-0.1,0.4,0,0.8,0.3,1.1c0.4,0.2,0.8,0.3,1.2,0L16,25l8.7,6.1c0.2,0.1,0.4,0.2,0.6,0.2c0.5,0,1-0.4,1-1c0-0.1,0-0.2-0.1-0.4l-3.1-10.1l8.5-6.7C32,12.9,32.1,12.5,31.9,12.1L31.9,12.1z"
            />
            <path
              fill="#FFFFFF22"
              d="M183.9,12.1L183.9,12.1c-0.1-0.4-0.5-0.6-0.9-0.6h-10.6l-3.5-10c-0.1-0.4-0.5-0.6-0.9-0.6c-0.5,0-0.8,0.2-0.9,0.6l-3.5,10H153c-0.5,0-0.8,0.2-0.9,0.6c-0.1,0.5,0,0.8,0.3,1.1l8.5,6.7L157.8,30c-0.1,0.4,0,0.8,0.3,1.1c0.4,0.2,0.8,0.3,1.2,0L168,25l8.7,6.1c0.2,0.1,0.4,0.2,0.6,0.2c0.5,0,1-0.4,1-1c0-0.1,0-0.2-0.1-0.4l-3.1-10.1l8.5-6.7C184,12.9,184.1,12.5,183.9,12.1z"
            />
          </g>
          <clipPath id="star-filled">
            <path
              fill="#000000"
              d="M145.9,12.1c-0.1-0.4-0.5-0.6-0.9-0.6h-10.6l-3.5-10c-0.1-0.4-0.5-0.6-0.9-0.6c-0.5,0-0.8,0.2-0.9,0.6l-3.5,10H115c-0.5,0-0.8,0.2-0.9,0.6c-0.1,0.5,0,0.8,0.3,1.1l8.5,6.7L119.8,30c-0.1,0.4,0,0.8,0.3,1.1c0.4,0.2,0.8,0.3,1.2,0L130,25l8.7,6.1c0.2,0.1,0.4,0.2,0.6,0.2c0.5,0,1-0.4,1-1c0-0.1,0-0.2-0.1-0.4l-3.1-10.1l8.5-6.7C146,12.9,146.1,12.5,145.9,12.1L145.9,12.1z"
            />
            <path
              fill="#000000"
              d="M107.9,12.1c-0.1-0.4-0.5-0.6-0.9-0.6H96.4l-3.5-10c-0.1-0.4-0.5-0.6-0.9-0.6c-0.5,0-0.8,0.2-0.9,0.6l-3.5,10H77c-0.5,0-0.8,0.2-0.9,0.6c-0.1,0.5,0,0.8,0.3,1.1l8.5,6.7L81.8,30c-0.1,0.4,0,0.8,0.3,1.1c0.4,0.2,0.8,0.3,1.2,0L92,25l8.7,6.1c0.2,0.1,0.4,0.2,0.6,0.2c0.5,0,1-0.4,1-1c0-0.1,0-0.2-0.1-0.4l-3.1-10.1l8.5-6.7C108,12.9,108.1,12.5,107.9,12.1L107.9,12.1z"
            />
            <path
              fill="#000000"
              d="M69.9,12.1c-0.1-0.4-0.5-0.6-0.9-0.6H58.4l-3.5-10c-0.1-0.4-0.5-0.6-0.9-0.6c-0.5,0-0.8,0.2-0.9,0.6l-3.5,10H39c-0.5,0-0.8,0.2-0.9,0.6c-0.1,0.5,0,0.8,0.3,1.1l8.5,6.7L43.8,30c-0.1,0.4,0,0.8,0.3,1.1c0.4,0.2,0.8,0.3,1.2,0L54,25l8.7,6.1c0.2,0.1,0.4,0.2,0.6,0.2c0.5,0,1-0.4,1-1c0-0.1,0-0.2-0.1-0.4l-3.1-10.1l8.5-6.7C70,12.9,70.1,12.5,69.9,12.1L69.9,12.1z"
            />
            <path
              fill="#000000"
              d="M31.9,12.1c-0.1-0.4-0.5-0.6-0.9-0.6H20.4l-3.5-10c-0.1-0.4-0.5-0.6-0.9-0.6c-0.5,0-0.8,0.2-0.9,0.6l-3.5,10H1c-0.5,0-0.8,0.2-0.9,0.6c-0.1,0.5,0,0.8,0.3,1.1l8.5,6.7L5.8,30c-0.1,0.4,0,0.8,0.3,1.1c0.4,0.2,0.8,0.3,1.2,0L16,25l8.7,6.1c0.2,0.1,0.4,0.2,0.6,0.2c0.5,0,1-0.4,1-1c0-0.1,0-0.2-0.1-0.4l-3.1-10.1l8.5-6.7C32,12.9,32.1,12.5,31.9,12.1L31.9,12.1z"
            />
            <path
              fill="#000000"
              d="M183.9,12.1L183.9,12.1c-0.1-0.4-0.5-0.6-0.9-0.6h-10.6l-3.5-10c-0.1-0.4-0.5-0.6-0.9-0.6c-0.5,0-0.8,0.2-0.9,0.6l-3.5,10H153c-0.5,0-0.8,0.2-0.9,0.6c-0.1,0.5,0,0.8,0.3,1.1l8.5,6.7L157.8,30c-0.1,0.4,0,0.8,0.3,1.1c0.4,0.2,0.8,0.3,1.2,0L168,25l8.7,6.1c0.2,0.1,0.4,0.2,0.6,0.2c0.5,0,1-0.4,1-1c0-0.1,0-0.2-0.1-0.4l-3.1-10.1l8.5-6.7C184,12.9,184.1,12.5,183.9,12.1z"
            />
          </clipPath>
          <rect
            fill="white"
            x="0"
            width="0"
            height="32"
            clipPath="url(#star-filled)"
            style={{ width: props.value + '%' }}
          />
        </svg>
      </UI.Box>
    )
  },
)
