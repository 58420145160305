import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import locale from 'date-fns/locale/fr'

import { buildComponent } from '../../../../components/factory'

import { useSessionBoolean } from '../../../../hooks/use-session-value'

import { feedbackCache } from './data'
import { DateSelector } from './date-selector'

import * as UI from '@mantine/core'

const CATEGORIES = {
  application: 'Général',
  booking: 'Réservations',
  audio: 'Audios',
  spaces: 'Espaces',
  compliments: 'Compliments',
  other: 'Autre',
}

const FeedbackEntry = buildComponent<{
  user: { email: string }
  date: string
  message: string
  category: keyof typeof CATEGORIES
}>().withRender(({ props }) => (
  <UI.Group p={8}>
    <UI.Stack
      sx={{ width: '24rem' }}
      align="center"
      justify="center"
      spacing={12}
    >
      <UI.Badge color="dark" size="md">
        {CATEGORIES[props.category] ?? CATEGORIES.other}
      </UI.Badge>
      <UI.Badge color="yellow" size="xs">
        Contact autorisé
      </UI.Badge>
    </UI.Stack>
    <UI.Stack sx={{ flex: '1' }} spacing={4}>
      <UI.Text size="sm">
        {props.user.email}, le{' '}
        {format(new Date(props.date), 'dd/MM/yy', { locale })} à{' '}
        {format(new Date(props.date), 'HH:mm', { locale })}
      </UI.Text>

      <UI.Text
        py={8}
        px={16}
        sx={(theme) => ({
          backgroundColor: theme.fn.lighten(theme.colors.blue[0], 0.25),
          borderRadius: '1rem',
          minHeight: '8rem',
        })}
      >
        {props.message}
      </UI.Text>
    </UI.Stack>
  </UI.Group>
))

export const Feedback = buildComponent<{
  date: Date
  setDate: (next: Date) => void
}>()
  .withLifecycle(({ props }) => {
    const [feedback, setFeedback] = useState<
      {
        category: keyof typeof CATEGORIES
        date: string
        message: string
        user: { email: string }
      }[]
    >([])
    const [annual] = useSessionBoolean('StatisticsAnnual')
    const year = props.date.getFullYear()
    const month = year + '-' + (props.date.getMonth() + 1)

    // Load feedbacks
    useEffect(() => {
      const promise = annual
        ? feedbackCache.loadYear(year)
        : feedbackCache.loadMonth(month)
      promise.then(setFeedback)
    }, [month, year, annual])

    return {
      feedback: feedback.sort((a, b) => b.date.localeCompare(a.date)),
    }
  })
  .withRender(({ props, lifecycle }) => (
    <UI.Stack>
      <DateSelector {...props} type="Retours" />

      {lifecycle.feedback.map((feedback) => (
        <FeedbackEntry
          key={feedback.date}
          user={feedback.user}
          date={feedback.date}
          message={feedback.message}
          category={feedback.category}
        />
      ))}

      {lifecycle.feedback.length === 0 && (
        <UI.Text mt={128} align="center" weight="bold" color="dimmed">
          Aucun retour
        </UI.Text>
      )}
    </UI.Stack>
  ))
