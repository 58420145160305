import React from 'react'
import { RouteComponentProps } from 'wouter'

import { buildComponent } from '../../../components/factory'

import * as UI from '@mantine/core'
import * as Elements from '../../../components/elements'
import * as Components from './components'

export const ScreenConnectedAudiosBackground = buildComponent<
  RouteComponentProps<{ background: string }>
>().withRender(({ props }) => (
  <React.Fragment>
    <Components.Title background={props.params.background} />

    <UI.Stack sx={{ maxWidth: 1280, margin: '2rem auto' }} spacing={32}>
      <UI.Card shadow="sm" pb={32} px={24}>
        <Components.SectionMain background={props.params.background} />
      </UI.Card>

      <UI.Card shadow="sm" pb={32} px={24}>
        <Components.SectionTracks background={props.params.background} />
      </UI.Card>

      <UI.Card shadow="sm" pb={32} px={24}>
        <Components.SectionLegacyImages background={props.params.background} />
      </UI.Card>

      <UI.Group position="center">
        <Elements.AudioDelete
          kind="background"
          slug={props.params.background}
        />
      </UI.Group>
    </UI.Stack>
  </React.Fragment>
))
