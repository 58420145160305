import React, { useEffect, useState } from 'react'
import {
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
  format,
} from 'date-fns'
import localeFr from 'date-fns/locale/fr'

import { SpaceCocoons, SpaceInfo } from '../../../../state/models/space'
import { graphql } from '../../../../services/graphql'

import { buildComponent } from '../../../../components/factory'

import { useSessionBoolean } from '../../../../hooks/use-session-value'
import { useStoreState } from '../../../../hooks/state'

import * as UI from '@mantine/core'

import {
  EventAudioPlay,
  EventNap,
  EventNoSlotWarning,
  EventRating,
  eventsCache,
  EventUserSpace,
  loadUserSpace,
  napCache,
  ratingsCache,
} from './data'

import { Title, CategoryTitle, StatTitle, PrintTitle } from './titles'
import { DateSelector } from './date-selector'
import { PrintButton, PrintPage } from './print'
import { SendRecapButton } from './send-recap'
import { UsageStats } from './usage-stats'
import { UsersStats } from './users-stats'
import {
  AudioKindRepartitionPie,
  AudioMainRepartitionBars,
  AudioBackgroundRepartitionBars,
  AudioPlayByPeriodBars,
  AudioKindDurationRepartitionPie,
} from './graphs-audio'
import {
  BookingSlotsBars,
  BookingByPeriodBars,
  BookingDurationPie,
  BookingByCocoonPie,
  BookingByRoomPie,
} from './graphs-booking'
import { WarningSlotsBars } from './graphs-warnings'
import { useDebouncedValue } from '@mantine/hooks'

const minWait = async <T extends unknown>(promise: Promise<T>): Promise<T> => {
  return await new Promise<void>((resolve) => {
    setTimeout(() => resolve(), 500)
  }).then(() => promise)
}
const delay = async () => {
  return await new Promise<void>((resolve) => {
    setTimeout(() => resolve(), 500)
  })
}

export const Summary = buildComponent<{
  date: Date
  setDate: (next: Date) => void
}>()
  .withLifecycle(({ props }) => {
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(0)

    const [napsUserSpaceLoaded, setNapsUserSpaceLoaded] = useState(false)
    const [eventsLoaded, setEventsLoaded] = useState(false)
    const [ratingsLoaded, setRatingsLoaded] = useState(false)
    const [spaceDetailsLoaded, setSpaceDetailsLoaded] = useState(false)

    const space = useStoreState((store) => store.statistics.space)
    const [annual] = useSessionBoolean('StatisticsAnnual')
    const [debouncedAnnual] = useDebouncedValue(annual, 500)
    const year = props.date.getFullYear()
    const month = year + '-' + (props.date.getMonth() + 1)

    const themes = useStoreState((store) => store.audio.themes)
    const books = useStoreState((store) => store.audio.books)
    const backgrounds = useStoreState((store) => store.audio.backgrounds)

    const [spaceDetails, setSpaceDetails] = useState<SpaceCocoons | null>(null)
    const [spaceInfo, setSpaceInfo] = useState<SpaceInfo | null>(null)
    const [_naps, setNaps] = useState<EventNap[]>([])
    const [_userSpace, setUserSpace] = useState<EventUserSpace[]>([])
    const [_ratings, setRatings] = useState<EventRating[]>([])
    const [_eventsAudioPlay, setEventsAudioPlay] = useState<EventAudioPlay[]>(
      [],
    )
    const [_eventsNoSlotWarnings, setEventsNoSlotWarnings] = useState<
      EventNoSlotWarning[]
    >([])

    const filterBySpace = (elem: { space: string }) =>
      !space || elem.space === space
    const naps = _naps.filter(filterBySpace)
    const userSpace = _userSpace.filter(filterBySpace)
    const ratings = _ratings.filter(filterBySpace)
    const legacyRatings = naps
      .filter((nap) => nap.evaluation)
      .map((nap) => nap.evaluation?.rating as number)
    const eventsAudioPlay = _eventsAudioPlay.filter(filterBySpace)
    const eventsNoSlotWarnings = _eventsNoSlotWarnings.filter(filterBySpace)
    const cocoons = spaceDetails?.cocoons ?? []
    const rooms = spaceDetails?.rooms ?? []

    const title =
      'Statistiques ' +
      (annual ? 'année' : format(props.date, 'MMMM', { locale: localeFr })) +
      ' ' +
      year

    useEffect(() => {
      setError(false)
    }, [props.date, annual, space])

    // Load naps & userSpace
    useEffect(() => {
      setLoading((l) => l + 2)
      setNapsUserSpaceLoaded(false)
      const lte = annual ? endOfYear(props.date) : endOfMonth(props.date)

      const napPromise = annual
        ? napCache.loadYear(year)
        : napCache.loadMonth(month)

      Promise.all([
        minWait(napPromise)
          .then(setNaps)
          .catch(() => setError(true))
          .then(delay)
          .finally(() => setLoading((l) => l - 1)),
        minWait(loadUserSpace(lte))
          .then(setUserSpace)
          .catch(() => setError(true))
          .then(delay)
          .finally(() => setLoading((l) => l - 1)),
      ]).finally(() => setNapsUserSpaceLoaded(true))
    }, [props.date, annual, year, month])

    // Load events
    useEffect(() => {
      setLoading((l) => l + 1)
      setEventsLoaded(false)

      const promise = annual
        ? eventsCache.loadYear(year)
        : eventsCache.loadMonth(month)
      minWait(promise)
        .then((events) => {
          setEventsAudioPlay(events.audioPlay)
          setEventsNoSlotWarnings(events.noSlotWarnings)
        })
        .catch(() => setError(true))
        .then(delay)
        .finally(() => {
          setLoading((l) => l - 1)
          setEventsLoaded(true)
        })
    }, [month, year, annual])

    // Load ratings
    useEffect(() => {
      setLoading((l) => l + 1)
      setRatingsLoaded(false)

      const promise = annual
        ? ratingsCache.loadYear(year)
        : ratingsCache.loadMonth(month)
      minWait(promise)
        .then(setRatings)
        .catch(() => setError(true))
        .then(delay)
        .finally(() => {
          setLoading((l) => l - 1)
          setRatingsLoaded(true)
        })
    }, [month, year, annual])

    // Load space details
    useEffect(() => {
      setSpaceDetails(null)
      setSpaceInfo(null)
      setSpaceDetailsLoaded(false)

      if (space) {
        setLoading((l) => l + 2)
        Promise.all([
          graphql.queries
            .getSpaceCocoons(space)
            .then(setSpaceDetails)
            .catch(() => setError(true))
            .then(delay)
            .finally(() => setLoading((l) => l - 1)),
          graphql.queries
            .getSpaceInfo(space)
            .then(setSpaceInfo)
            .catch(() => setError(true))
            .then(delay)
            .finally(() => setLoading((l) => l - 1)),
        ]).finally(() => setSpaceDetailsLoaded(true))
      } else {
        setSpaceDetailsLoaded(true)
      }
    }, [space])

    useEffect(() => {
      if (
        napsUserSpaceLoaded &&
        eventsLoaded &&
        ratingsLoaded &&
        spaceDetailsLoaded
      ) {
        // add class .loaded to body
        document.body.classList.add('loaded')
      } else {
        // remove class .loaded from body
        document.body.classList.remove('loaded')
      }
    }, [napsUserSpaceLoaded, eventsLoaded, ratingsLoaded, spaceDetailsLoaded])

    return {
      title,
      spaceDetails,
      annual,
      debouncedAnnual,
      loading,
      error,
      naps,
      userSpace,
      ratings,
      legacyRatings,
      eventsAudioPlay,
      eventsNoSlotWarnings,
      space,
      date: annual ? startOfYear(props.date) : startOfMonth(props.date),
      endDate: annual ? endOfYear(props.date) : endOfMonth(props.date),
      themes,
      books,
      backgrounds,
      cocoons,
      rooms,
      spaceInfo,
    }
  })
  .withRender(({ props, lifecycle }) => (
    <UI.Stack>
      <UI.Stack
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <DateSelector {...props} type="Statistiques" />
        {lifecycle.loading > 0 && <UI.Loader size="sm" />}
        {lifecycle.error && (
          <UI.Text weight="700" color="pink">
            Une erreur est survenue, merci de réessayer
          </UI.Text>
        )}
        <UI.Group position="apart">
          <SendRecapButton {...lifecycle} />
          <PrintButton {...lifecycle} />
        </UI.Group>
      </UI.Stack>

      <UI.Stack
        style={{ position: 'relative' }}
        spacing={0}
        id="print-container"
      >
        <UI.LoadingOverlay
          zIndex={1}
          visible={lifecycle.loading > 0 || lifecycle.error}
          overlayColor={lifecycle.error ? 'rgba(255, 0, 0, 0.5)' : 'white'}
        />
        <PrintPage>
          {!lifecycle.loading && (
            <>
              <PrintTitle title={lifecycle.title} />
              <UsageStats
                annual={lifecycle.debouncedAnnual}
                eventsAudioPlay={lifecycle.eventsAudioPlay}
                naps={lifecycle.naps}
                ratings={lifecycle.ratings}
                legacyRatings={lifecycle.legacyRatings}
              />
              <UsersStats
                annual={lifecycle.debouncedAnnual}
                eventsAudioPlay={lifecycle.eventsAudioPlay}
                eventsNoSlotWarnings={lifecycle.eventsNoSlotWarnings}
                naps={lifecycle.naps}
                userSpace={lifecycle.userSpace}
                date={lifecycle.date}
                endDate={lifecycle.endDate}
              />
              {!lifecycle.spaceInfo?.subscription?.disabled?.booking && (
                <UI.Stack>
                  <Title>Réservations</Title>
                  <StatTitle>
                    Siestes par {lifecycle.debouncedAnnual ? 'mois' : 'jour'}
                  </StatTitle>
                  <BookingByPeriodBars
                    annual={lifecycle.debouncedAnnual}
                    naps={lifecycle.naps}
                  />
                  <UI.Stack spacing={0} mt={-30}>
                    <StatTitle>Durées de siestes les plus réservées</StatTitle>
                    <BookingDurationPie naps={lifecycle.naps} />
                  </UI.Stack>
                </UI.Stack>
              )}
              {!lifecycle.spaceInfo?.subscription?.disabled?.booking && (
                <>
                  <UI.Stack
                    spacing={0}
                    sx={{
                      pageBreakInside: 'avoid',
                      overflow: 'hidden',
                    }}
                  >
                    <StatTitle>Répartition par créneau</StatTitle>
                    <BookingSlotsBars naps={lifecycle.naps} />
                  </UI.Stack>
                  {lifecycle.space && lifecycle.cocoons.length > 1 && (
                    <UI.Group noWrap spacing={0} sx={{ flex: 1 }}>
                      {lifecycle.cocoons.length !== lifecycle.rooms.length && (
                        <UI.Stack
                          sx={{
                            flex: 1,
                            width: '50%',
                            pageBreakInside: 'avoid',
                          }}
                        >
                          <StatTitle>Répartition par cocon</StatTitle>
                          <BookingByCocoonPie
                            naps={lifecycle.naps}
                            cocoons={lifecycle.cocoons}
                          />
                        </UI.Stack>
                      )}
                      {lifecycle.rooms.length > 1 && (
                        <UI.Stack
                          sx={{
                            flex: 1,
                            width: '50%',
                            pageBreakInside: 'avoid',
                          }}
                        >
                          <StatTitle>Répartition par salle</StatTitle>
                          <BookingByRoomPie
                            naps={lifecycle.naps}
                            rooms={lifecycle.rooms}
                          />
                        </UI.Stack>
                      )}
                    </UI.Group>
                  )}
                </>
              )}
              <Title>Audios</Title>
              <UI.Group>
                <UI.Stack
                  sx={{
                    flex: 1,
                    width: '100%',
                    pageBreakInside: 'avoid',
                  }}
                >
                  <StatTitle>Types d'audios écoutés</StatTitle>
                  <AudioKindRepartitionPie
                    eventsAudioPlay={lifecycle.eventsAudioPlay}
                  />
                </UI.Stack>
              </UI.Group>
              <UI.Group>
                <UI.Stack
                  sx={{ flex: 1, width: '100%', pageBreakInside: 'avoid' }}
                >
                  <StatTitle>Durées d'audios écoutées</StatTitle>
                  <AudioKindDurationRepartitionPie
                    eventsAudioPlay={lifecycle.eventsAudioPlay}
                  />
                </UI.Stack>
              </UI.Group>
              <UI.Box
                style={{ flex: 2, width: '100%', height: '100%' }}
                mb={-30}
              >
                <UI.Stack spacing={0} sx={{ pageBreakInside: 'avoid' }}>
                  <StatTitle>
                    Écoutes d'audios par{' '}
                    {lifecycle.debouncedAnnual ? 'mois' : 'jour'}
                  </StatTitle>
                  <AudioPlayByPeriodBars
                    annual={lifecycle.debouncedAnnual}
                    eventsAudioPlay={lifecycle.eventsAudioPlay}
                  />
                </UI.Stack>
              </UI.Box>
              <UI.Group noWrap spacing={0} sx={{ flex: 1 }}>
                <UI.Stack
                  sx={{ flex: 1, width: '50%', pageBreakInside: 'avoid' }}
                >
                  <CategoryTitle>Sophrologie</CategoryTitle>
                  <StatTitle>Thématiques écoutées</StatTitle>
                  <AudioMainRepartitionBars
                    kind="sophrology"
                    eventsAudioPlay={lifecycle.eventsAudioPlay}
                    themes={lifecycle.themes}
                  />
                </UI.Stack>
                <UI.Stack
                  sx={{ flex: 1, width: '50%', pageBreakInside: 'avoid' }}
                >
                  <CategoryTitle>Livres audios</CategoryTitle>
                  <StatTitle>Livres audios écoutées</StatTitle>
                  <AudioMainRepartitionBars
                    kind="audiobooks"
                    eventsAudioPlay={lifecycle.eventsAudioPlay}
                    books={lifecycle.books}
                  />
                </UI.Stack>
              </UI.Group>
              <CategoryTitle>Ambiances sonores</CategoryTitle>
              <UI.Stack spacing={0}>
                <UI.Stack
                  mt={-16}
                  sx={{
                    flex: 1,
                    width: '100%',
                    height: '100%',
                    pageBreakInside: 'avoid',
                    pageBreakBefore: 'avoid',
                  }}
                >
                  <StatTitle>Ambiances écoutées</StatTitle>
                  <AudioBackgroundRepartitionBars
                    kind="soundscapes"
                    backgrounds={lifecycle.backgrounds}
                    eventsAudioPlay={lifecycle.eventsAudioPlay}
                  />
                </UI.Stack>
              </UI.Stack>

              {!lifecycle.space && (
                <UI.Stack sx={{ pageBreakInside: 'avoid' }}>
                  <Title>Signalements de créneaux saturés</Title>

                  <StatTitle>Signalements par créneau</StatTitle>
                  <WarningSlotsBars
                    eventsNoSlotWarnings={lifecycle.eventsNoSlotWarnings}
                  />
                </UI.Stack>
              )}

              {lifecycle.space && lifecycle.eventsNoSlotWarnings.length > 0 && (
                <UI.Stack sx={{ pageBreakInside: 'avoid' }}>
                  <Title>Signalements de créneaux saturés</Title>
                  <StatTitle>Signalements par créneau</StatTitle>
                  <WarningSlotsBars
                    eventsNoSlotWarnings={lifecycle.eventsNoSlotWarnings}
                  />
                </UI.Stack>
              )}
            </>
          )}
        </PrintPage>
        {lifecycle.loading > 0 && <UI.Box sx={{ height: '150vh' }} />}
      </UI.Stack>
    </UI.Stack>
  ))
