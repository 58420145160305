import React from 'react'
import { RouteComponentProps } from 'wouter'

import { buildComponent } from '../../../components/factory'

import * as UI from '@mantine/core'
import * as Elements from '../../../components/elements'
import * as Components from './components'

export const ScreenConnectedAudiosTheme = buildComponent<
  RouteComponentProps<{ theme: string }>
>().withRender(({ props }) => (
  <React.Fragment>
    <Components.Title theme={props.params.theme} />

    <UI.Stack sx={{ maxWidth: 1280, margin: '2rem auto 32rem' }} spacing={32}>
      <UI.Card shadow="sm" pb={32} px={24} sx={{ position: 'relative' }}>
        <Components.SectionMain theme={props.params.theme} />
      </UI.Card>

      <UI.Card shadow="sm" pb={32} px={24} sx={{ position: 'relative' }}>
        <Components.SectionImages theme={props.params.theme} />
      </UI.Card>

      <UI.Card shadow="sm" pb={32} px={24} sx={{ position: 'relative' }}>
        <Components.SectionTracks theme={props.params.theme} />
      </UI.Card>

      <UI.Card shadow="sm" pb={32} px={24} sx={{ position: 'relative' }}>
        <Components.SectionLegacyImages theme={props.params.theme} />
      </UI.Card>

      <UI.Group position="center">
        <Elements.AudioDelete kind="theme" slug={props.params.theme} />
      </UI.Group>
    </UI.Stack>
  </React.Fragment>
))
