import React from 'react'

import { AudioBackground } from '../../../../state/models/audio'
import { graphql } from '../../../../services/graphql'

import { useSynchronizeData } from '../../../../hooks/use-synchronize-data'

import { buildComponent } from '../../../../components/factory'

import * as UI from '@mantine/core'
import * as Elements from '../../../../components/elements'

const SelectTracks = buildComponent<Partial<AudioBackground>>().withRender(
  ({ props }) => (
    <UI.Group position="center">
      {([10, 15, 20, 25, 30] as const).map((duration) => {
        return (
          <Elements.AudioButton
            key={duration}
            kind="backgrounds"
            duration={duration}
            slug={props.slug}
            entity={props._id}
            track={props.tracks?.[duration]}
          />
        )
      })}
    </UI.Group>
  ),
)

export const SectionTracks = buildComponent<{ background: string }>()
  .withLifecycle(({ props }) => {
    return useSynchronizeData(
      graphql.queries.getAudioBackground,
      graphql.mutations.updateAudioBackground,
      props.background,
    )
  })
  .withRender(({ lifecycle }) => (
    <UI.Input.Wrapper
      sx={{ flex: 1 }}
      label={<b style={{ fontSize: '2rem' }}>Fichiers audios</b>}
      description="Un fichier pour chaque durée, sans gong"
    >
      <UI.Stack mt={32} sx={{ flex: 1 }}>
        <SelectTracks {...lifecycle.data} />
      </UI.Stack>

      {lifecycle.loading && (
        <UI.Loader
          sx={{ position: 'absolute', top: '1rem', right: '1rem' }}
          size="xs"
        />
      )}
    </UI.Input.Wrapper>
  ))
