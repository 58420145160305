import React, { useEffect, useRef, useState } from 'react'

import { graphql } from '../../services/graphql'
import { storage } from '../../services/firebase'
import { serviceModals } from '../../services/modals'

import { buildComponent } from '../factory'

import * as UI from '@mantine/core'
import * as Icons from '../icons'

const rotate = UI.keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '50%': { transform: 'rotate(180deg)' },
  '100%': { transform: 'rotate(360deg)' },
})
const useStyles = UI.createStyles({
  rotate: {
    animation: `${rotate} 2s linear infinite`,
  },
})

export const AudioButton = buildComponent<{
  kind: 'sophrology' | 'audiobooks' | 'backgrounds'
  slug?: string
  entity?: string
  duration: 10 | 15 | 20 | 25 | 30
  track?: number
  language?: string
  chapter?: number
  update?: (next: { _id?: string }) => void
}>()
  .withLifecycle(({ props }) => {
    const languagePath =
      props.language !== undefined ? '/' + props.language : null
    const chapterPath = props.chapter !== undefined ? '/' + props.chapter : null
    const directoryPath =
      'tracks/' +
      props.kind +
      '/' +
      (props.slug ?? 'unknown') +
      (languagePath ?? chapterPath ?? '')
    const trackName = props.duration
    const trackExtension = '.m4a'
    const track = directoryPath + '/' + trackName + trackExtension

    const resetRef = useRef<() => void>(null)
    const [exists, setExists] = useState(false)
    const [saving, setSaving] = useState(false)

    useEffect(() => {
      let active = true
      storage()
        .ref(track)
        .getMetadata()
        .then(() => active && setExists(true))
        .catch(() => active && setExists(false))

      return () => {
        active = false
      }
    }, [track, props.track])

    const updateCacheNumber = async () => {
      if (!props.entity) {
        return
      }

      if (props.kind === 'sophrology') {
        await graphql.mutations.uploadAudioTheme({
          duration: (props.duration - 2) * 60,
          part: props.duration,
          id: props.entity,
          language: props.language,
        })
      }

      if (props.kind === 'audiobooks') {
        await graphql.mutations.uploadAudioBook({
          duration: (props.duration - 2) * 60,
          part: props.duration,
          id: props.entity,
          chapter: props.chapter,
        })
      }

      if (props.kind === 'backgrounds') {
        await graphql.mutations.uploadAudioBackground({
          duration: props.duration,
          id: props.entity,
        })
      }
    }
    const removeCache = async () => {
      if (!props.entity) {
        return
      }

      if (props.kind === 'sophrology') {
        await graphql.mutations.uncacheAudioTheme({
          duration: (props.duration - 2) * 60,
          part: props.duration,
          id: props.entity,
          language: props.language,
        })
      }

      if (props.kind === 'audiobooks') {
        await graphql.mutations.uncacheAudioBook({
          duration: (props.duration - 2) * 60,
          part: props.duration,
          id: props.entity,
          chapter: props.chapter,
        })
      }

      if (props.kind === 'backgrounds') {
        await graphql.mutations.uncacheAudioBackground({
          duration: props.duration,
          id: props.entity,
        })
      }
    }

    const onChange = async (file: File | null) => {
      if (!file) {
        return
      }

      setSaving(true)
      const saved = await storage()
        .ref(track)
        .put(file)
        .catch(() => false)
        .then(() => true)
      await updateCacheNumber().catch(() => {})
      resetRef.current?.()
      props.update?.({ _id: props.entity })
      setExists(saved)
      setSaving(false)
    }

    const onDelete = async (e: { stopPropagation: () => void }) => {
      e.stopPropagation()

      await serviceModals.confirmDeletion(
        'Supprimer une piste audio',
        'Souhaitez-vous vraiment supprimer cette piste audio ?',
      )

      setSaving(true)
      await storage()
        .ref(track)
        .delete()
        .catch(() => {})
      await removeCache().catch(() => {})
      resetRef.current?.()
      props.update?.({ _id: props.entity })
      setExists(false)
      setSaving(false)
    }

    const color = saving ? 'blue' : exists ? 'teal' : 'gray'
    const icon = saving
      ? Icons.Solid.faSpinner
      : exists
      ? Icons.Solid.faCheck
      : Icons.Solid.faUpload

    return { exists, saving, color, icon, onChange, onDelete, resetRef }
  })
  .withRender(({ props, lifecycle }) => {
    const styles = useStyles()

    return (
      <UI.FileButton
        resetRef={lifecycle.resetRef}
        onChange={lifecycle.onChange}
        accept={'audio/x-m4a'}
      >
        {(p) => (
          <UI.UnstyledButton {...p}>
            <UI.Badge
              radius="sm"
              variant="outline"
              color={lifecycle.color}
              styles={(theme) => ({
                root: {
                  position: 'relative',
                  height: '3rem',
                  backgroundColor: theme.fn.rgba(
                    theme.colors[lifecycle.color][0],
                    0.5,
                  ),
                  cursor: 'pointer',
                },
                inner: {
                  width: props.chapter !== undefined ? '11rem' : '16rem',
                  textAlign: 'center',
                },
              })}
              rightSection={
                <Icons.FontAwesomeIcon
                  icon={lifecycle.icon}
                  className={styles.cx({
                    [styles.classes.rotate]: lifecycle.saving,
                  })}
                />
              }
            >
              {lifecycle.exists && (
                <UI.Badge
                  color="gray"
                  radius="sm"
                  variant="outline"
                  onClick={lifecycle.onDelete}
                  styles={(theme) => ({
                    root: {
                      opacity: '0',
                      left: '0.5rem',
                      position: 'absolute',
                      height: '2rem',
                      width: '3rem',
                      padding: 0,
                      backgroundColor: theme.fn.rgba(theme.colors.gray[0], 0.5),
                      cursor: 'pointer',
                      '*:hover > &': {
                        opacity: 1,
                      },
                      '&:hover': {
                        color: theme.colors.red[5],
                        backgroundColor: theme.fn.rgba(
                          theme.colors.red[0],
                          0.5,
                        ),
                      },
                    },
                  })}
                >
                  <Icons.FontAwesomeIcon icon={Icons.Solid.faTrash} />
                </UI.Badge>
              )}
              {props.duration} {props.chapter !== undefined ? 'min' : 'minutes'}
            </UI.Badge>
          </UI.UnstyledButton>
        )}
      </UI.FileButton>
    )
  })
