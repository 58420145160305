import React from 'react'

import napCocoonV1 from './webps/nap-cocoon-v1.webp'
import napCocoonV2 from './webps/nap-cocoon-v2.webp'
import napCocoonV3 from './webps/nap-cocoon-v3.webp'
import napCocoonV4 from './webps/nap-cocoon-v4.webp'
import napRoom from './webps/nap-room.webp'

import { buildComponent } from '../../components/factory'

const buildIcon = (source: string) =>
  buildComponent<React.ImgHTMLAttributes<HTMLImageElement>>().withRender(
    ({ props }) => (
      <img
        style={{
          ...props.style,
          width: props.width,
          height: props.height,
          aspectRatio: '1/1',
          objectFit: 'contain',
        }}
        alt="icon"
        src={source}
      />
    ),
  )

export { NapClock } from './nap-clock'
export const NapCocoonV1 = buildIcon(napCocoonV1)
export const NapCocoonV2 = buildIcon(napCocoonV2)
export const NapCocoonV3 = buildIcon(napCocoonV3)
export const NapCocoonV4 = buildIcon(napCocoonV4)
export const NapRoom = buildIcon(napRoom)
