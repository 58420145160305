import { thunk, computed } from 'easy-peasy'
import base64 from 'base-64'

import { Set, Thunk, Computed } from '../types'
import { set } from '../utils'

interface SessionUtils {
  // State
  token: string | null
  active: boolean
  claims: Computed<this, { _id: string; space: string; admin: boolean }>

  // Actions
  setToken: Set<this, 'token'>
  setActive: Set<this, 'active'>

  // Thunk
  connectWithToken: Thunk<this, string>
  disconnect: Thunk<this>
  restore: Thunk<this, never, this['claims']['result']>
}

export interface ModelUtils {
  session: SessionUtils
}

export const modelUtils: ModelUtils = {
  session: {
    // State
    token: null,
    active: true, // Defaults to true to avoid showing the "change password" to recurring admins
    claims: computed((state) => {
      const decoded = JSON.parse(
        base64.decode(state.token?.split('.')[1] ?? '') || '{}',
      )
      return decoded || { _id: '', space: '', admin: false }
    }),

    // Actions
    setToken: set('token'),
    setActive: set('active'),

    // Thunks
    connectWithToken: thunk((actions, token) => {
      localStorage.setItem('@token', token)
      actions.setToken(token)
    }),
    disconnect: thunk((actions) => {
      localStorage.removeItem('@token')
      actions.setToken(null)
    }),
    restore: thunk((actions, _, helpers) => {
      let token = localStorage.getItem('@token')

      // if there is a token in url, we should use it
      const urlParams = new URLSearchParams(window.location.search)
      const tokenFromUrl = urlParams.get('token')
      if (tokenFromUrl) {
        token = tokenFromUrl
      }

      if (token) {
        actions.setToken(token)
      }

      return helpers.getState().claims
    }),
  },
}
