import React, { useEffect } from 'react'

import * as Routing from 'wouter'
import * as UI from '@mantine/core'
import * as Modals from '@mantine/modals'

import { buildComponent } from '../components/factory'
import { useConnectionState } from '../hooks/use-connection-state'

import { TopBar } from './top-bar'
import { Datasources } from './datasources'
import { SpaceList } from './space-list'
import { ChangePassword } from './change-password'

import { ScreenDisconnectedLogin } from '../screens/disconnected/login'
import { ScreenConnectedSpaces } from '../screens/connected/spaces/spaces'
import { ScreenConnectedSpacesEdit } from '../screens/connected/spaces-edit/spaces-edit'
import { ScreenConnectedAudios } from '../screens/connected/audios/audios'
import { ScreenConnectedAudiosTheme } from '../screens/connected/audios-theme/audios-theme'
import { ScreenConnectedAudiosBook } from '../screens/connected/audios-book/audios-book'
import { ScreenConnectedAudiosBackground } from '../screens/connected/audios-background/audios-background'
import { ScreenConnectedStatistics } from '../screens/connected/statistics/statistics'
import { ScreenConnectedUsers } from '../screens/connected/users/users'
import { ScreenConnectedContent } from '../screens/connected/content/content'

export const Router = buildComponent()
  .withLifecycle(() => {
    const { isConnected, loading, active } = useConnectionState()

    useEffect(() => {
      if (!active) {
        Modals.openModal({
          title: <UI.Text weight="bold">Changer votre mot de passe</UI.Text>,
          children: <ChangePassword />,
          padding: 'lg',
        })
      }
    }, [active])

    return { isConnected, loading }
  })
  .withRender(({ lifecycle }) => {
    if (lifecycle.loading) {
      return null
    }

    if (!lifecycle.isConnected) {
      return <ScreenDisconnectedLogin />
    }

    return (
      <UI.AppShell
        header={<TopBar />}
        navbar={
          <Routing.Switch>
            <Routing.Route key="stats" path="/statistics/:rest*">
              <SpaceList kind="statistics" />
            </Routing.Route>
            <Routing.Route key="users" path="/users/:rest*">
              <SpaceList kind="user" />
            </Routing.Route>
          </Routing.Switch>
        }
      >
        <Datasources />

        <Routing.Switch>
          <Routing.Route path="/spaces" component={ScreenConnectedSpaces} />
          <Routing.Route
            path="/spaces/edit/:space"
            component={ScreenConnectedSpacesEdit}
          />
          <Routing.Route path="/audios" component={ScreenConnectedAudios} />
          <Routing.Route
            path="/audios/theme/:theme"
            component={ScreenConnectedAudiosTheme}
          />
          <Routing.Route
            path="/audios/book/:book"
            component={ScreenConnectedAudiosBook}
          />
          <Routing.Route
            path="/audios/background/:background"
            component={ScreenConnectedAudiosBackground}
          />
          <Routing.Route
            path="/statistics/:space/:annual"
            component={ScreenConnectedStatistics}
          />
          <Routing.Route
            path="/statistics/:space/:annual/:month"
            component={ScreenConnectedStatistics}
          />
          <Routing.Route
            path="/statistics"
            component={ScreenConnectedStatistics}
          />
          <Routing.Route path="/users" component={ScreenConnectedUsers} />
          <Routing.Route path="/content" component={ScreenConnectedContent} />
          <Routing.Redirect to="/spaces" />
        </Routing.Switch>
      </UI.AppShell>
    )
  })
