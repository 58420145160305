import { useLayoutEffect, useState } from 'react'

const listeners: Record<string, ((nextValue: string) => void)[]> = {}

export function useSessionValue<T extends string>(
  key: string,
  defaultValue: T,
): [T, (nextValue: T) => void] {
  const [value, setValue] = useState(
    sessionStorage.getItem(key) ?? defaultValue,
  )

  const updateValue = (nextValue: string) => {
    sessionStorage.setItem(key, nextValue)
    listeners[key]?.forEach((listener) => listener(nextValue))
  }

  useLayoutEffect(() => {
    setValue(sessionStorage.getItem(key) ?? defaultValue)

    listeners[key] = listeners[key] || []
    listeners[key].push(setValue)

    return () => {
      listeners[key].splice(listeners[key].indexOf(setValue), 1)
    }
  }, [key, defaultValue])

  return [value as T, updateValue]
}

export function useSessionBoolean(
  key: string,
): [boolean, (nextValue: boolean) => void] {
  const [value, updateValue] = useSessionValue<'true' | 'false'>(key, 'false')

  return [
    value === 'true',
    (nextValue: boolean) => updateValue(nextValue ? 'true' : 'false'),
  ]
}
