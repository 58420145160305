import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from 'wouter'
import startOfDay from 'date-fns/startOfDay'

import { buildComponent } from '../../../components/factory'

import { Ratings } from './components/ratings'
import { Planning } from './components/planning'
import { Summary } from './components/summary'
import { Feedback } from './components/feedback'

import * as UI from '@mantine/core'
import { useStoreActions } from '../../../hooks/state'
import { useSessionBoolean } from '../../../hooks/use-session-value'

export const ScreenConnectedStatistics = buildComponent<
  RouteComponentProps<{ space?: string; annual?: string; month?: string }>
>()
  .withLifecycle((routeProps) => {
    const [tab, setTab] = useState<
      'summary' | 'ratings' | 'planning' | 'feedback'
    >('summary')
    const setSelected = useStoreActions((store) => store.statistics.setSpace)
    const [, setAnnual] = useSessionBoolean('StatisticsAnnual')

    const dateFromRoute = useMemo(() => {
      if (routeProps.props.params.space && routeProps.props.params.annual) {
        const annual = parseInt(routeProps.props.params.annual, 10)
        const month = routeProps.props.params.month
          ? parseInt(routeProps.props.params.month, 10)
          : new Date().getMonth()

        return new Date(annual, month)
      }
    }, [
      routeProps.props.params.space,
      routeProps.props.params.annual,
      routeProps.props.params.month,
    ])

    useEffect(() => {
      if (routeProps.props.params.space) {
        document.body.classList.toggle('printmode', true)
        setSelected(routeProps.props.params.space)
      }

      if (routeProps.props.params.month) {
        setAnnual(false)
      } else if (routeProps.props.params.annual) {
        setAnnual(true)
      }
    }, [
      routeProps.props.params.space,
      routeProps.props.params.annual,
      routeProps.props.params.month,
      setSelected,
      setAnnual,
    ])

    const [date, setDate] = useState(dateFromRoute || startOfDay(new Date()))

    useLayoutEffect(() => {
      const quitPrintMode = (e: {
        key?: string
        preventDefault: () => void
      }) => {
        if (!e.key || ['Escape', 'Enter'].includes(e.key)) {
          e.preventDefault()
          document.title = 'Nap&Up'
          document.body.classList.toggle('printmode', false)
        }
      }

      window.addEventListener('keyup', quitPrintMode)
      window.addEventListener('popstate', quitPrintMode)
      const printContainer = document.getElementById('print-container')

      const markAsPrint = (element: HTMLElement | null | undefined) => {
        if (!element) {
          return
        }

        element.classList.toggle('print', true)
        markAsPrint(element.parentElement)
      }

      markAsPrint(printContainer)

      return () => {
        window.removeEventListener('keyup', quitPrintMode)
        window.removeEventListener('popstate', quitPrintMode)
      }
    }, [routeProps.props.params, setSelected, tab])

    return { tab, setTab, date, setDate }
  })
  .withRender(({ lifecycle }) => (
    <React.Fragment>
      <UI.Stack
        mx={-16}
        px={16}
        mt={-16}
        py={16}
        sx={{
          position: 'sticky',
          top: 72,
          zIndex: 10,
          backgroundColor: '#f8f9fc',
        }}
        spacing={0}
      >
        <UI.Group position="apart">
          <UI.Stack spacing={0}>
            <UI.Title>
              <UI.Text size={22}>Statistiques</UI.Text>
            </UI.Title>
            <UI.Text color="dimmed">
              Statistiques mensuelles et anuelles des espaces clients.
            </UI.Text>
          </UI.Stack>
        </UI.Group>

        <UI.Group mx="auto" mt="lg" position="center" spacing={0}>
          <UI.SegmentedControl
            radius="xl"
            value={lifecycle.tab ?? undefined}
            onChange={(tab) => lifecycle.setTab(tab as 'summary')}
            data={[
              { label: 'Résumé', value: 'summary' },
              { label: 'Évaluations', value: 'ratings' },
              { label: 'Planning', value: 'planning' },
              { label: 'Retours', value: 'feedback' },
            ]}
            styles={(theme) => ({
              root: {
                backgroundColor: 'transparent',
              },
              active: {
                backgroundColor: theme.colors.blue[7],
              },
              label: {
                padding: '1rem 4rem',
              },
              labelActive: {
                color: 'white !important',
              },
            })}
          />
        </UI.Group>
      </UI.Stack>

      <UI.Card shadow="sm" mt={8} sx={{ minHeight: '50vh' }}>
        {lifecycle.tab === 'summary' && (
          <Summary date={lifecycle.date} setDate={lifecycle.setDate} />
        )}
        {lifecycle.tab === 'ratings' && (
          <Ratings date={lifecycle.date} setDate={lifecycle.setDate} />
        )}
        {lifecycle.tab === 'planning' && (
          <Planning date={lifecycle.date} setDate={lifecycle.setDate} />
        )}
        {lifecycle.tab === 'feedback' && (
          <Feedback date={lifecycle.date} setDate={lifecycle.setDate} />
        )}
      </UI.Card>
    </React.Fragment>
  ))
