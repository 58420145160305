import React, { useMemo } from 'react'

import { useIntersection } from '@mantine/hooks'
import { buildComponent } from '../../../../components/factory'

import * as UI from '@mantine/core'

import { UserRow } from './user-row'

interface TableSelectionProps {
  data: {
    _id: string
    email: string
    spaces: { _id: string; name: string }[]
  }[]
  toggleRow: (item: string) => void
  isSelected: (item: string) => boolean
}

function sliceArrayIntoPages<T>(arr: T[], size: number): T[][] {
  let step = 0
  const sliceArr: T[][] = []

  while (step < arr.length) {
    sliceArr.push(arr.slice(step, (step += size)))
  }

  return sliceArr
}

const Page = buildComponent<{
  page: TableSelectionProps['data']
  isSelected: (item: string) => boolean
  toggleRow: (item: string) => void
}>().withRender(({ props }) => {
  const { ref, entry } = useIntersection()
  const showContent = !!entry?.isIntersecting

  return (
    <div ref={ref} style={{ height: props.page.length * 6 + 'rem' }}>
      {showContent &&
        props.page.map((item) => {
          return (
            <UserRow
              key={item.email}
              {...item}
              isSelected={props.isSelected}
              toggleRow={props.toggleRow}
            />
          )
        })}
    </div>
  )
})

export const Table = buildComponent<TableSelectionProps>()
  .withLifecycle(({ props }) => {
    const pages = useMemo(
      () => sliceArrayIntoPages(props.data, 30),
      [props.data],
    )

    return { pages }
  })
  .withRender(({ props, lifecycle }) => {
    if (!props.data.length) {
      return (
        <UI.Group position="center" align="center" sx={{ height: '32rem' }}>
          <UI.Loader />
        </UI.Group>
      )
    }

    return (
      <>
        {lifecycle.pages.map((page) => (
          <Page
            key={page.map((u) => u.email).join('-')}
            page={page}
            toggleRow={props.toggleRow}
            isSelected={props.isSelected}
          />
        ))}
      </>
    )
  })
