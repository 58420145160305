import { createStore } from 'easy-peasy'

import { modelSpace } from './models/space'
import { modelAudio } from './models/audio'
import { modelDynamicContent } from './models/dynamic-content'
import { modelUtils } from './models/utils'
import { modelUser } from './models/users'
import { modelStatistics } from './models/statistics'

export const model = {
  space: modelSpace,
  audio: modelAudio,
  user: modelUser,
  statistics: modelStatistics,
  dynamicContent: modelDynamicContent,
  utils: modelUtils,
}

// Export our store
export const Store = createStore(model)
