import { MantineThemeOverride } from '@mantine/core'

export const colors = {
  blue: [
    '#f5fafe',
    '#e7f3fc',
    '#d8ebfa',
    '#cae4f8',
    '#bbddf6',
    '#6299e8',
    '#073475',
    '#073475',
    '#073475',
    '#073475',
  ],
  yellow: [
    '#fcecc0',
    '#fae199',
    '#f9d777',
    '#f6c63f',
    '#f6c230',
    '#f5be24',
    '#f5bb18',
    '#f4b70c',
    '#f4b70c',
    '#f4b70c',
  ],
  pink: [
    '#fff5ee',
    '#ffe9da',
    '#ffdbc2',
    '#ffc9a4',
    '#ffbb8b',
    '#ffad73',
    '#ff9e5a',
    '#ff8f41',
    '#ff8129',
  ],
  darkblue: [
    '#eff5fd',
    '#e4eefb',
    '#d3e2f9',
    '#c6daf7',
    '#a7c6f2',
    '#073475',
    '#073475',
    '#073475',
    '#073475',
    '#073475',
  ],
  dark: [
    '#dbe9fd',
    '#a4c7fa',
    '#6da5f6',
    '#3784f3',
    '#0e65e2',
    '#083c87',
    '#073475',
    '#062c63',
    '#052452',
    '#041d40',
  ],
  success: [
    'rgb(224, 249, 245)',
    'rgb(179, 241, 230)',
    'rgb(128, 232, 214)',
    'rgb(77, 223, 198)',
    'rgb(38, 216, 185)',
    'rgb(0,209,173)',
    'rgb(0, 204, 166)',
    'rgb(0, 198, 156)',
    'rgb(0, 192, 147)',
    'rgb(0, 181, 131)',
  ],
  danger: [
    'rgb(252, 234, 232)',
    'rgb(248, 202, 197)',
    'rgb(243, 166, 158)',
    'rgb(238, 130, 119)',
    'rgb(234, 104, 90)',
    'rgb(230, 77, 61)',
    'rgb(227, 70, 55)',
    'rgb(223, 61, 47)',
    'rgb(219, 52, 39)',
    'rgb(213, 37, 26)',
  ],
  warning: [
    'rgb(245, 242, 236)',
    'rgb(230, 223, 209)',
    'rgb(213, 202, 178)',
    'rgb(196, 180, 147)',
    'rgb(183, 164, 123)',
    'rgb(170, 148, 100)',
    'rgb(163, 140, 92)',
    'rgb(153, 129, 82)',
    'rgb(144, 119, 72)',
    'rgb(127, 101, 54)',
  ],
} as const

export const theme: MantineThemeOverride = {
  colors: colors as any,
  focusRing: 'never',
  datesLocale: 'fr',
  dateFormat: 'D/M/YYYY',
  components: {
    Button: {
      styles: (th, params) => {
        const index = params.color === 'gray' ? 3 : 9
        const text = params.color === 'gray' ? th.colors.gray[7] : 'white'
        const color = th.colors[params.color || th.primaryColor][index]
        const shadow = th.fn.darken(color, 0.1)

        return {
          root: {
            paddingLeft: '2rem',
            paddingRight: '2rem',
            minHeight: '6rem',
            borderRadius: '32rem',
            boxShadow: '0 5px 0 0 ' + shadow,
            backgroundColor: color,
            color: text,

            '&:hover': {
              backgroundColor: color,
            },

            '&:active': {
              transform: 'translateY(3px)',
              boxShadow: '0 2px 0 0 ' + shadow,
            },

            '&:disabled': {
              opacity: 0.5,
              backgroundColor: color,
              color: text,
            },
          },
        }
      },
    },
    Input: {
      defaultProps: {
        variant: 'filled',
        radius: 'xl',
        my: 8,
      },
      styles: (th, params) => {
        const base =
          params.variant === 'default' ? th.colors.gray[1] : th.colors.gray[0]
        return {
          input: {
            backgroundColor: base,
            minHeight: '5rem',
            borderWidth: 0,
            boxShadow: '0 3px 0 0 ' + th.fn.darken(base, 0.1),
            paddingLeft: '2rem',
            '&:focus': {
              outline: '0',
              borderColor: 'transparent !important',
            },
            '&:focus-within': {
              outline: '0',
              borderColor: 'transparent !important',
            },
          },
        }
      },
    },
    PasswordInput: {
      styles: {
        innerInput: {
          minHeight: '5rem',
          boxShadow: '0 3px 0 0 #DDDDDD',
          paddingLeft: '2rem',
          '&:focus': {
            outline: '0',
            border: '0',
          },
        },
      },
    },
    NumberInput: {
      styles: {
        rightSection: {
          width: '4rem',
        },
        control: {
          width: '4rem',
          paddingRight: '1rem',
        },
      },
    },
    MultiSelect: {
      styles: {
        wrapper: {
          overflow: 'visible !important',
          width: 480,
        },
        values: {
          height: 40,
        },
        value: {
          height: 28,
          lineHeight: '28px',
          border: '1px solid rgba(0, 0, 0, 0.1)',
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
      },
    },
    Select: {
      styles: {
        wrapper: {
          overflow: 'visible !important',
          width: 480,
        },
        values: {
          height: 40,
        },
        value: {
          height: 28,
          lineHeight: 28,
          border: '1px solid rgba(0, 0, 0, 0.1)',
        },
      },
    },
    Autocomplete: {
      styles: {
        wrapper: {
          overflow: 'visible !important',
          width: 480,
        },
        values: {
          height: 40,
        },
        value: {
          height: 28,
          lineHeight: 28,
          border: '1px solid rgba(0, 0, 0, 0.1)',
        },
      },
    },
    Badge: {
      styles: (th, params) => ({
        root: {
          color:
            params.color === 'yellow'
              ? th.colors.dark[9]
              : th.colors[params.color || th.primaryColor][8],
        },
      }),
    },
  },
}
