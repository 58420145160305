import { graphql } from '../../services/graphql'

import { useOnMount } from '../use-on-mount'
import { useStore } from '../state'

export function useDatasourceUser() {
  const Store = useStore()

  useOnMount(() => {
    return graphql.subscribe(['User'], () => {
      graphql.queries
        .getUsers()
        .then(({ userList }) =>
          Store.getActions().user.setList(
            userList.sort((a, b) => a.email.localeCompare(b.email)),
          ),
        )
    })
  })
}
