import React, { useState } from 'react'
import * as UI from '@mantine/core'
import * as Modals from '@mantine/modals'
import { useForm } from '@mantine/form'
import { useStore } from '../hooks/state'
import { graphql } from '../services/graphql'

import { buildComponent } from '../components/factory'

export const ChangePassword = buildComponent()
  .withLifecycle(() => {
    const Store = useStore()
    const [loading, setLoading] = useState(false)
    const form = useForm({
      initialValues: {
        password: '',
        confirm: '',
      },
      validate: {
        password: (value) =>
          value.length < 6 ? 'Au moins 6 caractères' : null,
        confirm: (value, values) =>
          value !== values.password
            ? 'Les mots de passes ne correspondent pas'
            : null,
      },
      validateInputOnBlur: true,
    })

    const handleSubmit = form.onSubmit(async (values) => {
      setLoading(true)
      await graphql.mutations.setupAdministrator(values.password)
      Store.getActions().utils.session.setActive(true)
      Modals.closeAllModals()
      setLoading(false)
    })

    return {
      loading,
      getInputProps: form.getInputProps,
      handleSubmit,
    }
  })
  .withRender(({ lifecycle }) => (
    <form onSubmit={lifecycle.handleSubmit}>
      <UI.Stack px={32} pb={16}>
        <UI.PasswordInput
          {...lifecycle.getInputProps('password')}
          label="Mot de passe"
          disabled={lifecycle.loading}
        />
        <UI.PasswordInput
          {...lifecycle.getInputProps('confirm')}
          label="Confirmer le mot de passe"
          disabled={lifecycle.loading}
        />

        <UI.Button
          type="submit"
          disabled={lifecycle.loading}
          loading={lifecycle.loading}
          mx={32}
        >
          Confirmer
        </UI.Button>
      </UI.Stack>
    </form>
  ))
