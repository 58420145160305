import { useEffect, useState } from 'react'

import { auth } from '../services/firebase'
import { graphql } from '../services/graphql'

import { useStore, useStoreState } from './state'
import { useOnMount } from './use-on-mount'

export function useConnectionState() {
  const Store = useStore()

  const claims = useStoreState((store) => store.utils.session.claims)
  const active = useStoreState((store) => store.utils.session.active)
  const [user, setUser] = useState(auth().currentUser)

  const [loading, setLoading] = useState(true)
  const [isConnected, setIsConnected] = useState(false)

  useOnMount(() => {
    return auth().onAuthStateChanged(setUser)
  })

  useEffect(() => {
    const checkSession = async () => {
      const updatedClaims = Store.getActions().utils.session.restore()

      // If a user is connected to firebase, but different from latest
      // token, sign them out
      if (user && user.uid !== updatedClaims._id) {
        await auth().signOut()
      }

      // If no user is connected to firebase, and there is a token,
      // connect them to firebase
      if (!user && updatedClaims._id) {
        const { administratorAuthenticated } = await graphql.query<{
          administratorAuthenticated: {
            firebaseToken: string
          } | null
        }>('{ administratorAuthenticated { firebaseToken } }')

        if (
          administratorAuthenticated &&
          administratorAuthenticated.firebaseToken
        ) {
          await auth().signInWithCustomToken(
            administratorAuthenticated.firebaseToken,
          )
        }
      }

      // If no user is connected at all, then we are in disconnected state
      if (!user && !updatedClaims._id) {
        setIsConnected(false)
        setLoading(false)
      }

      // If a user is connected to firebase with latest token, then we are
      // in connected state
      if (user && updatedClaims._id === user.uid) {
        setIsConnected(true)
        setLoading(false)
      }
    }

    checkSession().catch(console.error)
  }, [Store, user, claims])

  useEffect(() => {
    // If the user is not admin anymore, disconnect them
    if (user && !claims.admin) {
      Store.getActions().utils.session.disconnect()
    }
  }, [Store, user, claims.admin, claims])

  useEffect(() => {
    // When a user connect, check if their account is active
    if (user && claims.admin) {
      graphql.queries.getAdministratorInfo().then(({ administrator }) => {
        Store.getActions().utils.session.setActive(administrator.active)
      })
    }
  }, [Store, user, claims.admin])

  return {
    active,
    loading,
    isConnected,
  }
}
