import i18next from 'i18next'
import {
  PrefixedLocaleFunction,
  TranslationPrefix,
} from '../resources/locale/locale'

export function createLocaleHook<Prefix extends TranslationPrefix>(
  prefix: Prefix,
): () => PrefixedLocaleFunction<Prefix> {
  return () => {
    const locale = 'fr'

    return i18next.getFixedT(locale, undefined, prefix)
  }
}
