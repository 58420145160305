import React from 'react'

import { useStoreState } from '../../../../hooks/state'

import { buildComponent } from '../../../../components/factory'

import * as UI from '@mantine/core'
import * as Routing from 'wouter'
import * as Icons from '../../../../components/icons'

export const Title = buildComponent<{ book: string }>()
  .withLifecycle(({ props }) => {
    const audioBook = useStoreState((store) =>
      store.audio.books.find((b) => b.slug === props.book),
    )

    const name = audioBook?.title?.long ?? '...'

    return { name }
  })
  .withRender(({ lifecycle }) => (
    <UI.Group
      mx={-16}
      px={16}
      mt={-16}
      py={16}
      sx={{
        position: 'sticky',
        top: 72,
        zIndex: 10,
        backgroundColor: '#f8f9fc',
      }}
    >
      <Routing.Link to="/audios">
        <UI.ActionIcon color="blue" size="xl" component="a">
          <Icons.FontAwesomeIcon size="lg" icon={Icons.Solid.faArrowLeft} />
        </UI.ActionIcon>
      </Routing.Link>
      <UI.Stack spacing={0}>
        <UI.Stack spacing={0}>
          <UI.Title>
            <UI.Text size={22}>
              Livre audio{' '}
              <UI.Text component="span" weight={700} color="blue.900">
                {lifecycle.name}
              </UI.Text>
            </UI.Text>
          </UI.Title>
          <UI.Text color="dimmed">
            Modifiez le titre, les images, les chapitres et les fichiers audios
            de <b>{lifecycle.name}</b>.
          </UI.Text>
        </UI.Stack>
      </UI.Stack>
    </UI.Group>
  ))
