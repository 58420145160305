import React from 'react'
import { buildComponent } from '../../../../components/factory'

import { EventNoSlotWarning } from './data'
import { useChartValues, BarGraph } from './graphs'

export const WarningSlotsBars = buildComponent<{
  eventsNoSlotWarnings: EventNoSlotWarning[]
}>()
  .withLifecycle(({ props }) => {
    return useChartValues({
      events: props.eventsNoSlotWarnings,
      label: (event) => {
        const slotStart = new Date(event.date)
        const hours = slotStart.getHours()
        const minutes = slotStart.getMinutes() >= 30 ? 30 : 0
        return hours + ':' + (minutes + '').padStart(2, '0')
      },
      sort: (a, b) => Number.parseFloat(a.label) - Number.parseFloat(b.label),
    })
  })
  .withRender(({ lifecycle }) => <BarGraph data={lifecycle.data} tilted />)
