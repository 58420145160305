import React, { useEffect, useState } from 'react'
import { useLocation, RouteComponentProps } from 'wouter'

import { graphql } from '../../../services/graphql'

import { useForm } from '@mantine/form'
import { useSessionValue } from '../../../hooks/use-session-value'
import { useStoreState } from '../../../hooks/state'

import { buildComponent } from '../../../components/factory'

import * as UI from '@mantine/core'
import * as Modals from '@mantine/modals'
import * as Icons from '../../../components/icons'

import { Main } from './components/main'
import { Background } from './components/background'

const names = {
  themes: 'une thématique',
  books: 'un audiobook',
  backgrounds: 'un fond sonore',
}

const create = {
  themes: {
    method: 'createAudioTheme',
    result: 'audioThemeCreate',
    path: '/audios/theme/',
  },
  books: {
    method: 'createAudioBook',
    result: 'audioBookCreate',
    path: '/audios/book/',
  },
  backgrounds: {
    method: 'createAudioBackground',
    result: 'audioBackgroundCreate',
    path: '/audios/background/',
  },
} as const

const CreateAudio = buildComponent<{
  kind: 'themes' | 'books' | 'backgrounds'
}>()
  .withLifecycle(({ props }) => {
    const [, setLocation] = useLocation()
    const [submitting, setSubmitting] = useState(false)
    const form = useForm({
      initialValues: {
        code: '',
      },
      validate: {
        code: (value) => {
          if (/^[a-z0-9][a-z0-9_-]+[a-z0-9]$/.test(value)) {
            return null
          }

          return 'Code incompatible'
        },
      },
    })

    const submit = form.onSubmit(async () => {
      try {
        // prettier-ignore
        const def = create[props.kind] as typeof create['themes']

        setSubmitting(true)
        form.clearErrors()
        const result = await graphql.mutations[def.method](form.values.code)
        Modals.closeAllModals()

        setLocation(def.path + result[def.result].slug)
      } catch (err: any) {
        if ('message' in err) {
          if (err.message.includes('duplicate key')) {
            return form.setFieldError(
              'code',
              'Ce code est déjà utilisé par un autre audio',
            )
          }
        }

        form.setFieldError(
          'code',
          "Une erreur est survenue lors de la création de l'audio",
        )
      } finally {
        setSubmitting(false)
      }
    })

    return { form, submit, submitting }
  })
  .withRender(({ lifecycle }) => (
    <form onSubmit={lifecycle.submit}>
      <UI.Stack>
        <UI.Text>
          Choisissez un code unique pour votre audio. Le code doit être en
          minuscules et sans espaces.
        </UI.Text>
        <UI.TextInput
          label="Code unique"
          {...lifecycle.form.getInputProps('code')}
        />
        <UI.Button
          mx="auto"
          mb={16}
          sx={{ minHeight: '4rem' }}
          type="submit"
          loading={lifecycle.submitting}
        >
          Créer l'audio
        </UI.Button>
      </UI.Stack>
    </form>
  ))

export const ScreenConnectedAudios = buildComponent<RouteComponentProps>()
  .withLifecycle(() => {
    const [search, setSearch] = useState('')
    const [tab, setTab] = useSessionValue<'themes' | 'books' | 'backgrounds'>(
      'AudioTab',
      'themes',
    )

    const compare = (...terms: (string | undefined)[]) => {
      return terms.some((t) => t?.toLowerCase().includes(search.toLowerCase()))
    }

    const themes = useStoreState((store) => store.audio.themes).filter((t) =>
      compare(t.title?.fr, t.title?.en, t.short?.fr, t.short?.en, t.slug),
    )
    const books = useStoreState((store) => store.audio.books).filter((b) =>
      compare(b.title?.long, b.title?.short, b.slug),
    )
    const backgrounds = useStoreState(
      (store) => store.audio.backgrounds,
    ).filter((b) =>
      compare(b.short?.fr, b.short?.en, b.long?.fr, b.long?.en, b.slug),
    )

    const createAudio = () => {
      Modals.openModal({
        title: <UI.Text weight="bold">Créer {names[tab]}</UI.Text>,
        children: <CreateAudio kind={tab} />,
        padding: 'lg',
      })
    }

    useEffect(() => {
      setSearch('')
    }, [tab])

    return {
      tab,
      setTab,
      search,
      setSearch,
      themes,
      books,
      backgrounds,
      createAudio,
    }
  })
  .withRender(({ lifecycle }) => (
    <React.Fragment>
      <UI.Stack
        mx={-16}
        px={16}
        mt={-16}
        py={16}
        sx={{
          position: 'sticky',
          top: 72,
          zIndex: 10,
          backgroundColor: '#f8f9fc',
        }}
        spacing={0}
      >
        <UI.Group position="apart">
          <UI.Stack spacing={0}>
            <UI.Title>
              <UI.Text size={22}>Audios de relaxation</UI.Text>
            </UI.Title>
            <UI.Text color="dimmed">
              Configuration des thématiques, audiobooks et fonds sonores
              disponibles pour accompagner les siestes.
            </UI.Text>
          </UI.Stack>
        </UI.Group>

        <UI.Group mx="auto" mt="lg" position="center" spacing={0}>
          <UI.SegmentedControl
            radius="xl"
            value={lifecycle.tab ?? undefined}
            onChange={lifecycle.setTab}
            data={[
              { label: 'Thématiques', value: 'themes' },
              { label: 'Audiobooks', value: 'books' },
              { label: 'Fonds sonores', value: 'backgrounds' },
            ]}
            styles={(theme) => ({
              root: {
                backgroundColor: 'transparent',
              },
              active: {
                backgroundColor: theme.colors.blue[7],
              },
              label: {
                padding: '1rem 4rem',
              },
              labelActive: {
                color: 'white !important',
              },
            })}
          />
        </UI.Group>

        <UI.Group mx="auto" mt="lg" position="center" spacing={8}>
          <UI.TextInput
            mx={8}
            sx={{ width: 440 }}
            value={lifecycle.search}
            onChange={(e) => lifecycle.setSearch(e.target.value)}
            placeholder={`Chercher ${names[lifecycle.tab!]}`}
            variant="default"
            rightSection={
              <Icons.FontAwesomeIcon
                color="#AAAAAA"
                icon={Icons.Solid.faSearch}
                style={{ marginRight: '2rem' }}
              />
            }
          />
          <UI.Button
            size="sm"
            sx={{ minHeight: 38 }}
            mb={1}
            px="4rem"
            leftIcon={<Icons.FontAwesomeIcon icon={Icons.Solid.faPlus} />}
            onClick={lifecycle.createAudio}
          >
            Ajouter {names[lifecycle.tab!]}
          </UI.Button>
        </UI.Group>
      </UI.Stack>

      <UI.Container>
        <UI.Group mt="lg" position="center" spacing="xl">
          {lifecycle.tab === 'themes' &&
            lifecycle.themes.map((theme) => (
              <Main
                key={theme.slug}
                link={'/audios/theme/' + theme.slug}
                image={`${
                  process.env.REACT_APP_STATIC_URL as string
                }images/themes/${theme.slug}/${theme.image ?? 'image'}.webp`}
                title={theme.title?.fr ?? theme.title?.en ?? '---'}
              />
            ))}
          {lifecycle.tab === 'books' &&
            lifecycle.books.map((book) => (
              <Main
                key={book.slug}
                link={'/audios/book/' + book.slug}
                image={`${
                  process.env.REACT_APP_STATIC_URL as string
                }images/books/${book.slug}/${book.image ?? 'image'}.webp`}
                title={book.title?.long ?? '---'}
              />
            ))}
          {lifecycle.tab === 'backgrounds' &&
            lifecycle.backgrounds.map((background) => (
              <Background
                link={`/audios/background/${background.slug}`}
                image={
                  (process.env.REACT_APP_STATIC_URL as string) +
                  `images/backgrounds/${background.slug}/${
                    background.image ?? background.slug
                  }.webp`
                }
                title={background.long?.fr ?? background.long?.en ?? '---'}
              />
            ))}
        </UI.Group>
      </UI.Container>
    </React.Fragment>
  ))
