import React from 'react'

import { AudioBackground } from '../../../../state/models/audio'
import { graphql } from '../../../../services/graphql'

import {
  DeepPartial,
  useSynchronizeData,
} from '../../../../hooks/use-synchronize-data'

import { buildComponent } from '../../../../components/factory'

import * as UI from '@mantine/core'
import * as Elements from '../../../../components/elements'

const EditIcon = buildComponent<
  Partial<AudioBackground> & {
    update: (next: DeepPartial<AudioBackground>) => void
  }
>().withRender(({ props }) => (
  <UI.Input.Wrapper
    style={{ flex: 1 }}
    label={<b style={{ fontSize: '2rem' }}>Icône SVG pour application v1</b>}
    description="Icône carrée utilisée dans la configuration de l'audio. Format SVG"
  >
    <Elements.LegacyImageButton
      width={180}
      height={180}
      kind="backgrounds"
      variant="icon"
      entity={props._id}
      slug={props.slug}
    />
  </UI.Input.Wrapper>
))

export const SectionLegacyImages = buildComponent<{
  background: string
}>()
  .withLifecycle(({ props }) => {
    return useSynchronizeData(
      graphql.queries.getAudioBackground,
      () => graphql.queries.getAudioBackground(props.background),
      props.background,
    )
  })
  .withRender(({ lifecycle }) => (
    <UI.Group sx={{ flex: 1 }} spacing={64}>
      <EditIcon {...lifecycle.data} update={lifecycle.update} />

      {lifecycle.loading && (
        <UI.Loader
          sx={{ position: 'absolute', top: '1rem', right: '1rem' }}
          size="xs"
        />
      )}
    </UI.Group>
  ))
