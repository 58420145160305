import React, { useEffect, useState } from 'react'
import { marked } from 'marked'
import { NodeHtmlMarkdown } from 'node-html-markdown'

import { buildComponent } from '../../../../components/factory'

import * as UI from '@mantine/core'
import * as RTE from '@mantine/rte'
import * as Icons from '../../../../components/icons'
import { useStoreState } from '../../../../hooks/state'
import { del, get, set } from 'idb-keyval'
import {
  getDocumentReference,
  upsertDocument,
} from '../../../../helpers/firestore'
import { serviceModals } from '../../../../services/modals'

export const Faq = buildComponent<{ language: 'fr' | 'en' }>()
  .withLifecycle(({ props }) => {
    const data = useStoreState((store) => store.dynamicContent.faq)
    const [draft, setDraft] = useState<
      { question: string; answer: string }[] | null
    >(null)
    useEffect(() => {
      get('content:faq:' + props.language).then(setDraft)
    }, [props.language])

    const value = draft ?? data?.[props.language] ?? []

    const updateQuestion =
      (index: number) => (e: { currentTarget: { value: string } }) => {
        const nextValue = e.currentTarget.value

        setDraft((d) => {
          const next = d ? [...d] : []

          if (next[index]?.question === nextValue) {
            return d
          }

          if (!next[index]) {
            next[index] = {
              question: '',
              answer: '',
              ...data?.[props.language]?.[index],
            }
          }

          next[index].question = nextValue

          return next
        })
      }
    const updateAnswer = (index: number) => (html: string) => {
      const nextValue =
        NodeHtmlMarkdown.translate(html).replace(/ \n/g, ' <!-- -->\n') +
        '<!-- -->'

      setDraft((d) => {
        const next = d ? [...d] : []

        if (next[index]?.answer === nextValue) {
          return d
        }

        if (!next[index]) {
          next[index] = {
            question: '',
            answer: '',
            ...data?.[props.language]?.[index],
          }
        }

        next[index].answer = nextValue

        return next
      })
    }
    const isQuestionPublishable = (index: number) => {
      const original = data?.[props.language]?.[index]
      const next = draft?.[index]

      return !!(
        (data?.[props.language]?.length ?? 0) >= index &&
        next?.question &&
        next?.answer &&
        (original?.question !== next?.question ||
          original?.answer.replace(/<!-- -->/g, '').trim() !==
            next?.answer.replace(/<!-- -->/g, '').trim())
      )
    }
    const publishQuestion =
      (index: number) => async (e: { stopPropagation: () => void }) => {
        e.stopPropagation()

        await serviceModals.confirmAction(
          'Publier une question fréquente',
          "Voulez-vous vraiment publier cette question vers l'application\u00A0?",
        )

        const next = data?.[props.language] ?? []

        next[index] = value[index]
        next[index].answer = next[index].answer.replace(/<!-- -->/g, '').trim()

        upsertDocument(getDocumentReference('dynamic-content', 'faq'), {
          [props.language]: next,
        })
      }
    const addQuestion = () =>
      setDraft((d) => [...(d ?? []), { question: '', answer: '' }])
    const deleteQuestion =
      (index: number) => async (e: { stopPropagation: () => void }) => {
        e.stopPropagation()

        await serviceModals.confirmDeletion(
          'Supprimer une question fréquente',
          "Voulez-vous vraiment retirer cette question de l'application\u00A0?",
        )

        const next = data?.[props.language] ?? []
        next.splice(index, 1)

        upsertDocument(getDocumentReference('dynamic-content', 'faq'), {
          [props.language]: next,
        })
        setDraft((d) => {
          if (d) {
            d.splice(index, 1)
            return [...d]
          }

          return d
        })
      }
    const onReload = async () => {
      await serviceModals.confirmAction(
        'Charger les données publiées',
        'Cette action supprimera les données locales de votre ordinateur, et les remplacera par les données actuellement publiées. Est-ce bien ce que vous souhaitez ?',
      )
      setDraft(null)
      await del('content:faq:' + props.language)
    }

    const different = value.reduce(
      (acc, val, index) => acc || isQuestionPublishable(index),
      false,
    )

    useEffect(() => {
      if (draft && different) {
        set('content:faq:' + props.language, draft)
      }

      if (!draft || !different) {
        del('content:faq:' + props.language)
      }
    }, [draft, props.language, different])

    return {
      value,
      updateQuestion,
      updateAnswer,
      isQuestionPublishable,
      publishQuestion,
      addQuestion,
      deleteQuestion,
      onReload,
      different,
    }
  })
  .withRender(({ lifecycle }) => (
    <UI.Stack
      sx={{ maxWidth: 1280, margin: '2rem auto', width: '100%' }}
      spacing={16}
    >
      <UI.Button
        leftIcon={<Icons.FontAwesomeIcon icon={Icons.Solid.faSync} />}
        px={32}
        ml="auto"
        onClick={lifecycle.onReload}
        sx={{ minHeight: 32 }}
        color="gray"
        disabled={!lifecycle.different}
      >
        Réinitialiser
      </UI.Button>

      {lifecycle.value.map((question, index) => (
        <UI.Card key={index} shadow="sm" p={0}>
          <UI.Accordion
            variant="filled"
            chevronPosition="left"
            chevron={<Icons.FontAwesomeIcon icon={Icons.Solid.faCaretRight} />}
            styles={{
              item: {
                '&[data-active]': {
                  background: 'transparent',
                },
              },
              chevron: {
                '&[data-rotate]': {
                  transform: 'rotate(90deg)',
                },
              },
            }}
          >
            <UI.Accordion.Item value="answer">
              <UI.Accordion.Control>
                <UI.Group position="apart" mb={6} mt={6} align="center">
                  <UI.Text>
                    {question.question || <i>Nouvelle question</i>}
                  </UI.Text>

                  <UI.Group>
                    <UI.ActionIcon
                      size="xs"
                      sx={{ minHeight: 16 }}
                      onClick={lifecycle.deleteQuestion(index)}
                      color="red"
                    >
                      <Icons.FontAwesomeIcon icon={Icons.Solid.faTrash} />
                    </UI.ActionIcon>
                    <UI.Button
                      size="xs"
                      sx={{ minHeight: 16 }}
                      disabled={!lifecycle.isQuestionPublishable(index)}
                      onClick={lifecycle.publishQuestion(index)}
                      leftIcon={
                        <Icons.FontAwesomeIcon icon={Icons.Solid.faUpload} />
                      }
                    >
                      Publier
                    </UI.Button>
                  </UI.Group>
                </UI.Group>
              </UI.Accordion.Control>
              <UI.Accordion.Panel>
                <UI.TextInput
                  label="Question"
                  value={question.question}
                  onChange={lifecycle.updateQuestion(index)}
                />

                <UI.Input.Wrapper label="Réponse">
                  <RTE.RichTextEditor
                    mt={8}
                    value={marked(question.answer)}
                    onChange={lifecycle.updateAnswer(index)}
                    controls={[
                      ['bold', 'italic', 'underline'],
                      ['unorderedList', 'orderedList'],
                    ]}
                  />
                </UI.Input.Wrapper>
              </UI.Accordion.Panel>
            </UI.Accordion.Item>
          </UI.Accordion>
        </UI.Card>
      ))}

      <UI.Button
        ml="auto"
        px={32}
        leftIcon={<Icons.FontAwesomeIcon icon={Icons.Solid.faPlus} />}
        onClick={lifecycle.addQuestion}
      >
        Ajouter une question
      </UI.Button>
    </UI.Stack>
  ))
