import React from 'react'

import { buildComponent } from '../../../../components/factory'

import * as UI from '@mantine/core'
import { AudioBook } from '../../../../state/models/audio'
import {
  DeepPartial,
  useSynchronizeData,
} from '../../../../hooks/use-synchronize-data'
import { graphql } from '../../../../services/graphql'

const ShowUniqueIdentifier = buildComponent<{ slug?: string }>().withRender(
  ({ props }) => (
    <UI.Input.Wrapper
      sx={{ flex: 1 }}
      label={<b style={{ fontSize: '2rem' }}>Identifiant unique</b>}
    >
      <UI.Group mt={8}>
        <UI.Kbd px={16}>{props.slug ?? '...'}</UI.Kbd>
      </UI.Group>
    </UI.Input.Wrapper>
  ),
)

const EditLanguage = buildComponent<{
  language?: 'fr' | 'en'
  update: (next: Partial<AudioBook>) => void
}>().withRender(({ props }) => (
  <UI.Input.Wrapper
    sx={{ flex: 1 }}
    label={<b style={{ fontSize: '2rem' }}>Langue</b>}
  >
    <UI.Group mt={8}>
      <UI.Button
        style={{ minHeight: '4rem', width: '16rem' }}
        color={props.language === 'fr' ? undefined : 'gray'}
        onClick={() => props.update({ language: 'fr' })}
      >
        Français
      </UI.Button>
      <UI.Button
        style={{ minHeight: '4rem', width: '16rem' }}
        color={props.language === 'en' ? undefined : 'gray'}
        onClick={() => props.update({ language: 'en' })}
      >
        Anglais
      </UI.Button>
    </UI.Group>
  </UI.Input.Wrapper>
))

const EditName = buildComponent<{
  title?: {
    long: string
    short: string
  }
  update: (next: DeepPartial<AudioBook>) => void
}>()
  .withLifecycle(({ props }) => ({
    update:
      (field: 'long' | 'short') => (e: { currentTarget: { value: string } }) =>
        props.update({ title: { [field]: e.currentTarget.value } }),
  }))
  .withRender(({ props, lifecycle }) => (
    <UI.Group sx={{ width: '100%' }} spacing={32}>
      <UI.TextInput
        sx={{ flex: 1, width: '100%' }}
        label={<b style={{ fontSize: '2rem' }}>Nom du livre audio</b>}
        description="Affiché lors de la configuration de l'audio et dans le lecteur"
        value={props.title?.long}
        onChange={lifecycle.update('long')}
      />
      <UI.TextInput
        sx={{ flex: 1, width: '100%' }}
        label={<b style={{ fontSize: '2rem' }}>Nom court</b>}
        description="Affiché dès lors que le nom complet serait trop long, par exemple sur les cartes de sieste"
        value={props.title?.short}
        onChange={lifecycle.update('short')}
      />
    </UI.Group>
  ))

const EditDescription = buildComponent<{
  description?: string
  update: (next: DeepPartial<AudioBook>) => void
}>()
  .withLifecycle(({ props }) => ({
    update: (e: { currentTarget: { value: string } }) =>
      props.update({ description: e.currentTarget.value }),
  }))
  .withRender(({ props, lifecycle }) => (
    <UI.TextInput
      sx={{ width: '100%' }}
      label={<b style={{ fontSize: '2rem' }}>Description</b>}
      description="Accessible lors de la configuration de l'audio, permet de décrire plus en détail le contenu de la thématique"
      value={props.description}
      onChange={lifecycle.update}
    />
  ))

export const SectionMain = buildComponent<{
  book: string
}>()
  .withLifecycle(({ props }) => {
    return useSynchronizeData(
      graphql.queries.getAudioBook,
      graphql.mutations.updateAudioBook,
      props.book,
    )
  })
  .withRender(({ lifecycle }) => (
    <UI.Stack sx={{ flex: 1 }} spacing={24}>
      <UI.Group>
        <ShowUniqueIdentifier slug={lifecycle.data?.slug} />
        <EditLanguage {...lifecycle.data} update={lifecycle.update} />
      </UI.Group>
      <EditName {...lifecycle.data} update={lifecycle.update} />
      <EditDescription {...lifecycle.data} update={lifecycle.update} />

      {lifecycle.loading && (
        <UI.Loader
          sx={{ position: 'absolute', top: '1rem', right: '1rem' }}
          size="xs"
        />
      )}
    </UI.Stack>
  ))
