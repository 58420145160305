import React from 'react'

import { buildComponent } from '../../../../components/factory'

import * as UI from '@mantine/core'
import * as Icons from '../../../../components/icons'
import * as Elements from '../../../../components/elements'
import { useSynchronizeData } from '../../../../hooks/use-synchronize-data'
import { graphql } from '../../../../services/graphql'
import { AudioBook } from '../../../../state/models/audio'

const SelectTracks = buildComponent<
  AudioBook['chapters'][number] & {
    index: number
    book: AudioBook | null
    update: (next: Partial<AudioBook>) => void
  }
>().withRender(({ props }) => (
  <UI.Group
    ml="auto"
    sx={{ width: '100%', flex: 1 }}
    position="right"
    spacing={8}
  >
    {([10, 15, 20, 25, 30] as const).map((duration) => {
      return (
        <Elements.AudioButton
          key={duration}
          duration={duration}
          kind="audiobooks"
          chapter={props.index}
          entity={props.book?._id}
          slug={props.book?.slug}
          update={props.update}
        />
      )
    })}
  </UI.Group>
))

const Chapter = buildComponent<
  AudioBook['chapters'][number] & {
    index: number
    firstInvalidChapter: number
    book: AudioBook | null
    update: (next: Partial<AudioBook>, custom?: any) => void
  }
>()
  .withLifecycle(({ props }) => {
    const state =
      props.firstInvalidChapter < props.index
        ? 'blocked'
        : Object.keys(props.tracks || {}).length < 1
        ? 'invalid'
        : 'valid'

    const icon = {
      blocked: Icons.Solid.faExclamationCircle,
      invalid: Icons.Solid.faTimesCircle,
      valid: Icons.Solid.faCheckCircle,
    }[state]
    const color = state === 'valid' ? 'teal' : 'red'

    const onTitleChange = (e: { currentTarget: { value: string } }) => {
      const title = e.currentTarget.value
      const chapters = props.book?.chapters ?? []
      chapters[props.index].title = title
      props.update(
        {
          chapters,
        },
        async () => {
          if (props.book) {
            await graphql.mutations.setAudioBookChapterTitle(
              props.book?._id,
              props.index,
              title,
            )
          }
        },
      )
    }

    return { icon, color, onTitleChange }
  })
  .withRender(({ props, lifecycle }) => (
    <UI.Group px={16} py={8}>
      <UI.TextInput
        sx={{ minWidth: '50rem' }}
        placeholder={'Chapitre ' + (props.index + 1)}
        value={props.title}
        onChange={lifecycle.onTitleChange}
      />
      <SelectTracks {...props} />
      <UI.Text color={lifecycle.color}>
        <Icons.FontAwesomeIcon icon={lifecycle.icon} />
      </UI.Text>
    </UI.Group>
  ))

export const SectionChapters = buildComponent<{ book: string }>()
  .withLifecycle(({ props }) => {
    const synchronize = useSynchronizeData(
      graphql.queries.getAudioBook,
      () => graphql.queries.getAudioBook(props.book, true),
      props.book,
    )

    const firstInvalidChapterRaw =
      synchronize.data?.chapters.findIndex(
        (chapter) => Object.keys(chapter.tracks || {}).length < 1,
      ) || 0
    const firstInvalidChapter =
      firstInvalidChapterRaw === -1
        ? synchronize.data?.chapters.length || 0
        : firstInvalidChapterRaw

    const addChapter = () => {
      const book = synchronize.data

      if (book) {
        synchronize.update({}, async () => {
          await graphql.mutations.setAudioBookChapterTitle(
            book._id,
            book.chapters?.length ?? 0,
            '',
          )
        })
      }
    }

    const removeChapter = () => {
      const book = synchronize.data

      if (book) {
        synchronize.update({}, async () => {
          await graphql.mutations.removeLastAudioBookChapter(book._id)
        })
      }
    }

    return { ...synchronize, firstInvalidChapter, addChapter, removeChapter }
  })
  .withRender(({ lifecycle }) => (
    <UI.Input.Wrapper
      sx={{ flex: 1 }}
      label={<b style={{ fontSize: '2rem' }}>Chapitres</b>}
      description="Chaque chapitre correspond à un audio indépendant, et peut avoir son propre titre"
      mb={16}
    >
      <UI.Group mt={32} position="center" spacing={32}>
        <UI.Group spacing={8}>
          <UI.Text color="teal">
            <Icons.FontAwesomeIcon icon={Icons.Solid.faCheckCircle} />
          </UI.Text>
          <UI.Text size="sm" weight="bold" color="dimmed">
            Visible dans l'application
          </UI.Text>
        </UI.Group>
        <UI.Group spacing={8}>
          <UI.Text color="red">
            <Icons.FontAwesomeIcon icon={Icons.Solid.faTimesCircle} />
          </UI.Text>
          <UI.Text size="sm" weight="bold" color="dimmed">
            Non-visible car un audio est manquant
          </UI.Text>
        </UI.Group>
        <UI.Group spacing={8}>
          <UI.Text color="red">
            <Icons.FontAwesomeIcon icon={Icons.Solid.faExclamationCircle} />
          </UI.Text>
          <UI.Text size="sm" weight="bold" color="dimmed">
            Non-visible car un chapitre précédent est incomplet
          </UI.Text>
        </UI.Group>
      </UI.Group>

      <UI.Card withBorder mt={16} sx={{ flex: 1 }} p={0}>
        {lifecycle.data?.chapters?.map((chapter, index) => (
          <>
            {!!index && <UI.Divider />}
            <Chapter
              {...chapter}
              index={index}
              book={lifecycle.data}
              firstInvalidChapter={lifecycle.firstInvalidChapter}
              update={lifecycle.update}
            />
          </>
        ))}
      </UI.Card>

      <UI.Group mt={16} position="right">
        <UI.Button
          color="red"
          onClick={lifecycle.removeChapter}
          leftIcon={<Icons.FontAwesomeIcon icon={Icons.Solid.faMinus} />}
        >
          Supprimer le dernier chapitre
        </UI.Button>
        <UI.Button
          px={48}
          onClick={lifecycle.addChapter}
          leftIcon={<Icons.FontAwesomeIcon icon={Icons.Solid.faPlus} />}
        >
          Ajouter un chapitre
        </UI.Button>
      </UI.Group>

      {lifecycle.loading && (
        <UI.Loader
          sx={{ position: 'absolute', top: '1rem', right: '1rem' }}
          size="xs"
        />
      )}
    </UI.Input.Wrapper>
  ))
