import { useDatasourceSpace } from './datasources/space'
import { useDatasourceAudio } from './datasources/audio'
import { useDatasourceUser } from './datasources/user'
import { useDatasourceDynamicContent } from './datasources/dynamic-content'

export function useDatasources() {
  useDatasourceSpace()
  useDatasourceAudio()
  useDatasourceUser()
  useDatasourceDynamicContent()
}
